
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const searchWatchRequest = async (keyword, config) => {
  try {
    const response = await axios.get(
      SERVER_URL + `/web/sell/watch/search?keyword=${keyword}`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getWatchDetailsRequest = async (watchId, config, dispatch) => {
  try {
    const response = await axios.get(
      SERVER_URL + `/admin/product/watch/details/${watchId}`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getDealerListRequest = async (watchId, config, dispatch) => {
  try {
    const response = await axios.get(
      SERVER_URL + `/admin/product/dealer-list`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const addNewProductByDealerRequest = async (dealerId, productId, values, config) => {
  try {
    let route = "";
    if(productId){
      route = SERVER_URL + `/admin/product/by-dealer/update/${productId}?dealerId=${dealerId}`
    }else{
      route = SERVER_URL + `/admin/product/by-dealer/create?dealerId=${dealerId}`
    }
    const response = await axios.post(
      route,
      values,
      config
    );
    return response;
  } catch (error) {
    throw error.toString();
  }
};

export const addNewProductByPlatformRequest = async (productId, values, config) => {
  try {
    let route = "";
    if(productId){
      route = SERVER_URL + `/admin/product/by-platform/update/${productId}`
    }else{
      route = SERVER_URL + `/admin/product/by-platform/create`
    }
    const response = await axios.post(
      route,
      values,
      config
    );
    return response;
  } catch (error) {
    throw error.toString();
  }
};

export const addNewAccessoryRequest = async (productId, values, config) => {
  try {
    let route = "";
    if(productId){
      route = SERVER_URL + `/admin/product/accessory/update/${productId}`
    }else{
      route = SERVER_URL + `/admin/product/accessory/create`
    }
    const response = await axios.post(
      route,
      values,
      config
    );
    return response;
  } catch (error) {
    throw error.toString();
  }
};

export const addNewProductByConsignmentRequest = async (consignmentId, productId, values, config) => {
  try {
    let route = "";
    if(productId){
      route = SERVER_URL + `/admin/product/by-consignment/update/${productId}?consignmentId=${consignmentId}`
    }else{
      route = SERVER_URL + `/admin/product/by-consignment/create?consignmentId=${consignmentId}`
    }
    const response = await axios.post(
      route,
      values,
      config
    );
    return response;
  } catch (error) {
    throw error.toString();
  }
};


export const getProductEditDetails = async (productId, config) => {
  try {
    const response = await axios.get(
      SERVER_URL + `/admin/product/edit-details/${productId}`, config
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteProductListing = async (productId, config) => {
  try {
    const response = await axios.get(SERVER_URL + `/admin/product/delete-listing/${productId}`, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const searchActiveProductsRequest = async (keyword, productType, config) => {
  try {
    const response = await axios.get(
      SERVER_URL + `/admin/products/search?keyword=${keyword}&productType=${productType}`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateAccessoryStockLevelRequest = async (values, config) => {
  try {
    const response = await axios.post(
      SERVER_URL + `/admin/product/accessory/update-stock-level`,
      values,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};



