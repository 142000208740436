import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addNewAccessoryRequest,
  addNewProductByDealerRequest,
  getProductEditDetails,
} from "../../api/productsRequests";
import ScreenTitle from "../../components/ScreenTitle";
import { SimpleDialog } from "../../components/dialogs";
import { ErrorMessage, Loader } from "../../components/smallComponents";
import { K_COUNTRIES } from "../../constantData/K_COUNTRIES";
import { watchConstantOptionsRequest } from "../../redux/actions/watchActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import ProductDetailsForm from "./ProductDetailsForm";
import PlatformProductDetailsForm from "./PlatformProductDetailsForm";
import AccessoryDetailsForm from "./AccessoryDetailsForm";

export default function EditAccessory() {
  useDocumentTitle("Edit Product(Accessory)");
  const { id: productId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [dealerId, setDealerId] = useState("");
  const [formState, setFormState] = useState({});
  const [dialog, setDialog] = useState(false);

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [detailStatus, setDetailStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  useEffect(() => {
    const productDetailsRequest = async () => {
      try {
        setDetailStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await getProductEditDetails(productId, config);
        if (responseData.data.success) {
          let productDetails = responseData.data.data;
          let images = [];
          if (productDetails.imageUris.length) {
            productDetails.imageUris.forEach((x) => {
              const uri = x;
              const fileNameParts = x.split("/");
              const fileName = fileNameParts[fileNameParts.length - 1];

              images.push({
                fileName: fileName,
                isLoading: false,
                error: "",
                uri: uri,
              });
            });
          }

          let product_values = {
            imageUris: images,
            title: productDetails.title,
            price: productDetails.price,
            brandId: productDetails.brandId,
            description: productDetails.description
          };

          setFormState(product_values);
          setDetailStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setDetailStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };

    if (productId) {
      productDetailsRequest();
    }
  }, [productId]);

  const submitListing = async (values) => {
    let submittedValues = values;
    
    let uris = submittedValues.imageUris;
    let pureUris = [];
    uris.forEach((x) => {
      if (x.uri) {
        pureUris.push(x.uri);
      }
    });
    submittedValues.watchImages = pureUris;
    
    try {
      setSaveStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await addNewAccessoryRequest(
        productId,
        submittedValues,
        config
      );
      if (responseData.data.success) {
        setDialog(true);
        setSaveStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate(`/products/details/${productId}`);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Edit Product"
        successMessage="Product updated successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeDialogWithSuccess}
      />
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/products">
            Products
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/products/accessory/edit/${productId}`}
            aria-current="page"
          >
            Edit Accessory
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title={"Edit Product(Accessory)"} />

        {detailStatus.loading ? (
          <Loader mess="Requeting watch options..." />
        ) : detailStatus.success ? (
          <AccessoryDetailsForm
            type={"edit"}
            onSave={submitListing}
            submitLoading={saveStatus.loading}
            submitError={saveStatus.error}
            initialDetails={formState}
          />
        ) : detailStatus.error ? (
          <ErrorMessage mess={detailStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
