import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  addNewAccessoryRequest,
  addNewProductByPlatformRequest,
  getProductEditDetails
} from "../../api/productsRequests";
import ScreenTitle from "../../components/ScreenTitle";
import { SimpleDialog } from "../../components/dialogs";
import { K_COUNTRIES } from "../../constantData/K_COUNTRIES";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import AccessoryDetailsForm from "./AccessoryDetailsForm";

export default function NewAccessory() {
  useDocumentTitle("Add Accessory");
  const navigate = useNavigate();
  const location = useLocation();

  let cloneProductId = location.state;
  cloneProductId = Number(cloneProductId);

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const initialState = {
    imageUris: [],
    title: "",
    price: "",
    brandId: "",
    description: "",
  };

  const [dialog, setDialog] = useState(false);
  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [productId, setProductId] = useState("");


  const submitListing = async (values) => {
    let submittedValues = values;
    let uris = submittedValues.imageUris;
    let pureUris = [];
    uris.forEach((x) => {
      if (x.uri) {
        pureUris.push(x.uri);
      }
    });
    submittedValues.watchImages = pureUris;

    try {
      setSaveStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await addNewAccessoryRequest(
        "",
        values,
        config
      );
      if (responseData.data.success) {
        setProductId(responseData.data.data);
        setDialog(true);
        setSaveStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate(`/products/details/${productId}`);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Add New Product"
        successMessage="New product added successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeDialogWithSuccess}
      />
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/products">
            Products
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/products/new/`}
            aria-current="page"
          >
            New Accessory
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Add New Accessory" />

        <div>
          <AccessoryDetailsForm
            type={"new"}
            onSave={submitListing}
            submitLoading={saveStatus.loading}
            submitError={saveStatus.error}
            initialDetails={initialState}
          />
        </div>
      </div>
    </div>
  );
}
