
import React from "react";
import { NavLink, useLocation } from "react-router-dom";



export default function PageTabs({ tab1, tab2, tab1Route, tab2Route }) {
  const location = useLocation();
  const pathname = location.pathname
  const Tab = ({ name, isOn, to }) => {
    return (
      <NavLink
        to={to}
        className={`mr-6 py-2 border-solid border-brandYellow ${isOn ? "border-b-2" : ""}`}
      >
        <span className={`text-[16px] font-medium ${isOn ? "text-brandYellow" : ""}`}>
          {name}
        </span>
      </NavLink>
    );
  };

  return (
    <div className="w-full flex border-solid border-b border-borderGray my-8">
      {tab1 && (
        <Tab
          num="1"
          name={tab1}
          isOn={pathname === tab1Route ? true : false}
          to={tab1Route}
        />
      )}
      {tab2 && (
        <Tab
          num="2"
          name={tab2}
          isOn={pathname === tab2Route ? true : false}
          to={tab2Route}
        />
      )}
    </div>
  );
}
