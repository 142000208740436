"use client";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { convtPrice } from "../../api/functions";
import AddressCollector from "../../components/AddressCollector";
import { Button } from "../../components/buttons";
import {
  PureTextField,
  RatioField,
  TextAreaField,
} from "../../components/inputs";
import { ProductTypeTag } from "../../components/productComponents";
import { H3 } from "../../components/smallComponents";
import { K_COUNTRIES } from "../../constantData/K_COUNTRIES";
import SearchProduct from "./SearchProduct";
import { AiOutlineMinus, AiOutlinePlus, AiOutlineClose } from "react-icons/ai";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function OrderForm({ onSave }) {
  const {
    loading: userDetailsLoading,
    details,
    success: userDetailsSuccess,
    error: userDetailsError,
  } = useSelector((state) => state.userDetails);

  const [shippingFee, setShippingFee] = useState(0);
  const [orderSubtotal, setOrderSubtotal] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);

  const [orderProducts, setOrderProducts] = useState([]);

  const [productInfoError, setProductInfoError] = useState("");

  const [shippingAddress, setShippingAddress] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    streetLine1: "",
    city: "",
    region: "",
    country: "",
    postalCode: "",
  });
  const [billingAddress, setBillingAddress] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    streetLine1: "",
    city: "",
    region: "",
    country: "",
    postalCode: "",
  });
  const [sameAddress, setSameAddress] = useState(true);
  const [shippingAddressErrors, setShippingAddressErrors] = useState({});
  const [billingAddressErrors, setBillingAddressErrors] = useState({});

  const [adminNotes, setAdminNotes] = useState("");

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (userDetailsSuccess) {
      let contactDetails = {
        firstName: details.firstName,
        lastName: details.lastName,
        phone: details.phone,
        streetLine1: details.streetLine1,
        region: details.region,
        country: K_COUNTRIES.filter((x) => x.value === details.country)[0],
        city: details.city,
        postalCode: details.postalCode,
      };
      setShippingAddress(contactDetails);
    } else {
      setShippingAddress({
        firstName: "",
        lastName: "",
        phone: "",
        streetLine1: "",
        city: "",
        region: "",
        country: "",
        postalCode: "",
      });
    }
  }, [userDetailsSuccess]);

  useEffect(() => {
    // Calculate the total of all products (price * quantity)
    let productTotal = orderProducts.reduce((total, product) => {
      return (
        total + (Number(product.price) || 0) * (Number(product.quantity) || 1)
      );
    }, 0);
    productTotal = productTotal.toFixed(2);
    productTotal = Number(productTotal);

    setOrderSubtotal(productTotal);
    // Convert shipping fee to number (default to 0 if empty)
    let shippingCost = Number(shippingFee) || 0;
    let order_total = (productTotal + shippingCost).toFixed(2);
    setOrderTotal(Number(order_total));
  }, [orderProducts, shippingFee]);

  const getSelectWatch = (watch) => {
    let copy = [...orderProducts];
    let find_product = copy.find((x) => Number(x.id) === Number(watch.id));
    if (!find_product) {
      watch.quantity = 1;
      copy.push(watch);
      setOrderProducts(copy);
    }
  };

  const handlePriceChange = (e) => {
    const { productId, value } = e.target;
    const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (!validated) {
      return;
    }
    let copy = [...orderProducts];
    let find_product_index = copy.findIndex(
      (x) => Number(x.id) === Number(productId)
    );
    if (find_product_index === -1) {
      return;
    }
    copy[find_product_index].price = value;
    setOrderProducts(copy);
  };

  const handleQuantityChange = (productId, change) => {
    let copy = [...orderProducts];
    let find_product_index = copy.findIndex(
      (x) => Number(x.id) === Number(productId)
    );
    if (find_product_index === -1) {
      return;
    }
    copy[find_product_index].quantity = Math.max(
      1,
      Number(copy[find_product_index].quantity) + change
    );
    setOrderProducts(copy);
  };

  const removeProduct = (productId) => {
    let copy = [...orderProducts];
    let find_product_index = copy.findIndex(
      (x) => Number(x.id) === Number(productId)
    );
    if (find_product_index === -1) {
      return;
    }
    copy.splice(find_product_index, 1);
    setOrderProducts(copy);
  };

  const handleShippingFeeChange = (e) => {
    const { value } = e.target;
    const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (!validated) {
      return;
    }
    setShippingFee(value);
  };

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  const handleSubmit = () => {
    if (orderProducts.length === 0) {
      setProductInfoError("Please add at least one product to this order.");
      return;
    } else {
      setProductInfoError("");
    }
    let shippingAddressCopy = { ...shippingAddress };
    let billingAddressCopy = sameAddress
      ? shippingAddressCopy
      : { ...billingAddress };

    if (typeof shippingAddressCopy.country === "object") {
      shippingAddressCopy.countryName = shippingAddressCopy.country.value;
    }
    if (typeof billingAddressCopy.country === "object") {
      billingAddressCopy.countryName = billingAddressCopy.country.value;
    }

    let orderValues = {
      products: orderProducts,
      shippingFee: shippingFee,
      shippingAddress: JSON.stringify(shippingAddressCopy),
      billingAddress: JSON.stringify(billingAddressCopy),
      adminNotes: adminNotes,
    };

    onSave(orderValues);
  };

  ///////////////////////////////////////////////////////////////

  return (
    <div className="w-full pb-20 pt-4">
      <div className="w-full flex items-start flex-row">
        <div className="w-full lg:w-3/5">
          <div className="w-full mt-6">
            <div>
              <H3 title={"Order Product"} />
              <SearchProduct
                onSelect={getSelectWatch}
                readOnly={false}
                productType={"platformProducts"}
              />
              {productInfoError && (
                <p className="text-red-500 mt-2">{productInfoError}</p>
              )}
            </div>

            <div className="mt-8 w-full">
              <H3 title={"Shipping Address"} />
              <AddressCollector
                formState={shippingAddress}
                onChange={(address) => setShippingAddress(address)}
                errors={shippingAddressErrors}
              />
            </div>

            <div className="mt-8 w-full">
              <H3 title={"Billing Address"} />
              <RatioField
                name={"same address"}
                label={"Billing address is the same with shipping address"}
                isOn={sameAddress}
                onChange={(selected) => setSameAddress(selected)}
              />

              {!sameAddress && (
                <AddressCollector
                  formState={billingAddress}
                  onChange={(address) => setBillingAddress(address)}
                  errors={billingAddressErrors}
                />
              )}
            </div>
          </div>
        </div>
        <div className="w-2/5 sticky top-32 ml-8 ">
          <H3 title={"Order Summary"} />
          <div className="w-full mt-6 bg-lightGray rounded p-4">
            {orderProducts.length > 0 ? (
              <div>
                {orderProducts.map((pro, index) => (
                  <div
                    key={index}
                    className="w-full mt-6 p-4 rounded bg-lightGray flex flex-row items-center"
                  >
                    <div className="w-[24px] mr-2">
                      <button onClick={() => removeProduct(pro.id)}>
                        <AiOutlineClose className="w-4 h-4 text-red-500" />
                      </button>
                    </div>

                    <div className="w-[calc(100%-190px)] flex flex-row">
                      <img
                        src={
                          pro.imageUris && pro.imageUris.length
                            ? S3_DOMAIN + pro.imageUris[0]
                            : "/default-watch.svg"
                        }
                        alt={"watch image " + pro.watchImageUri}
                        className="w-24 h-24 mr-4 rounded object-contain"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      ></img>

                      <div className="flex flex-col item-start">
                        <ProductTypeTag type={pro.productType} />
                          {pro.productCategory === "watch" ? (
                            <div className="mt-2">
                              <div className="text-left">
                                <span className="text-[14px] mr-2">
                                  {pro.brandName}
                                </span>
                                <span className="text-[14px]">
                                  {pro.modelName}
                                </span>
                              </div>
                              <p className="text-left mt-1 text-[14px]">
                                {pro.referenceNumber}
                              </p>
                              <div className="w-[40px] flex flex-row items-center mt-4">
                                <AiOutlineClose className="w-[12px] h-[12px] mr-1" />
                                <span>{pro.quantity}</span>
                              </div>
                            </div>
                          ) : pro.productCategory === "accessory" ? (
                            <div className="mt-2">
                              <p>{pro.title}</p>
                              <p className="text-brandYellow text-[12px] my-2">{pro.stockOnhand} stock available</p>
                              <div className="w-[80px] flex flex-row justify-between items-center mt-4">
                                <button
                                  onClick={() =>
                                    handleQuantityChange(pro.id, -1)
                                  }
                                >
                                  <AiOutlineMinus className="w-4 h-4" />
                                </button>
                                <span>{pro.quantity}</span>
                                <button
                                  onClick={() =>
                                    handleQuantityChange(pro.id, 1)
                                  }
                                >
                                  <AiOutlinePlus className="w-4 h-4" />
                                </button>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
              
                      </div>
                    </div>
                    <div className="w-[170px] flex flex-row">
                      <PureTextField
                        type="text"
                        name="productPrice"
                        placeholder="Price"
                        autofocus={false}
                        value={pro.price}
                        hasBorder={true}
                        showSearchIcon={false}
                        dollarIcon={true}
                        onChange={(value) =>
                          handlePriceChange({
                            target: { value: value, productId: pro.id },
                          })
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
            <div className="h-[2px] border-b border-borderGray border-solid my-4"></div>

            <div className="w-full bg-lightGray rounded p-4 ">
              <div className="flex justify-between items-center">
                <span>Subtotal</span>
                <span>${convtPrice(orderSubtotal)}</span>
              </div>

              <div className="flex py-3 justify-between items-center">
                <p className="w-[120px]">Shipping Fee</p>
                <div className="w-[170px]">
                  <PureTextField
                    type="text"
                    name="keyword"
                    placeholder="Watch Price"
                    autofocus={true}
                    value={shippingFee}
                    hasBorder={true}
                    showSearchIcon={false}
                    dollarIcon={true}
                    onChange={(shippingFee) =>
                      handleShippingFeeChange({
                        target: { value: shippingFee },
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex border-t border-solid border-borderGray py-4 font-medium text-xl justify-between items-center">
                <span>Total</span>
                <span>${convtPrice(orderTotal)}</span>
              </div>
            </div>
          </div>
          <TextAreaField
            type="text"
            label="Notes (Printed on order PDF)"
            name="adminNotes"
            value={adminNotes}
            rows={6}
            placeholder="order notes..."
            onChange={(adminNotes) => setAdminNotes(adminNotes)}
          />

          <div className="mt-6 w-full">
            <Button name={"Place Order"} action={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
}
