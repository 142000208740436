import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { convToLocalTime } from "../../api/functions";
import { userTrackingLogsRequest } from "../../api/webContentRequets";
import Pagination from "../../components/Pagination";
import ScreenTitle from "../../components/ScreenTitle";
import { DatePicker, SearchField, SelectField } from "../../components/inputs";
import {
  ErrorMessage,
  Loader,
  ProductNameLink,
} from "../../components/smallComponents";
import { K_ACTION_TYPES } from "../../constantData/K_ACTION_TYPES";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import { CsvWriter } from "./CsvWriter.js";
import { Button, LoadingButton } from "../../components/buttons";

export default function UserTrackingList() {
  useDocumentTitle("User Tracking Logs");
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [logs, setLogs] = useState([]);
  
  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [downloadStatus, setDownloadStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [filterStartDate, setFilterStartDate] = useState(thirtyDaysAgo);
  const [filterEndDate, setFilterEndDate] = useState(today);
  const [filterActionType, setFilterActionType] = useState();

  const [filterError, setFilterError] = useState("");

  const handleStartDateChange = (date) => {
    setPageNum(0); // Reset to page 1 when filter changes
    setFilterStartDate(date);
  };
  
  const handleEndDateChange = (date) => {
    setPageNum(0); // Reset to page 1 when filter changes
    setFilterEndDate(date);
  };
  
  const handleActionTypeChange = (actionType) => {
    setPageNum(0); // Reset to page 1 when filter changes
    setFilterActionType(actionType);
  };
  
  const handleSearchChange = (value) => {
    setPageNum(0);
    setKeyword(value);
  };
  


  useEffect(() => {
    const getList = async () => {
      setFilterError("");
      let startTimestamp = filterStartDate
        ? Math.floor(filterStartDate.setHours(0, 0, 0, 0) / 1000)
        : "";
      let endTimestamp = filterEndDate
        ? Math.floor(filterEndDate.setHours(23, 59, 59, 999) / 1000)
        : "";
      if (endTimestamp && startTimestamp > endTimestamp) {
        setFilterError("The start date is greater than end date.");
        return;
      }
      let action_type_value = "";

      if (filterActionType && typeof filterActionType === "object") {
        action_type_value = filterActionType.value;
      }

      try {
        setReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await userTrackingLogsRequest(
          keyword,
          pageNum,
          pageSize,
          startTimestamp,
          endTimestamp,
          action_type_value,
          config,
          dispatch
        );
        if (responseData.data.success) {
          setLogs(responseData.data.data);
          setReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    getList();
  }, [pageNum, keyword, filterStartDate, filterEndDate, filterActionType]);

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };



  const downloadFile = async () => {
    setFilterError("");
    let startTimestamp = filterStartDate
      ? Math.floor(filterStartDate.setHours(0, 0, 0, 0) / 1000)
      : "";
    let endTimestamp = filterEndDate
      ? Math.floor(filterEndDate.setHours(23, 59, 59, 999) / 1000)
      : "";
    if (endTimestamp && startTimestamp > endTimestamp) {
      setFilterError("The start date is greater than end date.");
      return;
    }
    let action_type_value = "";

    if (filterActionType && typeof filterActionType === "object") {
      action_type_value = filterActionType.value;
    }

    let csv_data = [];
    try {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: true,
      }));

      const responseData = await userTrackingLogsRequest(
        keyword,
        0,
        999999999999999,
        startTimestamp,
        endTimestamp,
        action_type_value,
        config,
        dispatch
      );

      if (responseData.data.success) {
        csv_data = responseData.data.data.events;
        setDownloadStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setDownloadStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
    //get csv file
    let csvString = "";
    let csvWriter = new CsvWriter([
      "Time",
      "User Name",
      "User Email",
      "Action Type",
      "Action Value",
      "Product",
      "Product ID",
    ]);
    for (let line of csv_data) {
      let product_name = "";
      if(Number(line.productId) > 0){
        let name_arr = [];
        if(line.brandName){
          name_arr.push(line.brandName);
        }
        if(line.modelName){
          name_arr.push(line.modelName);
        }
        if(line.referenceNumber){
          name_arr.push(line.referenceNumber);
        }
        if(name_arr.length){
          product_name = name_arr.join(" ")
        }
      }

      let action_label = "";
      let find_action_type = K_ACTION_TYPES.find(
        (action) => action.value === line.actionType
      );
      if(find_action_type){
        action_label = find_action_type.label
      }

      csvWriter.addLine([
        convToLocalTime(line.atime),
        line.userName ? line.userName : "",
        line.userEmail ? line.userEmail : "",
        action_label,
        line.actionValue,
        product_name,
        Number(line.productId) > 0 ? line.productId : ""
        
      ]);
    }
    csvString = csvWriter.content;

    // create a download link
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

    const startDate = new Date(filterStartDate);
    const endDate = new Date(filterEndDate);

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `WatchSpace_user_tracking_logs_${startDate.toLocaleDateString()}_to_${endDate.toLocaleDateString()}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const DownloadBtn = () => {
    return (
      <div style={{ width: "auto" }}>
        {downloadStatus.loading ? (
          <LoadingButton name="Downloading.." />
        ) : (
          <Button name="Download" action={downloadFile} />
        )}
      </div>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/user-tracking/list">
            User Tracking Logs
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="User Tracking Logs" />
        <div className="grid grid-cols-3 gap-6">
          <div className="grid grid-cols-2 gap-6">
            <DatePicker
              label={"Start Date"}
              value={filterStartDate}
              onChange={handleStartDateChange}
            />
            <DatePicker
              label={"End Date"}
              value={filterEndDate}
              onChange={handleEndDateChange}
            />
          </div>

          <SelectField
            label="Action Type"
            placeHolder="Select type"
            selectOptions={K_ACTION_TYPES}
            value={filterActionType}
            extractKey=""
            onChange={handleActionTypeChange}
            error={""}
            clearable={true}
          />
        </div>
        {filterError && <ErrorMessage mess={filterError} />}
        <div className="flex flex-row justify-between items-end">
          <div className="w-1/2 mt-8">
            <SearchField
              placeholder="Type user name/email to search..."
              initialQuery={keyword}
              onChange={handleSearchChange}
            />
          </div>
          <DownloadBtn />
        </div>

        {reqStatus.loading ? (
          <Loader mess="Requesting user tracking data..." />
        ) : reqStatus.success ? (
          <div className={commonStyle.longDataTable}>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Time</th>
                  <th>User Name</th>
                  <th>User Email</th>
                  <th>Action Type</th>
                  <th>Action Value</th>
                  <th>Product</th>
                </tr>
              </thead>
              <tbody>
                {logs.events.length > 0 ? (
                  logs.events.map((item, index) => (
                    <tr key={index}>
                      <td className="w-[160px]">
                        {convToLocalTime(item.atime)}
                      </td>
                      <td>{item.userName ? item.userName : ""}</td>
                      <td>{item.userEmail ? item.userEmail : ""}</td>
                      <td>
                        {K_ACTION_TYPES.find(
                          (action) => action.value === item.actionType
                        )?.label || item.actionType}
                      </td>
                      <td>{item.actionValue}</td>
                      <td>
                        {Number(item.productId) > 0 ? (
                          <ProductNameLink
                            productId={item.productId}
                            image={
                              item.imageUris.length ? item.imageUris[0] : ""
                            }
                            brandName={item.brandName}
                            modelName={item.modelName}
                            referenceNumber={item.referenceNumber}
                            price={item.price}
                            productCategory={item.productCategory}
                            title={item.title}
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>There are no data available.</td>
                  </tr>
                )}
              </tbody>
            </table>
            {logs.events.length > 0 ? (
              <Pagination
                totalPageNum={logs.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : reqStatus.error ? (
          <ErrorMessage mess={reqStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
