import React, { useEffect, useState } from "react";
import { IoIosArrowRoundForward } from "react-icons/io";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { dashboardDataRequest } from "../../api/webContentRequets";
import { TextLinkButton } from "../../components/buttons";
import {
  ErrorMessage,
  Loader,
  NoDataChart,
  OrderStatusTag,
  ProductName,
  ProductStatusTag,
  UserAvatar,
} from "../../components/smallComponents";
import { convtPrice } from "../../api/functions";

export default function Dashboard() {
  useDocumentTitle("Dashboard");
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  let localToday = new Date();
  let localPast7day = new Date(new Date() - 7 * 24 * 60 * 60 * 1000);

  let startTimestamp = Math.floor(localToday.setHours(0, 0, 0, 0) / 1000);
  let endTimestamp = Math.floor(localPast7day.setHours(23, 59, 59, 999) / 1000);

  const [startTime, setStartTime] = useState(startTimestamp);
  const [endTime, setEndTime] = useState(endTimestamp);
  const [dashboardData, setDashboardData] = useState();

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const getFilterData = (time) => {
    setStartTime(time.startTime);
    setEndTime(time.endTime);
    //dispatch(saleTrendsDataRequest(time.startTim,time.endTime))
  };

  useEffect(() => {
    const getDocDetails = async () => {
      try {
        setRequestStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await dashboardDataRequest(
          config,
          startTime,
          endTime
        );

        if (responseData.data.success) {
          setRequestStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
            error: "",
          }));

          setDashboardData(responseData.data.data);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    getDocDetails();
  }, [startTime, endTime]);

  const DataItem = ({ title, value }) => {
    return (
      <div className="w-full flex flex-col rounded p-4 bg-lightbg hover:bg-brandYellow h-full items-center justify-center">
        <span className="text-gray text-sm">{title}</span>

        <span className="text-3xl font-medium mt-4">{value}</span>
      </div>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white rounded p-4">
          <p className="">{label}</p>
          <div className="mt-4">
            <span className="text-[#9083ED]">{payload[0].dataKey}</span>
            <span className="text-[#9083ED]">: {payload[0].value}</span>
          </div>
          {payload[1] && (
            <div className="mt-2">
              <span className="text-[#D6CAFF]">{payload[1].dataKey}</span>
              <span className="text-[#D6CAFF]">: {payload[1].value}</span>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full h-full p-8">
        {requestStatus.loading ? (
          <Loader mess="Requesting Data" />
        ) : requestStatus.success ? (
          <div className="w-full mt-4 flex">
            {/* datas */}
            <div className="w-1/2 ">
              <div className="flex justify-between">
                <h2 className="mb-4">Overview</h2>
              </div>

              <div className="w-full flex flex-row justify-between h-[112px]">
                <div className="w-[32%] h-full">
                  <DataItem
                    title={"Dealer"}
                    value={
                      dashboardData.users.filter((x) => x.role === "Dealer")
                        .length
                        ? dashboardData.users.filter(
                            (x) => x.role === "Dealer"
                          )[0].cnt
                        : 0
                    }
                  />
                </div>
                <div className="w-[32%] h-full">
                  <DataItem
                    title={"Private Seller"}
                    value={
                      dashboardData.users.filter(
                        (x) => x.role === "Verified Seller"
                      ).length
                        ? dashboardData.users.filter(
                            (x) => x.role === "Verified Seller"
                          )[0].cnt
                        : 0
                    }
                  />
                </div>
                <div className="w-[32%] h-full">
                  <DataItem
                    title={"General Users"}
                    value={
                      dashboardData.users.filter(
                        (x) => x.role === "Normal User"
                      ).length
                        ? dashboardData.users.filter(
                            (x) => x.role === "Normal User"
                          )[0].cnt
                        : 0
                    }
                  />
                </div>
              </div>

              <div className="w-full mt-4 flex flex-row justify-between h-[112px]">
                <div className="w-[32%] h-full">
                  <DataItem
                    title={"WatchSpace Orders"}
                    value={dashboardData.platformOrderCnt}
                  />
                </div>
                <div className="w-[32%] h-full">
                  <DataItem
                    title={"Dealer Orders"}
                    value={dashboardData.dealerOrderCnt}
                  />
                </div>
                <div className="w-[32%] h-full">
                  <DataItem title={"Products"} value={dashboardData.products} />
                </div>
              </div>

              {dashboardData.ongoingUsers.length ? (
                <div className="w-full mt-8">
                  <div className="flex justify-between">
                    <h2>Awaiting Approve Users</h2>
                    <NavLink
                      to={"/users"}
                      className={` text-gray hover:text-brandYellow flex items-center`}
                    >
                      more <IoIosArrowRoundForward />
                    </NavLink>
                  </div>
                  <div className="w-full">
                    <table className="border-separate w-full border-spacing-y-3">
                      <thead>
                        <tr className="h-12 text-left text-sm text-gray">
                          <th className="align-middle pl-4">User Name</th>

                          <th className="align-middle">Apply Role</th>
                          <th className="align-middle">Status</th>

                          <th className="align-middle"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData.ongoingUsers.map((item, index) => (
                          <tr key={index} className="h-12">
                            <td className="align-middle rounded-l-full bg-lightbg pl-4">
                              {item.userName}
                            </td>

                            <td className="align-middle bg-lightbg">
                              {item.applyRole}
                            </td>
                            <td className="align-middle bg-lightbg">
                              {item.verifStatus}
                            </td>

                            <td className="align-middle rounded-r-full bg-lightbg">
                              <TextLinkButton
                                name="View"
                                path={`/users/details/${item.id}`}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="w-full mt-8">
                <div className="flex justify-between mb-4">
                  <h2>Ongoing Orders</h2>
                  <NavLink
                    to={"/orders"}
                    className={` text-gray hover:text-brandYellow flex items-center`}
                  >
                    more <IoIosArrowRoundForward />
                  </NavLink>
                </div>

                <div className="w-full">
                  <table className="border-separate w-full border-spacing-y-3">
                    <tbody>
                      {dashboardData.ongoingOrders.length ? (
                        dashboardData.ongoingOrders.map((item, index) => (
                          <tr key={index} className="h-12">
                            <td className="align-middle rounded-l-xl bg-lightbg pl-4">
                              {item.products.length ? (
                                <div>
                                  {item.products.map((pro, index) => (
                                    <ProductName
                                      key={index}
                                      productCategory={pro.productCategory ? pro.productCategory : "watch"}
                                      title={pro.title ? pro.title : ""}
                                      image={pro.imageUris && pro.imageUris.length ? pro.imageUris[0] : ""}
                                      brandName={pro.brandName}
                                      modelName={pro.modelName}
                                      referenceNumber={pro.referenceNumber}
                                      price={pro.price}
                                    />
                                  ))}
                                  <p className="pl-4 mb-4">Total: ${convtPrice(item.orderTotal)}</p>
                                </div>
                              ) : (
                                <></>
                              )}
                            </td>

                            <td className="bg-lightbg pt-4">
                              <OrderStatusTag currState={item.currStateDesc} />
                            </td>

                            <td className="pt-4 rounded-r-xl bg-lightbg pr-4">
                              <TextLinkButton
                                name="View"
                                path={`/orders/details/${item.id}`}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoDataChart text="No data available" />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="w-1/2  ml-8 ">
              <h2 className="mb-4">Trends Over the Last 7 Days</h2>
              <div className="w-full h-[320px] py-8 rounded bg-lightbg text-sm">
                {dashboardData.trendsData.length ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      width={600}
                      height={300}
                      data={dashboardData.trendsData}
                      margin={{
                        top: 5,
                        right: 0,
                        left: 0,
                        bottom: 5,
                      }}
                    >
                      {/* <CartesianGrid strokeDasharray="3 3" /> */}
                      <XAxis dataKey="date" />
                      <YAxis
                        yAxisId="left"
                        orientation="left"
                        stroke="#9083ED"
                      />
                      <YAxis
                        yAxisId="right"
                        orientation="right"
                        stroke="#D6CAFF"
                      />
                      <Tooltip
                        content={<CustomTooltip />}
                        cursor={{ fill: "transparent" }}
                      />
                      <Legend />
                      <Bar
                        yAxisId="left"
                        dataKey="products"
                        stackId="1"
                        fill="#9083ED"
                        radius={16}
                        barSize={16}
                      />
                      <Bar
                        yAxisId="right"
                        dataKey="users"
                        stackId="2"
                        fill="#D6CAFF"
                        radius={16}
                        barSize={16}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <NoDataChart text="No data available" />
                )}
              </div>
              <div className="w-full mt-12">
                <div className="flex justify-between mb-4">
                  <h2>Most Liked Products</h2>
                  <NavLink
                    to={"/products"}
                    className={` text-gray hover:text-brandYellow flex items-center`}
                  >
                    more <IoIosArrowRoundForward />
                  </NavLink>
                </div>

                <div className="w-full">
                  <table className="border-separate w-full border-spacing-y-3">
                    <tbody>
                      {dashboardData.topProducts.length ? (
                        dashboardData.topProducts.map((item, index) => (
                          <tr key={index} className="h-12">
                            <td className="align-middle rounded-l-xl bg-lightbg pl-4">
                              <ProductName
                                productCategory={
                                  item.productCategory
                                    ? item.productCategory
                                    : "watch"
                                }
                                title={item.title ? item.title : ""}
                                image={
                                  item.imageUris.length ? item.imageUris[0] : ""
                                }
                                brandName={item.brandName}
                                modelName={item.modelName}
                                referenceNumber={item.referenceNumber}
                                price={item.price}
                              />
                            </td>

                            <td className="align-middle bg-lightbg">
                              <ProductStatusTag currState={item.status} />
                            </td>

                            <td className="align-middle rounded-r-xl bg-lightbg pr-4">
                              <TextLinkButton
                                name="View"
                                path={`/products/details/${item.id}`}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoDataChart text="No data available" />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* <ScreenTitle title="Order List" /> */}
          </div>
        ) : requestStatus.error ? (
          <ErrorMessage mess={requestStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
