import React, { useState } from "react";
import { useSelector } from "react-redux";
import menuStyle from "../../style/menuStyle.module.css";
import CollapsedMenu from "./CollapsedMenu";
import SideMenuItem from "./SideMenuItem";
import {
  BsBoxArrowInLeft,
  BsBoxArrowInRight
} from "react-icons/bs";
import { menuItemsData } from "../../menuItemsData";
// import {Modal} from "../../components/viewComponents";

function SideMenu() {
  const { userDetails, success } = useSelector((state) => state.accountUserDetails);

  const [isCollapsed, setCollapsed] = useState(true);
  let copyMenuItems = menuItemsData();
  let menuItems = menuItemsData();


  function removeNoAccessItem(nodes, deleteNodes) {
    let roles = userDetails.data.permissions;
    for (let i = nodes.length - 1; i >= 0; i--) {
   
      // check if has children
      if (nodes[i].children && nodes[i].isChildrenShow) {
        removeNoAccessItem(nodes[i].children, deleteNodes[i].children);
      }
      
      if (nodes[i].children && !nodes[i].isChildrenShow && nodes[i].permissionCheck) {
          if (!roles.includes(nodes[i].name)) {
            deleteNodes.splice(i, 1);
          }
      }

      if (!nodes[i].children && nodes[i].permissionCheck) {
        if (!roles.includes(nodes[i].name)) {
          deleteNodes.splice(i, 1);
        }
      }
    }
  }

  if (success) {
    removeNoAccessItem(copyMenuItems, menuItems);
  }

  return isCollapsed ? (
    <div className={menuStyle.sidemenuContainer}>
      <div className={menuStyle.sidemenu}>
        {menuItems.map((item, index) => (
          <SideMenuItem key={index} item={item} />
        ))}
      </div>
      <div
        className={menuStyle.collapsedCon}
        onClick={() => setCollapsed(false)}
      >
        <BsBoxArrowInLeft />
        <p>Collapse Side Menu</p>
      </div>
    </div>
  ) : (
    <div className={menuStyle.sidemenuContainerColla}>
      <div className={menuStyle.sidemenu}>
        {menuItems.map((item,index) => (
          <CollapsedMenu key={index} item={item} />
        ))}
      </div>
      <div
        className={menuStyle.collapsedCon}
        onClick={() => setCollapsed(true)}
      >
        <BsBoxArrowInRight />
      </div>
    </div>
  );
}

export default SideMenu;
