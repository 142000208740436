import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, CloseButton, LoadingButton } from "../../components/buttons";
import { updateAccessoryStockLevelRequest } from "../../api/productsRequests";
import ReactModal from "react-modal";
import commonStyle from "../../style/commonStyle.module.css";
import { GoCheck } from "react-icons/go";
import { FieldLabel, SelectField, TextField } from "../../components/inputs";
import { ErrorMessage } from "../../components/smallComponents";

export default function UpdateStockLevelModal({
productId,
  modalOpen,
  modalTitle,
  successActionText,
  onClose,
  modalText,
  successActionDone,
  modalButtonText,
  oriStockLevel,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };
  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [formState, setFormState] = useState({
    stockLevel: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const validate = (values) => {
    let errors = {};
    if (!values.stockLevel) {
      errors.stockLevel = "Please enter a valid quantity.";
    }
    return errors;
  };

  const modalAction = async () => {
    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      updateRequest();
    } else {
      setFormErrors(hasErrors);
    }
  };

  const updateRequest = async () => {
    try {
      setReqStatus({ ...reqStatus, loading: true });
      let submitValues = {
        productId: productId,
        stockLevel: formState.stockLevel
      }
      const responseData = await updateAccessoryStockLevelRequest(submitValues, config);

      if (responseData.data.success) {
        setReqStatus({ ...reqStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus({ ...reqStatus, loading: false, error: error });
    }
  };

  const closeModal = () => {
    if (reqStatus.success) {
      successActionDone();
    }
    onClose();
  };

  return (
    <div>
      <ReactModal
        isOpen={modalOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="w-full">
            <div className="flex justify-end">
              <CloseButton action={closeModal} />
            </div>
            {reqStatus.success ? (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>
                  <div className="w-full flex items-center justify-center">
                    <div className="w-20 h-20 rounded-full border-solid border border-brandYellow flex items-center justify-center mt-4">
                      <GoCheck className="w-10 h-10 text-brandYellow" />
                    </div>
                  </div>
                  <p className="my-6">{successActionText}</p>
                  <Button name="OK" action={closeModal} />
                </div>
              </div>
            ) : (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>
                  <div className="mt-8 w-full">
                    <p>{modalText}</p>

                    <FieldLabel label={"Current Stock Level"} />
                    <p className="mt-2">
                      {Number(oriStockLevel) > 0 ? oriStockLevel : "No stock available"}
                    </p>

                    <TextField
                      type="text"
                      label="Stock on Hand"
                      name="stockLevel"
                      value={formState.stockLevel}
                      onChange={(stockLevel) =>
                        handleChange({
                          target: {
                            value: stockLevel,
                            name: "stockLevel",
                          },
                        })
                      }
                      error={formErrors.stockLevel}
                    />

                    {reqStatus.error && <ErrorMessage mess={reqStatus.error} />}
                    <div className="mt-8">
                      {reqStatus.loading ? (
                        <LoadingButton name="Proceeding..." />
                      ) : (
                        <Button name={modalButtonText} action={modalAction} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}
