import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  collectionOtionsList
} from "../../api/webContentRequets";
import Pagination from "../../components/Pagination";
import ScreenTitle from "../../components/ScreenTitle";
import { TextLinkButton } from "../../components/buttons";
import { SelectProductCollectionDialog } from "../../components/dialogs";
import { SearchField } from "../../components/inputs";
import { ProductTypeTag } from "../../components/productComponents";
import {
  ErrorMessage,
  Loader,
  ProductName,
  ProductNameLink,
  ProductStatusTag,
} from "../../components/smallComponents";
import { productListRequest } from "../../redux/actions/userActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";

export default function SoldProductList() {
  useDocumentTitle("Sold Product List");
  const dispatch = useDispatch();
  const { loading, products, success, error } = useSelector(
    (state) => state.productList
  );

  const pageSize = 12;
  const [pageNum, setPageNum] = useState(0);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    dispatch(productListRequest(keyword, pageNum, pageSize, "Sold"));
  }, [dispatch, pageNum, keyword]);


  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };
  const handleSearchChange = (value) => {
    setKeyword(value);
    setPageNum(0);
  };

  return (
    <div className={commonStyle.pageContainer}>
   

      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/products">
            Products
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Sold Product List" />
        <div className="w-1/2 mt-8">
          <SearchField
            placeholder="Type brand,model, reference number to search..."
            initialQuery={keyword}
            onChange={handleSearchChange}
          />
        </div>

        {loading ? (
          <Loader mess="Requesting sold products..." />
        ) : success ? (
          <div className={commonStyle.longDataTable}>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Product</th>
                  <th>Seller</th>
                  <th>Status</th>
                  <th>Collections</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {products.products.length > 0 ? (
                  products.products.map((item, index) => (
                    <tr key={index}>
                      <td className="w-[160px]">
                        {" "}
                        <ProductTypeTag type={item.type} />
                      </td>
                      <td>
                        <ProductNameLink
                          productId={item.id}
                          image={item.imageUris.length ? item.imageUris[0] : ""}
                          brandName={item.brandName}
                          modelName={item.modelName}
                          referenceNumber={item.referenceNumber}
                          price={item.price}
                          productCategory={item.productCategory}
                          title={item.title}
                        />
                      </td>

                      <td>
                        <div>
                          <p>
                            {item.type === "SELLERPRODUCT"
                              ? item.sellerName
                              : "WatchSpace"}
                          </p>
                          {item.type === "SELLERPRODUCT" &&
                          item.create_by_admin_id > 0 ? (
                            <p className="text-xs mt-2 w-fit text-white bg-brandColor rounded-full px-3 py-1">
                              WatchSpace Added
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                      <td>
                        <ProductStatusTag currState={item.status} />
                      </td>

                      <td>
                        <TextLinkButton
                          name="View Details"
                          path={`/products/details/${item.id}`}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>There are no product available.</td>
                  </tr>
                )}
              </tbody>
            </table>
            {products.products.length > 0 ? (
              <Pagination
                totalPageNum={products.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
