import {
  CONSIGNMENT_QUOTE_DETAILS_FAIL,
  CONSIGNMENT_QUOTE_DETAILS_REQUEST,
  CONSIGNMENT_QUOTE_DETAILS_SUCCESS,
  CONSIGNMENT_QUOTE_LIST_FAIL,
  CONSIGNMENT_QUOTE_LIST_REQUEST,
  CONSIGNMENT_QUOTE_LIST_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  SERVICE_QUOTE_DETAILS_FAIL,
  SERVICE_QUOTE_DETAILS_REQUEST,
  SERVICE_QUOTE_DETAILS_SUCCESS,
  SERVICE_QUOTE_LIST_FAIL,
  SERVICE_QUOTE_LIST_REQUEST,
  SERVICE_QUOTE_LIST_SUCCESS,
  UPDATE_ORDER_PRICE_FAIL,
  UPDATE_ORDER_PRICE_REQUEST,
  UPDATE_ORDER_PRICE_SUCCESS,
  UPDATE_ORDER_SHIPPING_FEE_FAIL,
  UPDATE_ORDER_SHIPPING_FEE_REQUEST,
  UPDATE_ORDER_SHIPPING_FEE_SUCCESS,
  USER_ORDER_LIST_FAIL,
  USER_ORDER_LIST_REQUEST,
  USER_ORDER_LIST_SUCCESS,
  WATCH_REQUEST_DETAILS_FAIL,
  WATCH_REQUEST_DETAILS_REQUEST,
  WATCH_REQUEST_DETAILS_SUCCESS,
  WATCH_REQUEST_LIST_FAIL,
  WATCH_REQUEST_LIST_SUCCESS,
} from "../constants/orderConstants";

export const orderListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return { loading: true, success: false };
    case ORDER_LIST_SUCCESS:
      return { loading: false, orders: action.payload, success: true };
    case ORDER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userOrderListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case USER_ORDER_LIST_REQUEST:
      return { loading: true, success: false };
    case USER_ORDER_LIST_SUCCESS:
      return { loading: false, orders: action.payload, success: true };
    case USER_ORDER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const orderDetailsReducer = (state = { details: {} }, action) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return { loading: true, success: false };
    case ORDER_DETAILS_SUCCESS:
      return { loading: false, details: action.payload, success: true };
    case ORDER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateOrderPriceReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ORDER_PRICE_REQUEST:
      return { loading: true, success: false };
    case UPDATE_ORDER_PRICE_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_ORDER_PRICE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateOrderShippingFeeReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ORDER_SHIPPING_FEE_REQUEST:
      return { loading: true, success: false };
    case UPDATE_ORDER_SHIPPING_FEE_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_ORDER_SHIPPING_FEE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const consignmentQuoteListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case CONSIGNMENT_QUOTE_LIST_REQUEST:
      return { loading: true, success: false };
    case CONSIGNMENT_QUOTE_LIST_SUCCESS:
      return { loading: false, orders: action.payload, success: true };
    case CONSIGNMENT_QUOTE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const consignmentQuoteDetailsReducer = (
  state = { details: {} },
  action
) => {
  switch (action.type) {
    case CONSIGNMENT_QUOTE_DETAILS_REQUEST:
      return { loading: true, success: false };
    case CONSIGNMENT_QUOTE_DETAILS_SUCCESS:
      return { loading: false, details: action.payload, success: true };
    case CONSIGNMENT_QUOTE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const serviceQuoteListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case SERVICE_QUOTE_LIST_REQUEST:
      return { loading: true, success: false };
    case SERVICE_QUOTE_LIST_SUCCESS:
      return { loading: false, orders: action.payload, success: true };
    case SERVICE_QUOTE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const serviceQuoteDetailsReducer = (
  state = { details: {} },
  action
) => {
  switch (action.type) {
    case SERVICE_QUOTE_DETAILS_REQUEST:
      return { loading: true, success: false };
    case SERVICE_QUOTE_DETAILS_SUCCESS:
      return { loading: false, details: action.payload, success: true };
    case SERVICE_QUOTE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const wacthRequestListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case WATCH_REQUEST_LIST_FAIL:
      return { loading: true, success: false };
    case WATCH_REQUEST_LIST_SUCCESS:
      return { loading: false, orders: action.payload, success: true };
    case WATCH_REQUEST_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const wacthRequestDetailsReducer = (
  state = { details: {} },
  action
) => {
  switch (action.type) {
    case WATCH_REQUEST_DETAILS_REQUEST:
      return { loading: true, success: false };
    case WATCH_REQUEST_DETAILS_SUCCESS:
      return { loading: false, details: action.payload, success: true };
    case WATCH_REQUEST_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
