import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { GrSecure } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { convToLocalDate, convtPrice } from "../../api/functions";
import ScreenTitle from "../../components/ScreenTitle";
import {
  AddressLine,
  ErrorMessage,
  FormattedText,
  H3,
  Loader,
  OrderStatusTag,
  ProductName,
  ProductNameLink,
  UserAvatar,
} from "../../components/smallComponents";
import {
  orderDetailsRequest,
  updateOrderPriceRequest,
  updateOrderShippingFeeRequest,
} from "../../redux/actions/orderActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import {
  ActionWithFileButton,
  AdminCancelOrderActionButton,
  AdminUpdatePriceButton,
  ContactButton,
  OrderActionButton,
  ShipOrderButton,
} from "./orderActionButtons";
import { PureTextField } from "../../components/inputs";
import { AdminNotesDialog, ViewFilesDialog } from "../../components/dialogs";
import { Button } from "../../components/buttons";
import { AiOutlineClose } from "react-icons/ai";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function OrderDetails() {
  const { id } = useParams();
  useDocumentTitle("Order Details");
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success: detailsSuccess } = useSelector(
    (state) => state.accountUserDetails
  );
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const { loading, details, success, error } = useSelector(
    (state) => state.orderDetails
  );

  const {
    success: priceUpdateSuccess,
    loading: priceUpdateLoading,
    error: priceUpdateError,
  } = useSelector((state) => state.updateOrderPrice);

  const {
    success: shippingUpdateSuccess,
    loading: shippingUpdateLoading,
    error: shippingUpdateError,
  } = useSelector((state) => state.updateOrderShippingFee);

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [courierInfo, setCourierInfo] = useState("");

  const [hasEditPermission, setHasEditPermission] = useState(false);

  const [editingPrices, setEditingPrices] = useState({}); // Store which product is being edited
  const [updatedPrices, setUpdatedPrices] = useState({}); // Store updated price values

  const [editShipping, setEditShipping] = useState(false);
  const [shippingFee, setShippingFee] = useState("");

  const [showPaymentResultModal, setShowPaymentResultModal] = useState(false);
  const [paymentResultFiles, setPaymentResultFiles] = useState("");

  const [adminNotesModal, setAdminNotesModal] = useState(false);
  const [updateDetailsCnt, setUpdateDetailsCnt] = useState(0);
  const [orderActionCnt, setOrderActionCnt] = useState(0);

  useEffect(() => {
    dispatch(orderDetailsRequest(id, "1.0.2"));
    setSaveStatus({
      loading: false,
      success: false,
      error: "",
    });
  }, [
    dispatch,
    saveStatus.success,
    shippingUpdateSuccess,
    priceUpdateSuccess,
    updateDetailsCnt,
    orderActionCnt,
  ]);

  useEffect(() => {
    if (detailsSuccess) {
      if (userDetails.data.permissions.includes("edit_order")) {
        setHasEditPermission(true);
      } else {
        setHasEditPermission(false);
      }
    }
  }, [detailsSuccess]);

  useEffect(() => {
    if (success) {
      if (
        details.actionRecords.filter(
          (x) => x.action === "SHIPPED_PRODUCT_TO_BUYER"
        ).length
      ) {
        setCourierInfo(
          JSON.parse(
            details.actionRecords.filter(
              (x) => x.action === "SHIPPED_PRODUCT_TO_BUYER"
            )[0].action_info
          )
        );
      }
      if (
        details.actionRecords.filter(
          (x) => x.action === "BUYER_UPLOAD_PAYMENT_RESULT"
        ).length
      ) {
        let fileRecords = details.actionRecords.filter(
          (x) => x.action === "BUYER_UPLOAD_PAYMENT_RESULT"
        );
        let files = [];
        fileRecords.forEach((ele) => {
          files.push(JSON.parse(ele.action_info));
        });
        setPaymentResultFiles({
          notes: "",
          attachments: files,
        });
      }
    } else {
      setCourierInfo("");
      setPaymentResultFiles("");
    }
  }, [success]);

  const successActionDone = () => {
    setOrderActionCnt(orderActionCnt + 1);
  };

  const handleEditPrice = (productId, currentPrice) => {
    setEditingPrices((prev) => ({ ...prev, [productId]: true }));
    setUpdatedPrices((prev) => ({ ...prev, [productId]: currentPrice }));
  };

  const handleSavePrice = (productId) => {
    const newPrice = updatedPrices[productId];
    let update_data = {
      productId: productId,
      newPrice: newPrice,
    };
    dispatch(updateOrderPriceRequest(id, update_data));
    setEditingPrices((prev) => ({ ...prev, [productId]: false }));
  };

  const saveNewShippingFee = () => {
    let data = {
      shippingFee: shippingFee,
    };
    dispatch(updateOrderShippingFeeRequest(id, data));
    setEditShipping(false);
  };

  const openAdminNotesModal = () => {
    setAdminNotesModal(true);
  };

  const closeNotesDialogWithSuccess = () => {
    setAdminNotesModal(false);
    setUpdateDetailsCnt(updateDetailsCnt + 1);
  };
  const SummaryItem = ({ name, value }) => {
    return (
      <div className="w-full flex flex-row items-center justify-between mt-4">
        <span className="block">{name}</span>
        <span className="block">{value}</span>
      </div>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      {showPaymentResultModal && (
        <ViewFilesDialog
          isDialogOpen={showPaymentResultModal}
          closeDialog={() => setShowPaymentResultModal(false)}
          file={paymentResultFiles}
          modalTitle="Payment Result"
        />
      )}
      {adminNotesModal && (
        <AdminNotesDialog
          modalOpen={adminNotesModal}
          modalTitle={"Order Notes"}
          successActionText={"Order notes updated successfully"}
          orderId={id}
          onClose={() => setAdminNotesModal(false)}
          modalText={"Add Notes to order (Printed on order PDF)"}
          successActionDone={closeNotesDialogWithSuccess}
          modalButtonText={"Submit"}
          oriAdminNotes={success ? details.adminNotes : ""}
        />
      )}

      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/orders">
            Orders
          </NavLink>
          <NavLink underline="hover" color="inherit">
            Details
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Orders Details" />

        {loading ? (
          <Loader mess="Requesting details..." />
        ) : success ? (
          <div className="w-full">
            <div className="w-full h-full border-b border-solid border-borderGray mt-6 lg:justify-between">
              <div className="w-full flex items-center p-4">
                <span>Order Number: {details.orderNumber}</span>

                <OrderStatusTag currState={details.currStateDesc} />
              </div>
              {priceUpdateError && (
                <div className="w-full my-2">
                  <ErrorMessage mess={priceUpdateError} />
                </div>
              )}
              {shippingUpdateError && (
                <div className="w-full my-2">
                  <ErrorMessage mess={shippingUpdateError} />
                </div>
              )}

              {details.products && details.products.length ? (
                <div className="w-full px-4 pb-6">
                  {details.products.map((pro, index) => (
                    <div className="w-full flex mt-4" key={index}>
                      <div className="relative w-16 lg:w-20 h-16 lg:h-20 rounded">
                        <img
                          alt={
                            pro.imageUris.length
                              ? pro.imageUris[0]
                              : "default-watch"
                          }
                          src={
                            pro.imageUris.length
                              ? S3_DOMAIN + pro.imageUris[0]
                              : "/default-watch.svg"
                          }
                          className="w-full h-full object-cover"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                          }}
                        />
                      </div>
                      <div className="w-[calc(100%-64px)] lg:w-[calc(100%-80px)] ml-4">
                      <NavLink to={`/products/details/${pro.productId}`} className={"hover:underline"}>
                        <p className="truncate overflow-hidden">
                          {pro.productCategory === "watch" ? pro.brandName + " " + pro.modelName : pro.title}
                        </p>
                      </NavLink>

                        <p>{pro.referenceNumber}</p>
                        <div className="flex flex-row items-center justify-between">
                          <div className="w-[40px] flex flex-row items-center mt-1">
                            <AiOutlineClose className="w-[12px] h-[12px] mr-1" />
                            <span>{pro.quantity}</span>
                          </div>
                          <div>
                            {editingPrices[pro.productId] ? (
                              <div className="flex flex-row items-center">
                                <div className="w-[160px]">
                                  <PureTextField
                                    type="text"
                                    name="keyword"
                                    placeholder="New price"
                                    autofocus={true}
                                    hasBorder={true}
                                    showSearchIcon={false}
                                    dollarIcon={true}
                                    value={updatedPrices[pro.productId]}
                                    onChange={(value) =>
                                      setUpdatedPrices((prev) => ({
                                        ...prev,
                                        [pro.productId]: value,
                                      }))
                                    }
                                  />
                                </div>
                                <button
                                  onClick={() => handleSavePrice(pro.productId)}
                                  className="text-xs ml-2 text-green-500 hover:text-brandYellow"
                                >
                                  Save
                                </button>
                                <button
                                  onClick={() =>
                                    setEditingPrices((prev) => ({
                                      ...prev,
                                      [pro.productId]: false,
                                    }))
                                  }
                                  className="text-xs ml-2 text-red-600"
                                >
                                  Cancel
                                </button>
                              </div>
                            ) : (
                              <div>
                                <span className="mr-2">
                                  {" "}
                                  {convtPrice(pro.price)}
                                </span>
                                {details.actions.includes(
                                  "SEND_PAYMENT_METHOD"
                                ) &&
                                  hasEditPermission && (
                                    <button
                                      className="text-sm underline hover:text-brandYellow"
                                      onClick={() =>
                                        handleEditPrice(
                                          pro.productId,
                                          pro.price
                                        )
                                      }
                                    >
                                      Update Price
                                    </button>
                                  )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
              <div className="w-full flex flex-col p-4 justify-end items-end">
                <div className="h-[2px] border-b border-borderGray my-4"></div>
                <SummaryItem
                  name={"Subtotal"}
                  value={convtPrice(details.subTotal)}
                />
                <div className="w-full flex flex-row items-center justify-between mt-4">
                  <span className="block">Shipping</span>
                  {editShipping ? (
                    <div className="flex flex-row items-center">
                      <div className="w-[160px]">
                        <PureTextField
                          type="text"
                          name="shippingFee"
                          placeholder="Shipping fee"
                          autofocus={true}
                          hasBorder={true}
                          showSearchIcon={false}
                          dollarIcon={true}
                          value={shippingFee}
                          onChange={(value) => setShippingFee(value)}
                        />
                      </div>
                      <button
                        onClick={saveNewShippingFee}
                        className="text-xs ml-2 text-green-500 hover:text-brandYellow"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => setEditShipping(false)}
                        className="text-xs ml-2 text-red-600"
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-col items-end">
                      <span className="block">
                        {details.shippingTotal > 0
                          ? convtPrice(details.shippingTotal)
                          : "Free"}
                      </span>
                      {details.actions.includes("SEND_PAYMENT_METHOD") &&
                        hasEditPermission && (
                          <button
                            className="text-sm underline hover:text-brandYellow"
                            onClick={() => setEditShipping(true)}
                          >
                            Update Shipping Fee
                          </button>
                        )}
                    </div>
                  )}
                </div>
                <SummaryItem
                name={"Delivery Method"}
                value={details.deliveryMethod === "instore" ? "Pick up at WatchSpace" : "Ship to me"}
              />
                <div className="w-full h-[2px] border-b border-borderGray my-4"></div>
                <div className="w-full flex flex-row items-center justify-between mt-4">
                  <span className="block text-[18px] font-medium">
                    Order Total
                  </span>
                  <span className="block text-[18px] font-medium">
                    {convtPrice(
                      Number(details.subTotal) + Number(details.shippingTotal)
                    )}
                    USD
                  </span>
                </div>
                {paymentResultFiles && (
                  <button
                    onClick={() => setShowPaymentResultModal(true)}
                    className="w-full text-left lg:text-right hover:text-brandYellow underline text-sm mt-4"
                  >
                    View Payment Result
                  </button>
                )}
                {details.orderPdfUri && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={S3_DOMAIN + details.orderPdfUri}
                    className="mt-4 underline hover:text-brandYellow"
                  >
                    Download Order Certificate
                  </a>
                )}
              </div>
              {hasEditPermission && (
                <div className="w-full p-4">
                  {details.actions.includes("SEND_SHIPPING_LABEL_TO_SELLER") ? (
                    <div className="mt-4">
                      <ActionWithFileButton
                        orderType={"standardOrder"}
                        actionName="SEND_SHIPPING_LABEL_TO_SELLER"
                        modalTitle={"Send Shipping Label"}
                        modalText="Please upload the shipping label"
                        actionButtonName="Send Shipping Label"
                        successActionText={
                          " Shipping label files sent successfully."
                        }
                        orderId={details.id}
                        userType={"platform"}
                        modalButtonText="Send Shipping Label"
                        successActionDone={successActionDone}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {details.actions.includes("PRODUCT_RECEIVED") ? (
                    <div className="mt-4">
                      <OrderActionButton
                        orderType={"standardOrder"}
                        actionName="PRODUCT_RECEIVED"
                        actionButtonName="Confirm Received Product"
                        modalTitle="Confirm Received Product"
                        modalText="Are you sure you have received the product?"
                        successActionText="Product received!"
                        modalButtonText="Yes, Proceed"
                        orderId={details.id}
                        userType={"platform"}
                        successActionDone={successActionDone}
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  {details.actions.includes("PREORDER_PRODUCT_RECEIVED") ? (
                    <div className="mt-4">
                      <OrderActionButton
                        orderType={"standardOrder"}
                        actionName="PREORDER_PRODUCT_RECEIVED"
                        actionButtonName="Confirm Received Product"
                        modalTitle="Confirm Received Product"
                        modalText="Are you sure you have received the product?"
                        successActionText="Product received!"
                        modalButtonText="Yes, Proceed"
                        orderId={details.id}
                        userType={"platform"}
                        successActionDone={successActionDone}
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  {details.actions.includes("SEND_PAYMENT_METHOD") ? (
                    <div className="mt-4">
                      <OrderActionButton
                        orderType={"standardOrder"}
                        actionName="SEND_PAYMENT_METHOD"
                        actionButtonName="Confirm Order"
                        modalTitle="Confirm Order"
                        modalText="After confirm the order, the order price can not be changed, are you sure you want to proceed?"
                        successActionText="Order has been confirmed!"
                        modalButtonText="Yes, Proceed"
                        orderId={details.id}
                        userType={"platform"}
                        successActionDone={successActionDone}
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  {details.actions.includes("DONE_OFFLINE_PAYMENT") ? (
                    <div className="mt-4">
                      <OrderActionButton
                        orderType={"standardOrder"}
                        actionName="DONE_OFFLINE_PAYMENT"
                        actionButtonName="Confirm Received Payment"
                        modalTitle="Confirm Received Payment"
                        modalText="Are you sure you have received the payment?"
                        successActionText="Order payment received!"
                        modalButtonText="Yes, Proceed"
                        orderId={details.id}
                        userType={"platform"}
                        successActionDone={successActionDone}
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  {details.currState === "PRODUCT_RECEIVED_BY_PLATFORM" && (
                    <div className="w-full flex">
                      {details.actions.includes("PRODUCT_INSPECTION_PASS") ? (
                        <div className="w-1/2 mt-4">
                          <ActionWithFileButton
                            orderType={"standardOrder"}
                            actionName="PRODUCT_INSPECTION_PASS"
                            actionButtonName="Passing the Inspection"
                            modalTitle="Passing the Inspection"
                            modalText="Please upload the certificate of the inspection"
                            successActionText="Product successfully passed inspection."
                            modalButtonText="Submit"
                            orderId={details.id}
                            userType={"platform"}
                            successActionDone={successActionDone}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      {details.actions.includes("PRODUCT_INSPECTION_FAIL") ? (
                        <div className="w-1/2 mt-4 ml-4">
                          <ActionWithFileButton
                            orderType={"standardOrder"}
                            actionName="PRODUCT_INSPECTION_FAIL"
                            actionButtonName="Failing the Inspection"
                            modalTitle="Failing the Inspection"
                            modalText="Please upload the certificate of the inspection"
                            successActionText="Product did not pass inspection."
                            modalButtonText="Submit"
                            orderId={details.id}
                            userType={"platform"}
                            successActionDone={successActionDone}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                  {details.actions.includes("SHIPPED_PRODUCT_TO_BUYER") ? (
                    <div className="mt-4">
                      <ShipOrderButton
                        orderType={"standardOrder"}
                        actionName="SHIPPED_PRODUCT_TO_BUYER"
                        actionButtonName="Ship Order"
                        modalTitle="Ship Order"
                        modalText="Please enter order tracking details"
                        successActionText="Shipping details updated successfully."
                        modalButtonText="Submit"
                        orderId={details.id}
                        userType={"platform"}
                        successActionDone={successActionDone}
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  {details.actions.includes("PICK_UP_AT_STORE") ? (
                    <div className="mt-4">
                      <OrderActionButton
                        orderType={"standardOrder"}
                        actionName="PICK_UP_AT_STORE"
                        actionButtonName="Watch picked up at store"
                        modalTitle="Watch Picked Up"
                        modalText="Are you sure the user has picked up watch from store?"
                        successActionText="Order completed!"
                        modalButtonText="Yes, Proceed"
                        orderId={details.id}
                        userType={"platform"}
                        successActionDone={successActionDone}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
            {/* Authenticae Order */}
            {(details.type === "SELLERINSPECTION" ||
              details.type === "BUYERINSPECTION") && (
              <div className="w-full h-full border-b border-solid border-borderGray mt-6  p-4 ">
                <div>
                  <div className="flex items-center">
                    <GrSecure className="w-8 h-8 text-brandYellow" />
                    <span className="text-xl ml-2">Authenticate Guarantee</span>
                  </div>

                  <div className="pl-10 w-full">
                    <p className="">
                      Service Fee: ${convtPrice(details.serviceFee)}
                    </p>
                    {details.type === "BUYERINSPECTION" ? (
                      <p className="mt-1 text-sm text-gray">
                        (Buyer pay service fee)
                      </p>
                    ) : (
                      <p className="mt-1 text-sm text-gray">
                        (Seller pay service fee)
                      </p>
                    )}
                    {details.serviceFeePaid ? (
                      <p className="mt-1">Service Fee Payment Status: Paid</p>
                    ) : (
                      <></>
                    )}
                    {/* seller or buyer pay service fee */}
                  </div>
                </div>
              </div>
            )}

            {details.type !== "PLATFORMDIRECT" &&
              details.type !== "PREORDER" && (
                <div className="w-full h-full border-b border-solid border-borderGray mt-6  p-4">
                  <H3 title={"Seller"} />

                  <div className="flex justify-between flex-col lg:flex-row">
                    <div className="flex items-center mt-4">
                      <UserAvatar name={details.sellerName} />{" "}
                      <NavLink to={`/users/details/${details.sellerId}`} className={"hover:underline ml-4 "}>
                        <p>{details.sellerName}</p>
                      </NavLink>
                    </div>

                    <div className="w-fit text-sm text-darkGray mt-4">
                      <ContactButton
                        recipientId={details.sellerId}
                        productId={details.productId}
                        refType={"PRODUCT"}
                        buttonName={"Contact Seller"}
                      />
                    </div>
                  </div>
                </div>
              )}

            {/* buyer info */}
            <div className="w-full h-full border-b border-solid border-borderGray mt-6  p-4">
              <H3 title={"Buyer"} />

              <div className="flex justify-between flex-col lg:flex-row">
                <div className="flex items-center mt-4">
                  <UserAvatar name={details.buyerName} />{" "}
                  <NavLink to={`/users/details/${details.buyerId}`} className={"hover:underline ml-4 "}>
                    <p>{details.buyerName}</p>
                  </NavLink>
                </div>

                <div className="w-fit text-sm text-darkGray mt-4">
                  <ContactButton
                    recipientId={details.buyerId}
                    productId={details.products.length ? details.products[0].productId : 0}
                    refType={"PRODUCT"}
                    buttonName={"Contact Buyer"}
                  />
                </div>
              </div>
            </div>

            {/* buyer info */}
            <div className="w-full h-full border-b border-solid border-borderGray mt-6 p-4">
              <H3 title={"Shipping Address"} />

              {courierInfo && (
                <div className="text-sm text-darkGray mt-4">
                  <p>Courier: {courierInfo.courier.label}</p>
                  <p>Tracking Number: {courierInfo.trackingNumber}</p>
                </div>
              )}
              <div className="text-sm text-darkGray mt-4">
                <AddressLine
                  firstName={details.shippingAddr.firstName}
                  lastName={details.shippingAddr.lastName}
                  phone={details.shippingAddr.phone}
                  streetLine1={details.shippingAddr.streetLine1}
                  city={details.shippingAddr.city}
                  country={
                    details.shippingAddr.country
                      ? details.shippingAddr.country.label
                      : ""
                  }
                  region={details.shippingAddr.region}
                  postalCode={details.shippingAddr.postalCode}
                  showName={true}
                  showPhone={true}
                />
              </div>
            </div>
            {/* buyer info */}
            <div className="w-full h-full border-b border-solid border-borderGray mt-6 lg:justify-between p-4">
              <H3 title={"Billing Address"} />
              <div className="text-sm text-darkGray mt-4">
                <AddressLine
                  firstName={details.billingAddr.firstName}
                  lastName={details.billingAddr.lastName}
                  phone={details.billingAddr.phone}
                  streetLine1={details.billingAddr.streetLine1}
                  city={details.billingAddr.city}
                  country={
                    details.billingAddr.country
                      ? details.billingAddr.country.label
                      : ""
                  }
                  region={details.billingAddr.region}
                  postalCode={details.billingAddr.postalCode}
                  showName={true}
                  showPhone={true}
                />
              </div>
            </div>

            {/* order status change details */}
            <div className="w-full h-full border-solid border-b border-borderGray mt-6 lg:justify-between p-4">
              <H3 title={"Order Logs"} />
              <div className="text-sm text-darkGray mt-4">
                <div>
                  <span className="text-sm">
                    {convToLocalDate(details.atime)}
                  </span>
                  <span className="text-sm ml-4">Order created</span>
                </div>

                {details.actionRecords.length ? (
                  details.actionRecords.map((item, index) => (
                    <div key={index} className="mt-2">
                      <span className="text-sm">
                        {convToLocalDate(item.atime)}
                      </span>
                      <span className="text-sm ml-4"> {item.actionName}</span>
                      {item.userType && (
                        <span className="text-sm">
                          {" by " + item.userType}
                        </span>
                      )}
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* order actions */}
            <div className="w-full h-full border-b border-solid border-borderGray mt-6 lg:justify-between p-4">
              <H3 title={"Order Notes"} />
              <div className="text-sm text-darkGray mt-4">
                {details.adminNotes && (
                  <FormattedText text={details.adminNotes} />
                )}
                {!details.orderPdfUri && (
                  <div className="w-fit mt-4">
                    <Button
                      name={
                        details.adminNotes
                          ? "Update Order Notes"
                          : "Add Order Notes"
                      }
                      action={openAdminNotesModal}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="w-full flex flex-col justify-start p-4">
              {details.actions.includes("CANCEL_ORDER") && hasEditPermission ? (
                <div className="mt-4">
                  <OrderActionButton
                    orderType={"standardOrder"}
                    actionName="CANCEL_ORDER"
                    actionButtonName="Cancel Order"
                    modalTitle="Cancel Order"
                    modalText="Are you sure you want to cancel the order?"
                    successActionText="Order has been cancelled!"
                    modalButtonText="Yes, Please Cancel The Order"
                    orderId={details.id}
                    userType={"platform"}
                    successActionDone={successActionDone}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* only for order created by admin*/}

            {details.currState === "COMPLETED" &&
            Number(details.adminId) > 0 &&
            hasEditPermission ? (
              <div className="grid grid-cols-1 gap-6 pt-8 pl-4 border-t border-solid border-borderGray">
                <AdminCancelOrderActionButton
                  orderType={"standardOrder"}
                  actionName="CANCEL_ORDER"
                  actionButtonName="Cancel Order"
                  modalTitle="Cancel Order"
                  modalText="Are you sure you want to cancel the order?"
                  successActionText="Order has been cancelled!"
                  modalButtonText="Yes, Please Cancel The Order"
                  orderId={details.id}
                  userType={"platform"}
                  successActionDone={successActionDone}
                />
                <AdminUpdatePriceButton
                  orderType={"standardOrder"}
                  actionButtonName="Update Order Price"
                  modalTitle="Update Order Price"
                  modalText="Please enter the new product price"
                  successActionText="Order price has been updated!"
                  modalButtonText="Update"
                  orderId={details.id}
                  orderProducts={details.products}
                  userType={"platform"}
                  successActionDone={successActionDone}
                  oriPrice={details.price}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
