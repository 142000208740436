
import axios from "axios";
import { authMiddleware } from "../redux/actions/authMiddleware";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;



export const addNewQuestion = async (values, config, dispatch) => {

  try {
    const response = await axios.post(SERVER_URL + `/admin/web_content/faq/new`, values, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }

};

export const editQuestion = async (id, values, config, dispatch) => {

    try {
      const response = await axios.post(SERVER_URL + `/admin/web_content/faq/edit/${id}`, values, config);
      authMiddleware(response, dispatch);
      return response;
    } catch (error) {
      throw error;
    }
  
};

export const questionList = async (page_num, page_size, config, dispatch) => {

    try {
      const response = await axios.get(SERVER_URL + `/admin/web_content/faq/list?page_num=${page_num}&page_size=${page_size}`, config);
      authMiddleware(response, dispatch);
      return response;
    } catch (error) {
      throw error;
    }
  
};

export const questionDetails = async (id, config, dispatch) => {

    try {
      const response = await axios.get(SERVER_URL + `/admin/web_content/faq/details/${id}`, config);
      authMiddleware(response, dispatch);
      return response;
    } catch (error) {
      throw error;
    }
  
};

export const getImageList = async (config, dispatch) => {

  try {
    const response = await axios.get(SERVER_URL + `/admin/web_content/image/list`, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }

};

export const updateImageRequest = async ( imageId, values, config) => {

  try {
    const response = await axios.post(SERVER_URL + `/admin/web_content/image/update/${imageId}`, values, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAgreementListRequest = async (type, config, dispatch) => {

  try {
    const response = await axios.get(SERVER_URL + `/admin/web_content/content/list?type=${type}`, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }

};

export const getContentDetailsRequest = async (id, config, dispatch) => {

  try {
    const response = await axios.get(SERVER_URL + `/admin/web_content/content/details/${id}`, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }

};

export const updateContentRequest = async ( id, values, config) => {

  try {
    const response = await axios.post(SERVER_URL + `/admin/web_content/content/update/${id}`, values, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const newContentRequest = async ( values, type, config) => {

  try {
    const response = await axios.post(SERVER_URL + `/admin/web_content/content/new?type=${type}`, values, config);
    return response;
  } catch (error) {
    throw error;
  }
};



export const dashboardDataRequest = async (config, startTime, endTime) => {

  try {
    const response = await axios.get(SERVER_URL + `/admin/dashboard-data?startTime=${startTime}&endTime=${endTime}`, config);
    return response;
  } catch (error) {
    throw error;
  }

};


export const addNewAd = async (values, config, dispatch) => {

  try {
    const response = await axios.post(SERVER_URL + `/admin/web_content/ads/new`, values, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }

};

export const editAd = async (id, values, config, dispatch) => {

    try {
      const response = await axios.post(SERVER_URL + `/admin/web_content/ads/edit/${id}`, values, config);
      authMiddleware(response, dispatch);
      return response;
    } catch (error) {
      throw error;
    }
  
};

export const adsList = async (page_num, page_size, config, dispatch) => {
    try {
      const response = await axios.get(SERVER_URL + `/admin/web_content/ads/list?page_num=${page_num}&page_size=${page_size}`, config);
      authMiddleware(response, dispatch);
      return response;
    } catch (error) {
      throw error;
    }
  
};

export const adDetails = async (id, config, dispatch) => {
    try {
      const response = await axios.get(SERVER_URL + `/admin/web_content/ads/details/${id}`, config);
      authMiddleware(response, dispatch);
      return response;
    } catch (error) {
      throw error;
    }
};

export const deleteAdRequest = async (id, config, dispatch) => {
  try {
    const response = await axios.post(SERVER_URL + `/admin/web_content/ads/delete/${id}`, {}, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }
};

export const collectionList = async (page_num, page_size, config, dispatch) => {
  try {
    const response = await axios.get(SERVER_URL + `/admin/web_content/collection/list?page_num=${page_num}&page_size=${page_size}`, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }
};

export const collectionOtionsList = async (config, dispatch) => {
  try {
    const response = await axios.get(SERVER_URL + `/admin/web_content/collection/option-list`, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }
};

export const collectionProductList = async (collectionId, page_num, page_size, config, dispatch) => {
  try {
    const response = await axios.get(SERVER_URL + `/admin/web_content/collection/products/${collectionId}?page_num=${page_num}&page_size=${page_size}`, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }
};



export const deleteProductFromCollectionRequest = async (collectionId, values, config, dispatch) => {
  try {
    const response = await axios.post(SERVER_URL + `/admin/web_content/collection/products/delete/${collectionId}`, values, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }
};


export const addNewCollection = async (values, config, dispatch) => {

  try {
    const response = await axios.post(SERVER_URL + `/admin/web_content/collection/new`, values, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }
};

export const editCollection = async (id, values, config, dispatch) => {

  try {
    const response = await axios.post(SERVER_URL + `/admin/web_content/collection/edit/${id}`, values, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }

};

export const addProductToCollection = async (collectionId, values, config, dispatch) => {

  try {
    const response = await axios.post(SERVER_URL + `/admin/web_content/collection/add-product/${collectionId}`, values, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }

};

export const collectionDetails = async (collectionId, config, dispatch) => {
  try {
    const response = await axios.get(SERVER_URL + `/admin/web_content/collection/details/${collectionId}`, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteCollectionById = async (id, config, dispatch) => {
  try {
    const response = await axios.post(SERVER_URL + `/admin/web_content/collection/delete/${id}`, {}, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }
};

export const userTrackingLogsRequest = async (keyword, pageNum, pageSize, startTime, endTime, actionType, config, dispatch) => {
  try {
    const response = await axios.get(SERVER_URL + 
      `/admin/user-tracking/list?keyword=${keyword}&page_num=${pageNum}&page_size=${pageSize}&start_time=${startTime}&end_time=${endTime}&action_type=${actionType}`, config);
    authMiddleware(response, dispatch);
    return response;
  } catch (error) {
    throw error;
  }
};

