import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { convToLocalDate } from "../../api/functions";
import Pagination from "../../components/Pagination";
import ScreenTitle from "../../components/ScreenTitle";
import { TextLinkButton } from "../../components/buttons";
import { SearchField } from "../../components/inputs";
import {
  ErrorMessage,
  Loader,
  ProductName,
  RoundStatusTag,
} from "../../components/smallComponents";
import { messageListRequest } from "../../redux/actions/userActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import UnreadCount from "./UnreadCount";

export default function DialogList() {
  useDocumentTitle("Messages");
  const dispatch = useDispatch();

  const { loading, messages, success, error } = useSelector(
    (state) => state.messageList
  );

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  const [keyword, setKeyword] = useState("");

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  useEffect(() => {
    dispatch(messageListRequest(keyword, pageNum, pageSize));
  }, [dispatch, pageNum, keyword]);

  const handleSearchChange = (value) => {
    setKeyword(value);
    setPageNum(0);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/messages">
            Messages
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Messages" />
        <div className="w-1/2 mt-8">
          <SearchField
            placeholder="Type user name to search..."
            initialQuery={keyword}
            onChange={handleSearchChange}
          />
        </div>

        {loading ? (
          <Loader mess="Requesting dialogs..." />
        ) : success ? (
          <div className={commonStyle.longDataTable}>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Type</th>
                  <th>User Name</th>
                  <th>Create Time</th>
                  <th>Last Update Time</th>

                  <th></th>
                </tr>
              </thead>
              <tbody>
                {messages.messages.length > 0 ? (
                  messages.messages.map((item, index) => (
                    <tr key={index}>
                      <td className="relative">
                        <ProductName
                          productCategory={item.productCategory ? item.productCategory : "watch"}
                          title={item.title ? item.title : ""}
                          image={item.imageUris.length ? item.imageUris[0] : ""}
                          brandName={item.brandName}
                          modelName={item.modelName}
                          referenceNumber={item.referenceNumber}
                          price={item.price ? item.price : null}
                        />
                         <UnreadCount dialogId={item.id}/>
                      </td>
                      <td>
                        {" "}
                        <RoundStatusTag
                          name={
                            item.refType === "CONSIGNMENT"
                              ? "Consignment"
                              : item.refType === "SERVICE"
                              ? "Service"
                              : "Product"
                          }
                          color={
                            item.refType === "CONSIGNMENT"
                              ? "green"
                              : item.refType === "SERVICE"
                              ? "yellow"
                              : "blue"
                          }
                        />
                      </td>
                      <td>
                        {item.senderId === 0
                          ? item.recipientName
                          : item.senderName}
                      </td>
                      <td>{convToLocalDate(item.atime)}</td>
                      <td>{convToLocalDate(item.mtime)}</td>

                      <td>
                        <TextLinkButton
                          name="View Details"
                          path={`/messages/details/${item.id}`}
                          data={item}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>There are no dialog available.</td>
                  </tr>
                )}
              </tbody>
            </table>
            {messages.messages.length > 0 ? (
              <Pagination
                totalPageNum={messages.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
