import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, LoadingButton } from "../../components/buttons";
import {
  SelectField,
  TextAreaField,
  TextField,
} from "../../components/inputs";
import { ErrorMessage, H2 } from "../../components/smallComponents";
import ImagesUploader from "./ImagesUploader";
import { getAllBrands } from "../../api/watchRequests";

export default function AccessoryDetailsForm({
  type,
  onSave,
  submitLoading,
  submitError,
  initialDetails,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [formState, setFormState] = useState(initialDetails);

  const [formErrors, setFormErrors] = useState({});
  const [brandOptions, setBrandOptions] = useState([]);
  const [brandReqStatus, setBrandReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  useEffect(() => {
    const getBrandOptions = async () => {
      try {
        setBrandReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await getAllBrands(config);
        if (responseData.data.success) {
          setBrandOptions(responseData.data.data);
          setBrandReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setBrandReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    getBrandOptions();
  }, []);

  //////////////////////////////////////////////////////////////////

  const handleChange = (e) => {
    const { name, value } = e.target;
    const type = e.type;

    if (type === "FLOAT") {
      const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
      if (!validated) {
        return;
      }
    }
    if (type === "INT") {
      const validated = value.match(/^(?:\d+)?$/g);
      if (!validated) {
        return;
      }
    }
    setFormState({ ...formState, [name]: value });
  };

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  const getWatchImages = (files) => {
    setFormState({
      ...formState,
      ["imageUris"]: files,
    });
  };

  ///////////////////////////////////////////////////////////////

  const handleSaveListing = () => {
    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      onSave(formState);
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.price) {
      errors.price = "Product price is required.";
    }
    if (!values.title) {
      errors.title = "Product title is required.";
    }
    if (!values.imageUris.length) {
      errors.imageUris = "Please add at least one photo.";
    }
    return errors;
  };

  //////////////////////////////////////////////////////////////

  return (
    <div className="w-full pb-20 pt-4 mt-4">
      <div className="w-full flex">
        <div className="w-full lg:w-3/5">
          <div className="w-full">
            <H2 title="Product Images" />
            <ImagesUploader
              initialImages={formState.imageUris}
              numLimit={20}
              onWatchUpload={getWatchImages}
              tipsText={"Upload at least 3 photos of the product."}
            />

            {formErrors.imageUris ? (
              <p className="mt-4 text-red-500">{formErrors.imageUris}</p>
            ) : (
              <></>
            )}
          </div>

          <div className="w-full mt-6">
            <H2 title="Product Details" subText="" />
            <TextField
              type="text"
              label="Title*"
              name="title"
              autofocus={true}
              readOnly={false}
              value={formState.title}
              onChange={(title) =>
                handleChange({
                  type: "TEXT",
                  target: { value: title, name: "title" },
                })
              }
              error={formErrors.title}
            />
            <TextField
              type="text"
              label="Price*"
              name="price"
              autofocus={false}
              readOnly={false}
              value={formState.price}
              onChange={(price) =>
                handleChange({
                  type: "FLOAT",
                  target: { value: price, name: "price" },
                })
              }
              error={formErrors.price}
            />
            <SelectField
              label="Brand"
              placeHolder="Select brand"
              selectOptions={brandReqStatus.success ? brandOptions : []}
              value={formState.brandId}
              clearable={true}
              extractKey="id"
              onChange={(brandId) =>
                handleChange({
                  type: "ID",
                  target: { value: brandId, name: "brandId" },
                })
              }
              error={""}
            />

            <TextAreaField
              type="text"
              label="Description"
              name="description"
              value={formState.description}
              rows={6}
              placeholder="For example, where did you buy the watch? Does it still have a warranty ? Has it been repaired? Is the watch damaged?"
              onChange={(description) =>
                handleChange({
                  type: "TEXT",
                  target: { value: description, name: "description" },
                })
              }
            />
          </div>
        </div>
      </div>

      <div className="w-full flex items-start">
        <div className="w-full lg:w-3/5">
          {submitError && (
            <div className="mt-8 w-full">
              <ErrorMessage mess={submitError} />
            </div>
          )}

          <div className="mt-8 w-full flex justify-between">
            {submitLoading ? (
              <LoadingButton name="Submitting..." />
            ) : (
              <Button name={"Submit Listing"} action={handleSaveListing} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
