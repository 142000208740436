import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import menuStyle from "../../style/menuStyle.module.css";

function CollapsedMenu({ item }) {
  const location = useLocation();

  return item.children && item.children.length > 0 ? (
    // sidemen expanded, and item has childrens
    <div className={menuStyle.sideMenuItemColla}>
      <div className={menuStyle.collaIcon}>{item.iconName}</div>

      <div className={menuStyle.subMenuContainer}>
        {item.children.map((item, index) => (
          <div className={menuStyle.collaSubitemContainer} key={index}>
            <NavLink
              to={item.path}
              exact="true"
              
              className={
                item.path === location.pathname
                  ? `${menuStyle.collaSubitem} ${menuStyle.pathSelected}`
                  : menuStyle.collaSubitem
              }
            >
              {item.sidemenuName}
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  ) : (
    // sidemen expanded, and item no children
    <div>
      <NavLink
        key={item.id}
        to={item.path}
        exact="true"
        className={menuStyle.collaIconPlain}
      >
        {item.iconName}
        <div className={menuStyle.menuNameTag}>{item.sidemenuName}</div>
      </NavLink>
    </div>
  );
}

export default CollapsedMenu;
