import Breadcrumbs from "@mui/material/Breadcrumbs";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NavLink,
  useLocation,
  useNavigate
} from "react-router-dom";
import ScreenTitle from "../../components/ScreenTitle";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";

export default function NewProduct() {
  useDocumentTitle("Add New Product");
  const navigate = useNavigate();

  const dispatch = useDispatch();



  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/products">
            Products
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/products/new/`}
            aria-current="page"
          >
            New Product
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Add New Product " />

        <div className="flex flex-row mt-6">
          <NavLink
            to={"/products/new/platform"}
            className="px-4 py-8 bg-lightGray text-center rounded hover:bg-brandYellow focus:outline-none mr-8 hover:text-white"
          >
            <p>Add New Product</p>
            <p className="text-sm mt-2">WatchSpace Self-operated</p>

          </NavLink>
          <NavLink
            to={"/products/accessory/new"}
            className="px-4 py-8 bg-lightGray text-center rounded hover:bg-brandYellow focus:outline-none mr-8 hover:text-white"
          >
            <p>Add Accessory</p>
            <p className="text-sm mt-2">WatchSpace Self-operated</p>

          </NavLink>

          <NavLink
            to={"/products/new/on-behalf-of-dealer"}
            className="px-4 py-8 bg-lightGray rounded hover:bg-brandYellow focus:outline-none hover:text-white"
          >
            <p>Add New Product</p>
            <p className="text-sm mt-2">on Behalf of Dealer</p>
          </NavLink>
          
        </div>
      </div>
    </div>
  );
}
