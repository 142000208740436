import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { convToLocalDate, convtPrice } from "../../api/functions";
import Pagination from "../../components/Pagination";
import ScreenTitle from "../../components/ScreenTitle";
import { LinkButton, TextLinkButton } from "../../components/buttons";
import { SearchField } from "../../components/inputs";
import {
  ErrorMessage,
  Loader,
  OrderStatusTag,
  OrderTypeTag,
  ProductName,
  ProductNameLink,
} from "../../components/smallComponents";
import { orderListRequest } from "../../redux/actions/orderActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";

export default function OrderList() {
  useDocumentTitle("Order List");
  const dispatch = useDispatch();

  const { userDetails, success:detailsSuccess } = useSelector(
    (state) => state.accountUserDetails
  );

  const { loading, orders, success, error } = useSelector(
    (state) => state.orderList
  );

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  const [keyword, setKeyword] = useState("");

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  useEffect(() => {
    dispatch(orderListRequest(keyword, pageNum, pageSize, "1.0.2"));
  }, [dispatch, pageNum, keyword]);

  const handleSearchChange = (value) => {
    setKeyword(value);
    setPageNum(0);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/orders">
            Orders
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Order List" />

        <div className="flex flex-row justify-between mt-8">
          <div className="w-1/2">
            <SearchField
              placeholder="Type order number / user email / user name to search..."
              initialQuery={keyword}
              onChange={handleSearchChange}
            />
          </div>
          {
            detailsSuccess && userDetails.data.permissions.includes("add_new_order") && 
          <div>
            <LinkButton
              name={"Add New Order"}
              path={`/orders/new`}
              width="fit"
            />{" "}
          </div>
          }
          
        </div>

        {loading ? (
          <Loader mess="Requesting orders..." />
        ) : success ? (
          <div className={commonStyle.longDataTable}>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>OrderNumber</th>
                  <th>Products</th>
                  <th>User</th>
                  <th>Order Total</th>
                  <th>Order Date</th>

                  <th>Type</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {orders.orders.length > 0 ? (
                  orders.orders.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className="w-fit flex flex-col">
                        {item.type === "PREORDER" && (
                          <div
                            className={`text-[11px] px-3 py-1 text-white bg-green-500 rounded-full mb-2`}
                          >
                            Pre-order
                          </div>
                        )}
                        <OrderTypeTag
                          isOnline={item.adminId > 0 ? false : true}
                        />

                        </div>
                        
                      </td>
                      <td>{item.orderNumber}</td>
                      <td>
                        {item.products && item.products.length ? (
                          <div className="px-4 pb-6">
                            {item.products.map((pro, index) => (
                              <ProductNameLink
                                key={index}
                                productId={pro.productId}
                                image={pro.imageUris.length ? pro.imageUris[0] : ""}
                                brandName={pro.brandName}
                                modelName={pro.modelName}
                                referenceNumber={pro.referenceNumber}
                                price={pro.price}
                                quantity={pro.quantity}
                                productCategory={pro.productCategory}
                                title={pro.title}
                              />
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                      <NavLink to={`/users/details/${item.buyerId}`} className={"hover:underline"}>
                        <div className="w-flex flex-col ">
                        <p>{item.buyerName}</p>
                        <p>{item.buyerEmail}</p>
                        </div>
                      </NavLink>
                      </td>
                      <td>${convtPrice(item.orderTotal)}</td>
                      <td>{convToLocalDate(item.atime)}</td>

                      <td>
                        {item.type === "DEFAULT"
                          ? "MiddleMan(Direct)"
                          : item.type === "PLATFORMDIRECT"
                          ? "PlatformDirect"
                          : item.type === "PREORDER"
                          ? "Pre-order"
                          : "MiddleMan((Authentification))"}
                      </td>

                      <td>
                        <OrderStatusTag currState={item.currStateDesc} />
                      </td>
                      <td>
                        <TextLinkButton
                          name="View Details"
                          path={`/orders/details/${item.id}`}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>There are no order available.</td>
                  </tr>
                )}
              </tbody>
            </table>
            {orders.orders.length > 0 ? (
              <Pagination
                totalPageNum={orders.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
