import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addNewProductByPlatformRequest,
  getProductEditDetails,
  getWatchDetailsRequest,
} from "../../api/productsRequests";
import ScreenTitle from "../../components/ScreenTitle";
import { SimpleDialog } from "../../components/dialogs";
import { ErrorMessage, Loader } from "../../components/smallComponents";
import { watchConstantOptionsRequest } from "../../redux/actions/watchActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import { K_COUNTRIES } from "../../constantData/K_COUNTRIES";
import SearchWatch from "./SearchWatch";
import PlatformProductDetailsForm from "./PlatformProductDetailsForm";

export default function NewProductByPlatform() {
  useDocumentTitle("Add New Product");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  let cloneProductId = location.state;
  cloneProductId = Number(cloneProductId);

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const { loading, watchOptions, success, error } = useSelector(
    (state) => state.watchConstantOptions
  );

  const [formState, setFormState] = useState({});

  const [dialog, setDialog] = useState(false);
  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [detailStatus, setDetailStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [watchId, setWatchId] = useState("");
  const [showSearchField, setShowSearchField] = useState(
    cloneProductId > 0 ? false : true
  );
  const [showForm, setShowForm] = useState(cloneProductId > 0 ? true : false);

  const [productId, setProductId] = useState("");

  useEffect(() => {
    dispatch(watchConstantOptionsRequest());
  }, []);

  useEffect(() => {
    const getWatchInfo = async () => {
      try {
        setReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await getWatchDetailsRequest(
          watchId,
          config,
          dispatch
        );
        if (responseData.data.success) {
          let watch = responseData.data.data;
          watch.year = "";
          watch.conditionId = "";
          watch.scopeId = "";
          watch.serialNumber = "";
          watch.price = "";
          watch.description = "";
          watch.imageUris = [];
          watch.ownerProofOne = "";
          watch.ownerProofTwo = "";

          setFormState(watch);
          setReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
          setShowForm(true);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    if (watchId) {
      getWatchInfo();
    }
  }, [watchId]);

  useEffect(() => {
    const productDetailsRequest = async () => {
      try {
        setDetailStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await getProductEditDetails(
          cloneProductId,
          config
        );
        if (responseData.data.success) {
          let productDetails = responseData.data.data;
          let images = [];

          if (productDetails.imageUris.length) {
            productDetails.imageUris.forEach((x) => {
              const uri = x;
              const fileNameParts = x.split("/");
              const fileName = fileNameParts[fileNameParts.length - 1];

              images.push({
                fileName: fileName,
                isLoading: false,
                error: "",
                uri: uri,
              });
            });
          }
          productDetails.imageUris = images;

          if (productDetails.proofOneImage) {
            const proofOneImageUri = productDetails.proofOneImage;
            const oneNameParts = proofOneImageUri.split("/");
            const oneFileName = oneNameParts[oneNameParts.length - 1];

            productDetails.ownerProofOne = {
              fileName: oneFileName,
              isLoading: false,
              error: "",
              uri: proofOneImageUri,
            };
          } else {
            productDetails.ownerProofOne = {
              fileName: "",
              isLoading: false,
              error: "",
              uri: "",
            };
          }

          if (productDetails.proofTwoImage) {
            const proofTwoImageUri = productDetails.proofTwoImage;
            const twoNameParts = proofTwoImageUri.split("/");
            const twoFileName = twoNameParts[twoNameParts.length - 1];

            productDetails.ownerProofTwo = {
              fileName: twoFileName,
              isLoading: false,
              error: "",
              uri: proofTwoImageUri,
            };
          } else {
            productDetails.ownerProofTwo = {
              fileName: "",
              isLoading: false,
              error: "",
              uri: "",
            };
          }

          if (productDetails.contact.countryName) {
            productDetails.contact.country = K_COUNTRIES.filter(
              (x) => x.value === productDetails.contact.countryName
            )[0];
          }
          setFormState(productDetails);
          setDetailStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setDetailStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };

    if (cloneProductId) {
      productDetailsRequest();
    }
  }, [cloneProductId]);

  const submitListing = async (values) => {
    let submittedValues = values;
    let contact_info = {
      firstName: "",
      lastName: "",
      phone: "",
      streetLine1: "",
      region: "",
      country: "",
      city: "",
      postalCode: "",
    };

    let uris = submittedValues.imageUris;
    let pureUris = [];
    uris.forEach((x) => {
      if (x.uri) {
        pureUris.push(x.uri);
      }
    });

    submittedValues.productType = "PLATFORMPRODUCT";
    submittedValues.watchImages = pureUris;
    submittedValues.ownerProofImage1 = "";
    submittedValues.ownerProofImage2 = "";
    submittedValues.contact = contact_info;

    try {
      setSaveStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await addNewProductByPlatformRequest(
        "",
        values,
        config
      );
      if (responseData.data.success) {
        setProductId(responseData.data.data);
        setDialog(true);
        setSaveStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const handleWatchSelect = (id) => {
    setWatchId(id);
    setShowSearchField(false);
    setShowForm(true);
  };

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate(`/products/details/${productId}`);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Add New Product"
        successMessage="New product added successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeDialogWithSuccess}
      />
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/products">
            Products
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/products/new/`}
            aria-current="page"
          >
            New Product
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Add New Product (Platform)" />

        {showSearchField && (
          <div className="mt-8 w-1/2">
            <p className="mb-2 text-base ">Select a watch model to start..</p>
            <SearchWatch onSelect={handleWatchSelect} />
          </div>
        )}

        {success && showForm ? (
          <>
            {cloneProductId > 0 ? (
              <div>
                {detailStatus.loading ? (
                  <Loader mess="Requeting watch options..." />
                ) : detailStatus.success ? (
                  <PlatformProductDetailsForm
                    type={"new"}
                    onSave={submitListing}
                    submitLoading={saveStatus.loading}
                    submitError={saveStatus.error}
                    initialDetails={formState}
                    pricEditable={true}
                  />
                ) : detailStatus.error ? (
                  <ErrorMessage mess={detailStatus.error} />
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <div>
                {reqStatus.loading ? (
                  <Loader mess="Requeting watch options..." />
                ) : reqStatus.success ? (
                  <PlatformProductDetailsForm
                    type={"new"}
                    onSave={submitListing}
                    submitLoading={saveStatus.loading}
                    submitError={saveStatus.error}
                    initialDetails={formState}
                    pricEditable={true}
                  />
                ) : reqStatus.error ? (
                  <ErrorMessage mess={reqStatus.error} />
                ) : (
                  <></>
                )}
              </div>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
