import React, { useEffect, useState } from "react";
import { ErrorMessage, Loader } from "../../components/smallComponents";
import {
  SelectField,
  TextField,
  FieldLabel,
  RatioField,
  ReadOnlyField,
} from "../../components/inputs";
import { useDispatch, useSelector } from "react-redux";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Button } from "../../components/buttons";
import {
  modelListByBrandRequest,
  watchConstantOptionsRequest,
} from "../../redux/actions/watchActions";
import SingleImageUploader from "../../components/SingleImageUploader";


export default function WatchDetailsForm({ type, onSave, watchDetailsInfo }) {
  const dispatch = useDispatch();

  const { brandOptions } = useSelector((state) => state.brandOptionList);

  const { loading, watchOptions, success, error } = useSelector(
    (state) => state.watchConstantOptions
  );

  const {
    loading: modelOptionsLoading,
    modelOptions,
    success: modelOptionsSuccess,
    error: modelOptionsError,
  } = useSelector((state) => state.modelListByBrand);

  const [formState, setFormState] = useState(watchDetailsInfo);

  const [formErrors, setFormErrors] = useState({});
  const [showAdditionalInfo, setShowAdditionalInfo] = useState("");
  const [brandStep, setBrandStep] = useState(type === "new" ? true : false);
  const [modelStep, setModelStep] = useState(type === "new" ? true : false);

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(watchConstantOptionsRequest());
  }, [dispatch]);

  useEffect(() => {
    if(success){
      dispatch(modelListByBrandRequest(watchDetailsInfo.brandId));
    }
  }, [success]);


  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  const handleShowButton = (value) => {
    if (value === showAdditionalInfo) {
      setShowAdditionalInfo("");
    } else {
      setShowAdditionalInfo(value);
    }
  };
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  const handleBrandChange = (e) => {
    const { name, value } = e.target;
  
    if (value) {
      setFormState({
        ...formState,
        [name]: value,
        ["brandName"]: brandOptions.filter((x) => x.id === value)[0].label,
      });
      dispatch(modelListByBrandRequest(value));
      setBrandStep(false);
    } else {
      setBrandStep(true);
      setModelStep(true);
    }
  };

  const handleModelChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
      ["modelName"]: modelOptions.filter((x) => x.id === value)[0].label,
    });
    if (value) {
      setModelStep(false);
    } else {
      setModelStep(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleRatioChange = (e) => {

    const { id, value } = e.target;
    const type = e.type;

    let copy = { ...formState };
    let typeArray = copy[type]; // an array
     // check if id exist

     if (typeArray.includes(id)) {
      let existIndex = typeArray.indexOf(id);
      if (!value) {
        typeArray.splice(existIndex, 1);
      }
    } else {
      if (value) {
        typeArray.push(id);
      }
    }
    setFormState(copy);
  };
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  const getUploadedImages = (file) => {
    if (file.uri) {
      setFormState({ ...formState, ["imageUri"]: file.uri });
    }
  };

  const handleSubmit = () => {
    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      onSave(formState);
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.brandId) {
      errors.brand = "Brand is required.";
    }

    // if (!values.modelId) {
    //   errors.model = "Model is required.";
    // }

    if (!values.watchTypeId) {
      errors.watchType = "Watch type is required.";
    }

    if (!values.genderId) {
      errors.gender = "Watch gender is required.";
    }

    if (!values.movementId) {
      errors.movement = "Watch movement is required.";
    }

    // if (!values.imageUri) {
    //   errors.image = "Watch image is required.";
    // }

    return errors;
  };

  return (
    <div className="w-full">
      {loading ? (
        <Loader mess="Requesting" />
      ) : success ? (
        <div className="w-full flex">
          <div className="w-3/5">
            <div>
              <h2 className="mt-8">Basic Info* (Required)</h2>
              <div className="w-full flex">
                <div className="w-1/2 mr-8">
                  {type === "new" ? (
                    <SelectField
                      label="Brand*"
                      placeHolder="Select brand"
                      selectOptions={brandOptions}
                      value={formState.brandId}
                      extractKey="id"
                      onChange={(brandId) =>
                        handleBrandChange({
                          target: { value: brandId, name: "brandId" },
                        })
                      }
                      error={formErrors.brand}
                      readOnly={!brandStep && !modelStep ? true : false}
                      readOnlyName={formState.brandName}
                    />
                  ) : (
                    <ReadOnlyField
                      label="Brand*"
                      readOnlyName={formState.brandName}
                    />
                  )}
                </div>
                {!brandStep ? (
                  <div className="w-1/2">
                  
                      <>
                        {modelOptionsLoading ? (
                          <Loader mess="Requesting models..." />
                        ) : modelOptionsSuccess ? (
                          <SelectField
                            label="Model*"
                            placeHolder="Select model"
                            selectOptions={modelOptions}
                            value={formState.modelId}
                            extractKey="id"
                            onChange={(modelId) =>
                              handleModelChange({
                                target: { value: modelId, name: "modelId" },
                              })
                            }
                            error={formErrors.model}
                            readOnly={false}
                            readOnlyName={formState.modelName}
                          />
                        ) : modelOptionsError ? (
                          <ErrorMessage mess={modelOptionsError} />
                        ) : (
                          <></>
                        )}
                      </>
                   
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            {!brandStep && !modelStep && success ? (
              <div>
                <div className="w-full flex">
                  <div className="w-1/2 mr-8">
                    <SelectField
                      label="Type of Watch*"
                      placeHolder="Select type"
                      selectOptions={watchOptions.watchTypes}
                      value={formState.watchTypeId}
                      extractKey="id"
                      onChange={(watchTypeId) =>
                        handleChange({
                          target: { value: watchTypeId, name: "watchTypeId" },
                        })
                      }
                      error={formErrors.watchType}
                    />
                    <SelectField
                      label="Gender*"
                      placeHolder="Select gender"
                      selectOptions={watchOptions.genders}
                      value={formState.genderId}
                      extractKey="id"
                      onChange={(genderId) =>
                        handleChange({
                          target: { value: genderId, name: "genderId" },
                        })
                      }
                      error={formErrors.gender}
                    />
                    <TextField
                      type="text"
                      label="Case Diameter Width"
                      name="caseDiameterWidth"
                      autofocus={true}
                      value={formState.caseDiameterWidth}
                      onChange={(caseDiameterWidth) =>
                        handleChange({
                          target: { value: caseDiameterWidth, name: "caseDiameterWidth" },
                        })
                      }
                      unit="mm"
                      readOnly={loading ? true : false}
                    />
                     <TextField
                      type="text"
                      label="Case Diameter Height"
                      name="caseDiameterWidth"
                      autofocus={true}
                      value={formState.caseDiameterHeight}
                      onChange={(caseDiameterHeight) =>
                        handleChange({
                          target: { value: caseDiameterHeight, name: "caseDiameterHeight" },
                        })
                      }
                      unit="mm"
                      readOnly={loading ? true : false}
                    />
                  </div>
                  <div className="w-1/2">
                    <TextField
                      type="text"
                      label="Reference Number*"
                      name="referenceNumber"
                      autofocus={true}
                      value={formState.referenceNumber}
                      onChange={(referenceNumber) =>
                        handleChange({
                          target: {
                            value: referenceNumber,
                            name: "referenceNumber",
                          },
                        })
                      }
                      error={formErrors.referenceNumber}
                      readOnly={loading ? true : false}
                    />

                    <SelectField
                      label="Movement*"
                      placeHolder=""
                      selectOptions={watchOptions.movemnets}
                      value={formState.movementId}
                      extractKey={"id"}
                      onChange={(movementId) =>
                        handleChange({
                          target: { value: movementId, name: "movementId" },
                        })
                      }
                      error={formErrors.movement}
                    />

                 
                  </div>
                </div>

                <div>
                  <h2 className="mt-8">Additional Info* (Optional)</h2>
                  <button
                    className={`w-full h-12 bg-lightGray flex items-center px-2 justify-between mt-6`}
                    onClick={() => handleShowButton("caliber")}
                  >
                    <h3>Caliber</h3>
                    <MdKeyboardArrowDown
                      className={`transform transition-transform duration-300 ease-in-out origin-center ${
                        showAdditionalInfo === "caliber"
                          ? "rotate-180"
                          : "rotate-0"
                      }`}
                    />
                  </button>
                  {showAdditionalInfo === "caliber" ? (
                    <div>
                      <div className="flex w-full">
                        <div className="w-1/2 mr-8">
                          <TextField
                            type="text"
                            label="Caliber/movement"
                            name="caliMovement"
                            autofocus={true}
                            value={formState.caliMovement}
                            onChange={(caliMovement) =>
                              handleChange({
                                target: {
                                  value: caliMovement,
                                  name: "caliMovement",
                                },
                              })
                            }
                            readOnly={loading ? true : false}
                          />
                          <TextField
                            type="text"
                            label="Base caliber"
                            name="baseCaliber"
                            autofocus={true}
                            value={formState.baseCaliber}
                            onChange={(baseCaliber) =>
                              handleChange({
                                target: {
                                  value: baseCaliber,
                                  name: "baseCaliber",
                                },
                              })
                            }
                            readOnly={loading ? true : false}
                          />
                          <TextField
                            type="text"
                            label="Frequency"
                            name="frequency"
                            autofocus={true}
                            value={formState.frequency}
                            onChange={(frequency) =>
                              handleChange({
                                target: { value: frequency, name: "frequency" },
                              })
                            }
                            unit="A/h"
                            readOnly={loading ? true : false}
                          />
                        </div>
                        <div className="w-1/2">
                          <TextField
                            type="text"
                            label="Power Reserve"
                            name="powerReserve"
                            autofocus={true}
                            value={formState.powerReserve}
                            onChange={(powerReserve) =>
                              handleChange({
                                target: {
                                  value: powerReserve,
                                  name: "powerReserve",
                                },
                              })
                            }
                            unit="h"
                            readOnly={loading ? true : false}
                          />
                          <TextField
                            type="text"
                            label="Number of Jewels"
                            name="jewelNumbers"
                            autofocus={true}
                            value={formState.jewelNumbers}
                            onChange={(jewelNumbers) =>
                              handleChange({
                                target: {
                                  value: jewelNumbers,
                                  name: "jewelNumbers",
                                },
                              })
                            }
                            readOnly={loading ? true : false}
                          />
                        </div>
                      </div>
                      <FieldLabel label="Other Details" />
                      <ul className="w-full flex flex-wrap">
                        {watchOptions.caliberOtherDetails.map((item, index) => (
                          <li key={index} className="w-1/2">
                            <RatioField
                              name="otherAttributeIds"
                              label={item.name}
                              isOn={
                                formState["otherAttributeIds"].length
                                  ? formState[
                                      "otherAttributeIds"
                                    ].includes(item.id)
                                  : false
                              }
                              onChange={(checked) =>
                                handleRatioChange({
                                  type: "otherAttributeIds",
                                  target: { id: item.id, value: checked },
                                })
                              }
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}

                  <button
                    className={`w-full h-12 bg-lightGray flex items-center px-2 justify-between mt-6`}
                    onClick={() => handleShowButton("case")}
                  >
                    <h3>Case</h3>
                    <MdKeyboardArrowDown
                      className={`transform transition-transform duration-300 ease-in-out origin-center ${
                        showAdditionalInfo === "case"
                          ? "rotate-180"
                          : "rotate-0"
                      }`}
                    />
                  </button>

                  {showAdditionalInfo === "case" ? (
                    <div className="w-full">
                      <div className="flex w-full">
                        <div className="w-1/2 mr-8">
                          <SelectField
                            label="Case Material"
                            placeHolder=""
                            selectOptions={watchOptions.caseMaterials}
                            value={formState.caseMaterialId}
                            extractKey={"id"}
                            onChange={(caseMaterialId) =>
                              handleChange({
                                target: {
                                  value: caseMaterialId,
                                  name: "caseMaterialId",
                                },
                              })
                            }
                          />

                          <SelectField
                            label="Bezel Material"
                            placeHolder=""
                            selectOptions={watchOptions.caseMaterials}
                            value={formState.bezelMaterialId}
                            extractKey={"id"}
                            onChange={(bezelMaterialId) =>
                              handleChange({
                                target: {
                                  value: bezelMaterialId,
                                  name: "bezelMaterialId",
                                },
                              })
                            }
                          />
                          <TextField
                            type="text"
                            label="Thickness"
                            name="thickness"
                            autofocus={true}
                            value={formState.thickness}
                            onChange={(thickness) =>
                              handleChange({
                                target: { value: thickness, name: "thickness" },
                              })
                            }
                            unit="mm"
                            readOnly={loading ? true : false}
                          />
                        </div>
                        <div className="w-1/2">
                          <SelectField
                            label="Crystal"
                            placeHolder=""
                            selectOptions={watchOptions.caseCrystals}
                            value={formState.glassId}
                            extractKey={"id"}
                            onChange={(glassId) =>
                              handleChange({
                                target: {
                                  value: glassId,
                                  name: "glassId",
                                },
                              })
                            }
                          />
                          <SelectField
                            label="Watch Resistance"
                            placeHolder=""
                            selectOptions={watchOptions.watchResistances}
                            value={formState.waterResistanceId}
                            extractKey={"id"}
                            onChange={(waterResistanceId) =>
                              handleChange({
                                target: {
                                  value: waterResistanceId,
                                  name: "waterResistanceId",
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                      <FieldLabel label="Other Details" />
                      <ul className="w-full flex flex-wrap">
                        {watchOptions.caseOtherDetails.map((item, index) => (
                          <li key={index} className="w-1/2">
                            <RatioField
                              name="otherAttributeIds"
                              label={item.name}
                              isOn={
                                formState["otherAttributeIds"]
                                  ? formState["otherAttributeIds"].includes(
                                      item.id
                                    )
                                  : false
                              }
                              onChange={(checked) =>
                                handleRatioChange({
                                  type: "otherAttributeIds",
                                  target: { id: item.id, value: checked },
                                })
                              }
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}

                  <button
                    className={`w-full h-12 bg-lightGray flex items-center px-2 justify-between mt-6`}
                    onClick={() => handleShowButton("dial")}
                  >
                    <h3>Dial and hands</h3>
                    <MdKeyboardArrowDown
                      className={`transform transition-transform duration-300 ease-in-out origin-center ${
                        showAdditionalInfo === "dial"
                          ? "rotate-180"
                          : "rotate-0"
                      }`}
                    />
                  </button>

                  {showAdditionalInfo === "dial" ? (
                    <div className="w-full">
                      <div className="flex w-full">
                        <div className="w-1/2 mr-8">
                          <SelectField
                            label="Dial Color"
                            placeHolder=""
                            selectOptions={watchOptions.dialColors}
                            value={formState.dialColorId}
                            extractKey={"id"}
                            onChange={(dialColorId) =>
                              handleChange({
                                target: {
                                  value: dialColorId,
                                  name: "dialColorId",
                                },
                              })
                            }
                          />
                        </div>
                        <div className="w-1/2">
                          <SelectField
                            label="Dial Numerals"
                            placeHolder=""
                            selectOptions={watchOptions.dialNumerals}
                            value={formState.dialNumeralsId}
                            extractKey={"id"}
                            onChange={(dialNumeralsId) =>
                              handleChange({
                                target: {
                                  value: dialNumeralsId,
                                  name: "dialNumeralsId",
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                      <FieldLabel label="Dial Features" />
                      <ul className="w-full flex flex-wrap">
                        {watchOptions.dialFeatures.map((item, index) => (
                          <li key={index} className="w-1/2">
                            <RatioField
                              name="otherAttributeIds"
                              label={item.name}
                              isOn={
                                formState["otherAttributeIds"]
                                  ? formState["otherAttributeIds"].includes(
                                      item.id
                                    )
                                  : false
                              }
                              onChange={(checked) =>
                                handleRatioChange({
                                  type: "otherAttributeIds",
                                  target: { id: item.id, value: checked },
                                })
                              }
                            />
                          </li>
                        ))}
                      </ul>
                      <FieldLabel label="Hand Details" />
                      <ul className="w-full flex flex-wrap">
                        {watchOptions.handDetails.map((item, index) => (
                          <li key={index} className="w-1/2">
                            <RatioField
                              name="otherAttributeIds"
                              label={item.name}
                              isOn={
                                formState["otherAttributeIds"]
                                  ? formState["otherAttributeIds"].includes(
                                      item.id
                                    )
                                  : false
                              }
                              onChange={(checked) =>
                                handleRatioChange({
                                  type: "otherAttributeIds",
                                  target: { id: item.id, value: checked },
                                })
                              }
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}

                  <button
                    className={`w-full h-12 bg-lightGray flex items-center px-2 justify-between mt-6`}
                    onClick={() => handleShowButton("bracelet")}
                  >
                    <h3>Bracelet/Strap</h3>
                    <MdKeyboardArrowDown
                      className={`transform transition-transform duration-300 ease-in-out origin-center ${
                        showAdditionalInfo === "bracelet"
                          ? "rotate-180"
                          : "rotate-0"
                      }`}
                    />
                  </button>

                  {showAdditionalInfo === "bracelet" ? (
                    <div className="flex">
                      <div className="w-1/2 mr-8">
                        <SelectField
                          label="Bracelet Material"
                          placeHolder=""
                          selectOptions={watchOptions.breceletMaterials}
                          value={formState.braceletMaterialId}
                          extractKey={"id"}
                          onChange={(braceletMaterialId) =>
                            handleChange({
                              target: {
                                value: braceletMaterialId,
                                name: "braceletMaterialId",
                              },
                            })
                          }
                        />

                        <SelectField
                          label="Bracelet Color"
                          placeHolder=""
                          selectOptions={watchOptions.breceletColors}
                          value={formState.braceletColorId}
                          extractKey={"id"}
                          onChange={(braceletColorId) =>
                            handleChange({
                              target: {
                                value: braceletColorId,
                                name: "braceletColorId",
                              },
                            })
                          }
                        />
                      </div>
                      <div className="w-1/2">
                        <SelectField
                          label="Type of clasp"
                          placeHolder=""
                          selectOptions={watchOptions.watchClasps}
                          value={formState.claspId}
                          extractKey={"id"}
                          onChange={(claspId) =>
                            handleChange({
                              target: { value: claspId, name: "claspId" },
                            })
                          }
                        />
                        <SelectField
                          label="Clasp Material"
                          placeHolder=""
                          selectOptions={watchOptions.breceletMaterials}
                          value={formState.claspMaterialId}
                          extractKey={"id"}
                          onChange={(claspMaterialId) =>
                            handleChange({
                              target: {
                                value: claspMaterialId,
                                name: "claspMaterialId",
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <button
                    className={`w-full h-12 bg-lightGray flex items-center px-2 justify-between mt-6`}
                    onClick={() => handleShowButton("function")}
                  >
                    <h3>Functions</h3>
                    <MdKeyboardArrowDown
                      className={`transform transition-transform duration-300 ease-in-out origin-center ${
                        showAdditionalInfo === "function"
                          ? "rotate-180"
                          : "rotate-0"
                      }`}
                    />
                  </button>

                  {showAdditionalInfo === "function" ? (
                    <ul className="w-full flex flex-wrap">
                      {watchOptions.watchFunctions.map((item, index) => (
                        <li key={index} className="w-1/2">
                          <RatioField
                            name="watchFunctionsIds"
                            label={item.name}
                            isOn={
                              formState["watchFunctionsIds"]
                                ? formState["watchFunctionsIds"].includes(
                                    item.id
                                  )
                                : false
                            }
                            onChange={(checked) =>
                              handleRatioChange({
                                type: "watchFunctionsIds",
                                target: { id: item.id, value: checked },
                              })
                            }
                          />
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="mt-8 w-full">
                  <Button name={type === "new" ? "Save" : "Update"} action={handleSubmit} />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          {!brandStep && !modelStep ? (
            <div className="w-2/5 px-8">
              <h2 className="mt-8">Watch Image (Required)</h2>
              <SingleImageUploader
                onUpload={getUploadedImages}
                imageError={formErrors.image}
                uri={formState.imageUri}
                folderName="watch/watchimages"
                compress={false}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : error ? (
        <ErrorMessage mess={error} />
      ) : (
        <></>
      )}
    </div>
  );
}
