import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import React, { useEffect } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "./components/Header/Header";
import SideMenu from "./components/SideMenu/SideMenu";
import { RequireAuth } from "./routesFunctions";
import Login from "./screens/auth/Login";
import ErrorBoundary from "./screens/errorPages/ErrorBoundary";
import NoAuthority from "./screens/errorPages/NoAuthority";
import NotFound from "./screens/errorPages/NotFound";

import { menuItemsData } from "./menuItemsData";
import { accountUserDetailsRequest } from "./redux/actions/userActions";

function App() {
  const dispatch = useDispatch();

  const SidebarLayout = () => (
    <div>
      <Header />
      <div
        style={{
          display: "flex",
          height: "calc(100vh - 50px)",
          color: "var(--font-color)",
          fontSize: "var(--main-size)",
          position: "relative",
        }}
      >
        <SideMenu />
        <Outlet />
      </div>
    </div>
  );
  const { userInfo } = useSelector((state) => state.userLogin);

  useEffect(() => {
    // if user is logged in , load to main page
    if (userInfo) {
      dispatch(accountUserDetailsRequest());
    }
  }, [dispatch, userInfo]);
  // flatten routes
  const flattedRoutes = [];
  const findAllRoutes = (data) => {
    for (let index in data) {
      if (data[index].path) {
        flattedRoutes.push(data[index]);
      }
      if (data[index].children) {
        findAllRoutes(data[index].children);
      }
    }
  };

  // findAllRoutes(menuData);
  findAllRoutes(menuItemsData());

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Routes>
          <Route element={<SidebarLayout />}>
            {flattedRoutes.map((route, index) => (
              <Route
                key={index}
                exact
                path={route.path}
                element={
                  <RequireAuth
                    pageName={route.name}
                    permisson={route.permissionCheck}
                  >
                    {route.component}
                  </RequireAuth>
                }
              />
            ))}
          </Route>
          {/* <Route exact path="/billing" element={<BillingScreen />} /> */}
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/no_permission" element={<NoAuthority />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
