import React, { useState, useEffect } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";
import menuStyle from "../../style/menuStyle.module.css";
import { useDispatch, useSelector } from "react-redux";
import { unreadMessagesRequest } from "../../redux/actions/userActions";

function SideMenuItem({ item, checkUnreadMessage }) {
  const location = useLocation();
  const dispatch = useDispatch();
  
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);

  const UnreadCount = () => {
    const { loading, unreads, succss, error } = useSelector(
      (state) => state.unreadMessages
    );

    useEffect(() => {
      let timeoutId;
      const checkStatus = () => {
        dispatch(unreadMessagesRequest());
        timeoutId = setTimeout(checkStatus, 60000); // check new message every 1 minute
      };
      checkStatus();
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId); // This clears the pending timeout on re-render or unmount
        }
      };
     
    }, [dispatch]);

    return unreads && unreads.length > 0 ?  <span className="ml-2 w-5 h-5 flex items-center justify-center bg-red-500 rounded-full text-white text-xs">
      {parseFloat(
            unreads.reduce((accumulator, currentObject) => {
              return accumulator + Number(currentObject.unreads);
            }, 0).toFixed(2)
          )}
  </span> : <></>
  }

  return item.children && item.children.length > 0 ? (
    // sidemen expanded, and item has childrens
    <div
      className={
        isSubMenuOpen
          ? `${menuStyle.sideMenuItemChildren} ${menuStyle.open}`
          : `${menuStyle.sideMenuItemChildren}`
      }
    >
      <div
        className={menuStyle.sideMenuItemTitle}
        onClick={() => setSubMenuOpen(!isSubMenuOpen)}
      >
        <div className={menuStyle.sideMenuItemName}>
        {item.iconName}
          <span className={menuStyle.sideMenuItemTitleName}>
            {item.sidemenuName}
          </span>
          {item.sidemenuName === "Message" && <UnreadCount />}
        </div>
        <MdKeyboardArrowDown className={menuStyle.toggleBtn} />
      </div>

      <div className={menuStyle.sideMenuContent}>
        {item.children.map((child, index) => (
          <NavLink
            to={child.path}
            exact="true"
            key={index}
            className={
              child.path === location.pathname
                ? `${menuStyle.subMenuItem} ${menuStyle.pathSelected}`
                : menuStyle.subMenuItem
            }
          >
            <span>{child.sidemenuName}</span>
            {item.sidemenuName === "Message" && <UnreadCount />}
          </NavLink>
        ))}
      </div>
    </div>
  ) : (
    <NavLink
      to={item.path}
      exact="true"
      className={
        item.path === location.pathname
          ? `${menuStyle.sideMenuItemPlain} ${menuStyle.pathSelected}`
          : menuStyle.sideMenuItemPlain
      }
    >
      {item.iconName}

      <div className={menuStyle.sideMenuItemNamePlain}>{item.sidemenuName}</div>
    </NavLink>
  );
}

export default SideMenuItem;
