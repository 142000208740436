import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { FaCircleCheck } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import {
  addProductToTaskRequest,
  deleteProductFromTaskRequest,
  emailTaskProductList,
} from "../../api/marketingEmailTaksRequets";
import ScreenTitle from "../../components/ScreenTitle";
import {
  ErrorMessage,
  Loader,
  ProductNameLink
} from "../../components/smallComponents";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import SearchProduct from "../order/SearchProduct";
import { DeleteDialog } from "../../components/dialogs";

export default function ManageTaskProducts() {
  useDocumentTitle("Manage Email Task Products");
  const dispatch = useDispatch();

  const { id: taskId } = useParams();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [deleteStatus, setDeleteStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [newStatus, setNewStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [updateCount, setUpdateCount] = useState(1);

  const [products, setProducts] = useState([]);
  // delete
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState();


  useEffect(() => {
    const getTaskProductList = async () => {
      try {
        setReqStatus((preState) => ({
          ...preState,
          loading: true,
          error: "",
        }));

        const responseData = await emailTaskProductList(
          taskId,
          config,
          dispatch
        );
        if (responseData.data.success) {
          setReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
          setProducts(responseData.data.data);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };

    getTaskProductList();
  }, [dispatch, updateCount]);



  const getSelectWatch = async (watch) => {
    try {
      setNewStatus((preState) => ({
        ...preState,
        loading: true,
        error: "",
      }));
      let new_product = {
        productId: watch.id,
      };
      const responseData = await addProductToTaskRequest(
        taskId,
        new_product,
        config,
        dispatch
      );
      if (responseData.data.success) {
        setUpdateCount(updateCount + 1);
        setNewStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setNewStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const removeProductAction = (productId) => {
    setDeleteId(productId);
    setDeleteDialog(true);
  };

  const deleteProduct = async () => {
    try {
      setDeleteStatus((preState) => ({
        ...preState,
        loading: true,
        error: "",
      }));
      let delete_values = {
        productId: deleteId,
      };

      const responseData = await deleteProductFromTaskRequest(
        taskId,
        delete_values,
        config,
        dispatch
      );
      if (responseData.data.success) {
        setUpdateCount(updateCount + 1);
        setDeleteStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setDeleteStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };


  return (
    <div className={commonStyle.pageContainer}>
      <DeleteDialog
        title="Remove Product"
        preDeleteMess="Are you sure you want to remove this product from the email task?"
        successMessage="Product removed successfully."
        isDialogOpen={deleteDialog}
        closeDialog={() => setDeleteDialog(false)}
        closeDialogWithSuccess={() => setDeleteDialog(false)}
        loading={deleteStatus.loading}
        success={deleteStatus.success}
        error={deleteStatus.error}
        loadingMessage="Processing request..."
        deleteAction={deleteProduct}
      />

      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink
            underline="hover"
            color="inherit"
            to="/marketing-email/tasks"
          >
            Email Tasks
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/marketing-email/tasks/manage-products/${taskId}`}
            aria-current="page"
          >
            Manage Task Products
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Manage Task Products" />

        {reqStatus.loading ? (
          <Loader mess="Requesting task products..." />
        ) : reqStatus.success ? (
          <div className="mt-6">
            <p>Please add around 10 products to the email.</p>
            <SearchProduct
              onSelect={getSelectWatch}
              readOnly={false}
              productType={"allProducts"}
            />
            {newStatus.error && (
              <div className="mt-4">
                <ErrorMessage mess={newStatus.error} />
              </div>
            )}
            {deleteStatus.error && (
              <div className="mt-4">
                <ErrorMessage mess={deleteStatus.error} />
              </div>
            )}
            {products.length ? (
              <div className="grid grid-cols-2 gap-6 mt-6">
                {products.map((item, index) => (
                  <div className="border border-solid border-borderGray rounded flex flex-row items-start justify-between">
                    <ProductNameLink
                      key={index}
                      productId={item.productId}
                      image={item.imageUris.length ? item.imageUris[0] : ""}
                      brandName={item.brandName}
                      modelName={item.modelName}
                      referenceNumber={item.referenceNumber}
                      price={item.price}
                      productCategory={item.productCategory}
                      title={item.title}
                    />
                    <div className="w-fit h-full p-4 flex items-end flex-col justify-between">
                      <FaCircleCheck className="text-green-500 w-5 h-5" />
                      <button
                        className="border border-solid border-red-500 text-red-500 rounded px-3 py-1 text-sm hover:text-white hover:bg-red-500"
                        onClick={() => removeProductAction(item.productId)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : reqStatus.error ? (
          <ErrorMessage mess={reqStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
