import React from "react";
import { IoDocumentTextOutline, IoImageOutline, IoAnalytics } from "react-icons/io5";
import { AiOutlineMessage, AiOutlineUser, AiOutlineInbox } from "react-icons/ai";
import { MdOutlineWatch, MdOutlineWatchLater, MdOutlineMailOutline, MdOutlineFormatListBulleted } from "react-icons/md";
import Dashboard from "./screens/dashboard/Dashboard";
import Login from "./screens/auth/Login";
import BlogEdit from "./screens/blog/BlogEdit";
import BlogList from "./screens/blog/BlogList";
import BlogPreview from "./screens/blog/BlogPreview";
import NewBlog from "./screens/blog/NewBlog";
import ErrorBoundary from "./screens/errorPages/ErrorBoundary";
import NoAuthority from "./screens/errorPages/NoAuthority";
import NotFound from "./screens/errorPages/NotFound";
import DialogDetails from "./screens/message/DialogDetails";
import DialogList from "./screens/message/DialogList";
import OrderDetails from "./screens/order/OrderDetails";
import OrderList from "./screens/order/OrderList";
import ProductDetails from "./screens/product/ProductDetails";
import ProductList from "./screens/product/ProductList";
import UserDetails from "./screens/user/UserDetails";
import UserList from "./screens/user/UserList";
import BrandList from "./screens/watch/BrandList";
import EditBrand from "./screens/watch/EditBrand";
import EditWatch from "./screens/watch/EditWatch";
import ModelList from "./screens/watch/ModelList";
import NewBrand from "./screens/watch/NewBrand";
import NewModel from "./screens/watch/NewModel";
import NewWatch from "./screens/watch/NewWatch";
import WatchList from "./screens/watch/WatchList";
import AgreementEdit from "./screens/webContent/AgreementEdit";
import AgreementList from "./screens/webContent/AgreementList";
import CareerEdit from "./screens/webContent/CareerEdit";
import CareerList from "./screens/webContent/CareerList";
import CompanyInfoEdit from "./screens/webContent/CompanyInfoEdit";
import CompanyInfoList from "./screens/webContent/CompanyInfoList";
import EditQuestion from "./screens/webContent/FAQ/EditQuestion";
import FaqList from "./screens/webContent/FAQ/FaqList";
import NewQuestion from "./screens/webContent/FAQ/NewQuestion";
import ImageEdit from "./screens/webContent/ImageEdit";
import ImageList from "./screens/webContent/ImageList";
import NewCareer from "./screens/webContent/NewCareer";
import NewProduct from "./screens/product/NewProduct";
import EditProduct from "./screens/product/EditProduct";
import NewUser from "./screens/user/NewUser";
import EditUser from "./screens/user/EditUser";
import AdsList from "./screens/webContent/ads/AdsList";
import NewAd from "./screens/webContent/ads/NewAd";
import EditAd from "./screens/webContent/ads/EditAd";
import ConsignmentOrderList from "./screens/order/consignment/ConsignmentOrderList";
import ConsignmentOrderDetails from "./screens/order/consignment/ConsignmentOrderDetails";
import NewProductByDealer from "./screens/product/NewProductByDealer";
import NewProductByPlatform from "./screens/product/NewProductByPlatform";
import NewProductByConsignment from "./screens/product/NewProductByConsignment";
import NewConsignmentQuotation from "./screens/order/consignment/NewConsignmentQuotation";
import EditConsignmentQuotation from "./screens/order/consignment/EditConsignmentQuotation";
import NewOder from "./screens/order/NewOder";
import ServiceOrderList from "./screens/order/service/ServiceOrderList";
import ServiceOrderDetails from "./screens/order/service/ServiceOrderDetails";
import NewServiceQuotation from "./screens/order/service/NewServiceQuotation";
import EditServiceQuotation from "./screens/order/service/EditServiceQuotation";
import CollectionList from "./screens/webContent/collections/CollectionList";
import NewCollection from "./screens/webContent/collections/NewCollection";
import EditCollection from "./screens/webContent/collections/EditCollection";
import ManageCollectionProducts from "./screens/webContent/collections/ManageCollectionProducts";
import WatchRequestOrderList from "./screens/order/watchRequest/WatchRequestOrderList";
import WatchRequestOrderDetails from "./screens/order/watchRequest/WatchRequestOrderDetails";
import SoldProductList from "./screens/product/SoldProductList";
import TaskList from "./screens/emailMarketing/TaskList";
import NewEmailTask from "./screens/emailMarketing/NewEmailTask";
import EditEmailTask from "./screens/emailMarketing/EditEmailTask";
import ManageTaskProducts from "./screens/emailMarketing/ManageTaskProducts";
import UserTrackingList from "./screens/userTracking/UserTrackingList";
import NewAccessory from "./screens/product/NewAccessory";
import EditAccessory from "./screens/product/EditAccessory";
import UserOrderHistory from "./screens/user/UserOrderHistory";

// pageName : Breadcrumbs use this name
// sidemenuName: sidebar use this name
// name: for permissions check (match the name in database in permission table)

export const menuItemsData = () => {
  return [
    {
      id: "dash",
      pageName: "Dashboard",
      sidemenuName: "Dashboard",
      name: "dashboard",
      iconName: <IoAnalytics />,
      path: "/",
      component: <Dashboard />,
      isSidebarShow: true,
      permissionCheck: true,
    },
    {
      id: "order",
      pageName: "Orders",
      sidemenuName: "Orders",
      name: "manage_orders",
      iconName: <AiOutlineInbox />,
      isSidebarShow: true,
      path: "",
      isChildrenShow: true,
      children: [
        {
          id: "order1",
          pageName: "Order List",
          sidemenuName: "Order List",
          name: "manage_orders",
          path: "/orders",
          component: <OrderList />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "order11",
              pageName: "Order Details",
              sidebmenuName: "Order Details",
              name: "manage_orders",
              path: "/orders/details/:id",
              component: <OrderDetails />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "order12",
              pageName: "New Order",
              sidebmenuName: "New Order",
              name: "manage_orders",
              path: "//orders/new",
              component: <NewOder />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
        {
          id: "order2",
          pageName: "Consignment",
          sidemenuName: "Consignment",
          name: "manage_consignment_orders",
          path: "/consignment/quote-list",
          component: <ConsignmentOrderList />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "order21",
              pageName: "Consignment Quote Details",
              sidebmenuName: "Consignment Quote Details",
              name: "manage_consignment_orders",
              path: "/consignment/details/:id",
              component: <ConsignmentOrderDetails />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "order22",
              pageName: "New Consignment Quotation",
              sidebmenuName: "New Consignment Quotation",
              name: "manage_consignment_orders",
              path: "/consignment/quotation/new",
              component: <NewConsignmentQuotation />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "order23",
              pageName: "Edit Consignment Quotation",
              sidebmenuName: "Edit Consignment Quotation",
              name: "manage_consignment_orders",
              path: "/consignment/quotation/edit/:id",
              component: <EditConsignmentQuotation />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
        {
          id: "order3",
          pageName: "Service",
          sidemenuName: "Service",
          name: "manage_service_orders",
          path: "/service/quote-list",
          component: <ServiceOrderList />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "order31",
              pageName: "Service Quote Details",
              sidebmenuName: "Service Quote Details",
              name: "manage_service_orders",
              path: "/service/order/details/:id",
              component: <ServiceOrderDetails />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "order32",
              pageName: "New Service Order",
              sidebmenuName: "New Service Order",
              name: "manage_service_orders",
              path: "/service/order/new",
              component: <NewServiceQuotation />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "order33",
              pageName: "Edit Service Order",
              sidebmenuName: "Edit Service Order",
              name: "manage_service_orders",
              path: "/service/order/edit/:id",
              component: <EditServiceQuotation />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
        {
          id: "order4",
          pageName: "Watch Request",
          sidemenuName: "Watch Request",
          name: "manage_watch_requests",
          path: "/watch-request/list",
          component: <WatchRequestOrderList />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "order41",
              pageName: "Watch Request Details",
              sidebmenuName: "Watch Request Details",
              name: "manage_watch_requests",
              path: "/watch-request/details/:id",
              component: <WatchRequestOrderDetails />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
      ],
    },
    {
      id: "mess",
      pageName: "Messages",
      sidemenuName: "Messages",
      name: "manage_messages",
      iconName: <AiOutlineMessage />,
      isSidebarShow: true,
      path: "",
      isChildrenShow: true,
      children: [
        {
          id: "mess1",
          pageName: "Messages",
          sidemenuName: "Messages",
          name: "manage_messages",
          path: "/messages",
          component: <DialogList />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "mess11",
              pageName: "Dialog",
              sidebmenuName: "Dialog",
              name: "manage_messages",
              path: "/messages/details/:id",
              component: <DialogDetails />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
      ],
    },
    {
      id: "pro",
      pageName: "Products",
      sidemenuName: "Products",
      name: "manage_products",
      iconName: <MdOutlineWatchLater />,
      isSidebarShow: true,
      path: "",
      isChildrenShow: true,
      children: [
        {
          id: "pro1",
          pageName: "Product List",
          sidemenuName: "Product List",
          name: "manage_products",
          path: "/products",
          component: <ProductList />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "pro11",
              pageName: "Product Details",
              sidebmenuName: "Product Details",
              name: "manage_products",
              path: "/products/details/:id",
              component: <ProductDetails />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
        {
          id: "pro2",
          pageName: "New Product",
          sidemenuName: "New Product",
          name: "manage_products",
          path: "/products/new",
          component: <NewProduct />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "pro21",
              pageName: "New Product by Dealer",
              sidebmenuName: "New Product by Dealer",
              name: "manage_products",
              path: "/products/new/on-behalf-of-dealer",
              component: <NewProductByDealer />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "pro22",
              pageName: "New Product by Platform",
              sidebmenuName: "New Product by Platform",
              name: "manage_products",
              path: "/products/new/platform",
              component: <NewProductByPlatform />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "pro23",
              pageName: "New Product by Consignment",
              sidebmenuName: "New Product by Consignment",
              name: "manage_products",
              path: "/products/new/consignment/:id",
              component: <NewProductByConsignment />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "pro24",
              pageName: "Edit Product",
              sidebmenuName: "Edit Product",
              name: "manage_products",
              path: "/products/seller/edit/:id",
              component: (
                <EditProduct productType="SELLERPRODUCT" pricEditable={true} />
              ),
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "pro25",
              pageName: "Edit Product",
              sidebmenuName: "Edit Product",
              name: "manage_products",
              path: "/products/platform/edit/:id",
              component: (
                <EditProduct
                  productType="PLATFORMPRODUCT"
                  pricEditable={true}
                />
              ),
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "pro26",
              pageName: "Edit Product",
              sidebmenuName: "Edit Product",
              name: "manage_products",
              path: "/products/consignment/edit/:id",
              component: (
                <EditProduct
                  productType="CONSIGNEDPRODUCT"
                  pricEditable={false}
                />
              ),
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "pro27",
              pageName: "New Product(Accessory)",
              sidebmenuName: "New Product(Accessory)",
              name: "manage_products",
              path: "/products/accessory/new",
              component: <NewAccessory />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "pro28",
              pageName: "Edit Product(Accessory)",
              sidebmenuName: "Edit Product(Accessory)",
              name: "manage_products",
              path: "/products/accessory/edit/:id",
              component: <EditAccessory />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
        {
          id: "pro3",
          pageName: "Sold Product List",
          sidemenuName: "Sold Product List",
          name: "manage_products",
          path: "/products/sold",
          component: <SoldProductList />,
          isSidebarShow: true,
          permissionCheck: true,
        },
      ],
    },
    {
      id: "user",
      pageName: "Users",
      sidemenuName: "Users",
      name: "manage_users",
      iconName: <AiOutlineUser />,
      path: "",
      isSidebarShow: true,
      isChildrenShow: true,
      children: [
        {
          id: "user1",
          pageName: "Dealer List",
          sidemenuName: "Dealer List",
          name: "manage_users",
          path: "/users/dealer",
          component: <UserList userType={"Dealer"} />,
          isSidebarShow: true,
          permissionCheck: true,
        },
        {
          id: "user2",
          pageName: "Private Seller List",
          sidemenuName: "Private Seller List",
          name: "manage_users",
          path: "/users/private-seller",
          component: <UserList userType={"Verified Seller"} />,
          isSidebarShow: true,
          permissionCheck: true,
        },
        {
          id: "user3",
          pageName: "General User List",
          sidemenuName: "General User List",
          name: "manage_users",
          path: "/users",
          component: <UserList userType={"Normal User"} />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "user31",
              pageName: "User Details",
              sidebmenuName: "User Details",
              name: "manage_users",
              path: "/users/details/:id",
              component: <UserDetails />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "user31",
              pageName: "User Order History",
              sidebmenuName: "User Order History",
              name: "manage_users",
              path: "/users/orders/:id",
              component: <UserOrderHistory />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "user32",
              pageName: "Edit User",
              sidebmenuName: "Edit User",
              name: "manage_users",
              path: "/users/edit/:id",
              component: <EditUser />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
        {
          id: "user4",
          pageName: "Add New User",
          sidemenuName: "Add New User",
          name: "manage_users",
          path: "/users/new",
          component: <NewUser />,
          isSidebarShow: true,
          permissionCheck: true,
        },
      ],
    },
    {
      id: "tracking",
      pageName: "User Tracking",
      sidemenuName: "User Tracking",
      name: "manage_tracking_event",
      iconName: <MdOutlineFormatListBulleted />,
      path: "",
      isSidebarShow: true,
      isChildrenShow: true,
      children: [
        {
          id: "tracking1",
          pageName: "User Tracking",
          sidemenuName: "User Tracking",
          name: "manage_tracking_event",
          path: "/user-tracking/list",
          component: <UserTrackingList />,
          isSidebarShow: true,
          permissionCheck: true,
        },
      ],
    },
    {
      id: "watch",
      pageName: "Watches",
      sidemenuName: "Watches",
      name: "purchase",
      iconName: <MdOutlineWatch />,
      path: "",
      isSidebarShow: true,
      isChildrenShow: true,
      children: [
        {
          id: "watch1",
          pageName: "Watch List",
          sidemenuName: "Watch List",
          name: "view_watch_list",
          path: "/watches",
          component: <WatchList />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "watch11",
              pageName: "Edit Watch",
              sidebmenuName: "Edit Watch",
              name: "add_new_watch",
              path: "/watch/edit/:id",
              component: <EditWatch />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
        {
          id: "watch2",
          pageName: "New Watch",
          sidemenuName: "New Watch",
          name: "add_new_watch",
          path: "/watch/new",
          component: <NewWatch />,
          isSidebarShow: true,
          permissionCheck: true,
        },
        {
          id: "watch3",
          pageName: "Brand List",
          sidemenuName: "Brand List",
          name: "manage_brands",
          path: "/watch/brands",
          component: <BrandList />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "watch31",
              pageName: "New Brand",
              sidebmenuName: "New Brand",
              name: "manage_brands",
              path: "/watch/brand/new",
              component: <NewBrand />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "watch32",
              pageName: "Edit Brand",
              sidebmenuName: "Edit Brand",
              name: "manage_brands",
              path: "/watch/brand/edit/:id",
              component: <EditBrand />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
        {
          id: "watch4",
          pageName: "Modal List",
          sidemenuName: "Modal List",
          name: "manage_models",
          path: "/watch/models",
          component: <ModelList />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "watch41",
              pageName: "New Model",
              sidebmenuName: "New Model",
              name: "manage_models",
              path: "/watch/model/new",
              component: <NewModel />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
      ],
    },
    {
      id: "blog",
      pageName: "Blogs",
      sidemenuName: "Blogs",
      name: "manage_blogs",
      iconName: <IoImageOutline />,
      path: "",
      isSidebarShow: true,
      isChildrenShow: true,
      children: [
        {
          id: "blog1",
          pageName: "New Blog",
          sidemenuName: "New Blog",
          name: "manage_blogs",
          path: "/blog/new",
          component: <NewBlog />,
          isSidebarShow: true,
          permissionCheck: true,
        },
        {
          id: "blog2",
          pageName: "Blog List",
          sidemenuName: "Blog List",
          name: "manage_blogs",
          path: "/blogs",
          component: <BlogList />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "blog21",
              pageName: "Edit Blog",
              sidebmenuName: "Edit Blog",
              name: "manage_blogs",
              path: "/blog/edit/:id",
              component: <BlogEdit />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "blog22",
              pageName: "Preview Blog",
              sidebmenuName: "Preview Blog",
              name: "manage_blogs",
              path: "/blog/preview/:id",
              component: <BlogPreview />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
      ],
    },
    {
      id: "web",
      pageName: "Web Content",
      sidemenuName: "Web Content",
      name: "purchase",
      iconName: <IoDocumentTextOutline />,
      path: "",
      isSidebarShow: true,
      isChildrenShow: true,
      children: [
        {
          id: "web1",
          pageName: "Collections",
          sidemenuName: "Collections",
          name: "manage_collections",
          path: "/web-content/collections",
          component: <CollectionList />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "web11",
              pageName: "Collections",
              sidebmenuName: "Collections",
              name: "manage_collections",
              path: "/web-content/collections/new",
              component: <NewCollection />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "web12",
              pageName: "Collections",
              sidebmenuName: "Collections",
              name: "manage_collections",
              path: "/web-content/collections/edit/:id",
              component: <EditCollection />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "web13",
              pageName: "Collections",
              sidebmenuName: "Collections",
              name: "manage_collections",
              path: "/web-content/collections/manage-products/:id",
              component: <ManageCollectionProducts />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
        {
          id: "web2",
          pageName: "Ads",
          sidemenuName: "Ads",
          name: "manage_ads",
          path: "/web-content/ads",
          component: <AdsList />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "web21",
              pageName: "Ads",
              sidebmenuName: "Ads",
              name: "manage_ads",
              path: "/web-content/ads/new",
              component: <NewAd />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "web22",
              pageName: "Ads",
              sidebmenuName: "Ads",
              name: "manage_ads",
              path: "/web-content/ads/edit/:id",
              component: <EditAd />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
        {
          id: "web3",
          pageName: "FAQ",
          sidemenuName: "FAQ",
          name: "manage_questions",
          path: "/web-content/faq",
          component: <FaqList />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "web31",
              pageName: "FAQ",
              sidebmenuName: "FAQ",
              name: "manage_questions",
              path: "/web-content/faq/new",
              component: <NewQuestion />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "web32",
              pageName: "FAQ",
              sidebmenuName: "FAQ",
              name: "manage_questions",
              path: "/web-content/faq/edit/:id",
              component: <EditQuestion />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
        {
          id: "web4",
          pageName: "Web Images",
          sidemenuName: "Web Images",
          name: "manage_web_images",
          path: "/web-content/images",
          component: <ImageList />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "web41",
              pageName: "Web Images",
              sidebmenuName: "Web Images",
              name: "manage_web_images",
              path: "/web-content/images/edit/:id",
              component: <ImageEdit />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
        {
          id: "web5",
          pageName: "Web Agreements",
          sidemenuName: "Web Agreements",
          name: "manage_web_agreements",
          path: "/web-content/agreements",
          component: <AgreementList />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "web51",
              pageName: "Web Agreements",
              sidebmenuName: "Web Agreements",
              name: "manage_web_agreements",
              path: "/web-content/agreements/edit/:id",
              component: <AgreementEdit />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
        {
          id: "web6",
          pageName: "Career List",
          sidemenuName: "Career List",
          name: "manage_web_career",
          path: "/web-content/career",
          component: <CareerList />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "web61",
              pageName: "Career",
              sidebmenuName: "Career",
              name: "manage_web_career",
              path: "/web-content/career/new",
              component: <NewCareer />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "web62",
              pageName: "Career",
              sidebmenuName: "Career",
              name: "manage_web_career",
              path: "/web-content/career/edit/:id",
              component: <CareerEdit />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
        {
          id: "web7",
          pageName: "Company Details",
          sidemenuName: "Company Details",
          name: "manage_company_details",
          path: "/web-content/company-details",
          component: <CompanyInfoList />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "web71",
              pageName: "Company Details",
              sidebmenuName: "Company Details",
              name: "manage_company_details",
              path: "/web-content/company-details/edit/:id",
              component: <CompanyInfoEdit />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
      ],
    },
    {
      id: "market",
      pageName: "Marketing Email",
      sidemenuName: "Marketing Email",
      name: "customer",
      iconName: <MdOutlineMailOutline />,
      path: "",
      isSidebarShow: true,
      isChildrenShow: true,
      children: [
        {
          id: "market1",
          pageName: "Marketing Emails",
          sidemenuName: "Marketing Emails",
          name: "manage_marketing_email",
          path: "/marketing-email/tasks",
          component: <TaskList />,
          isSidebarShow: true,
          permissionCheck: true,
          isChildrenShow: false,
          children: [
            {
              id: "market11",
              pageName: "Marketing Emails",
              sidemenuName: "Marketing Emails",
              name: "manage_marketing_email",
              path: "/marketing-email/tasks/new",
              component: <NewEmailTask />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "market12",
              pageName: "Marketing Emails",
              sidemenuName: "Marketing Emails",
              name: "manage_marketing_email",
              path: "/marketing-email/tasks/edit/:id",
              component: <EditEmailTask />,
              isSidebarShow: false,
              permissionCheck: true,
            },
            {
              id: "market13",
              pageName: "Marketing Emails",
              sidemenuName: "Marketing Emails",
              name: "manage_marketing_email",
              path: "/marketing-email/tasks/manage-products/:id",
              component: <ManageTaskProducts />,
              isSidebarShow: false,
              permissionCheck: true,
            },
          ],
        },
      ],
    },
  ];
};
