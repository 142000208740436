import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { convToLocalDate, convtPrice } from "../../../api/functions";
import ScreenTitle from "../../../components/ScreenTitle";
import { Button, LinkButton } from "../../../components/buttons";
import { ProductImageListPreview } from "../../../components/productComponents";
import {
  AddressLine,
  ConsignmentOrderStatusTag,
  ErrorMessage,
  H3,
  Loader,
  OrderStatusTag,
  UserAvatar,
} from "../../../components/smallComponents";
import { serviceQuoteDetailsRequest } from "../../../redux/actions/orderActions";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import {
  ActionWithFileButton,
  AdditionalServiceButton,
  ContactButton,
  MakeAnOfferButton,
  OrderActionButton,
  ShipOrderButton,
} from "../orderActionButtons";
import { ViewFilesDialog } from "../../../components/dialogs";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function ServiceOrderDetails() {
  const { id } = useParams();
  useDocumentTitle("Service Details");
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const { userDetails, success:detailsSuccess } = useSelector(
    (state) => state.accountUserDetails
  );

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const { loading, details, success, error } = useSelector(
    (state) => state.serviceDetails
  );

  const [hasEditPermission, setHasEditPermission] = useState(false);

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [courierInfo, setCourierInfo] = useState("");
  const [orderPaid, setOrderPaid] = useState(false);

  const [showPaymentResultModal, setShowPaymentResultModal] = useState(false);
  const [paymentResultFiles, setPaymentResultFiles] = useState("");

  const [certificateInfo, setCertificateInfo] = useState("");
  const [showCertificateModal, setShowCertificateModal] = useState(false);
  const [hasCertificate, setHasCertificate] = useState(false);

  useEffect(() => {
    dispatch(serviceQuoteDetailsRequest(id));
    setSaveStatus({
      loading: false,
      success: false,
      error: "",
    });
  }, [dispatch, saveStatus.success]);

  useEffect(() => {
    if(detailsSuccess){
      if(userDetails.data.permissions.includes("edit_service_order")){
        setHasEditPermission(true);
      }else{
        setHasEditPermission(false);
      }
    }
  }, [detailsSuccess]);

  useEffect(() => {
    if (success) {
      let find_courier = details.actionRecords.find(
        (x) => x.action === "SHIPPED_PRODUCT_TO_ClIENT"
      );

      if (find_courier) {
        setCourierInfo(JSON.parse(find_courier.action_info));
      }

      let find_certificate = details.actionRecords.find(
        (x) => x.action === "COMPLETE_SERVICE"
      );
      if (find_certificate) {
        if (find_certificate.action_info) {
          let file = JSON.parse(find_certificate.action_info);
          setCertificateInfo({
            notes: file.notes,
            attachments: file.files,
          });
          setHasCertificate(true);
        }
      }

      let find_payment_result = details.actionRecords.find(
        (x) => x.action === "CLIENT_UPLOAD_PAYMENT_RESULT"
      );

      if (
        details.actionRecords.filter(
          (x) => x.action === "CLIENT_UPLOAD_PAYMENT_RESULT"
        ).length
      ) {
        let fileRecords = details.actionRecords.filter(
          (x) => x.action === "CLIENT_UPLOAD_PAYMENT_RESULT"
        );
        let files = [];
        fileRecords.forEach((ele) => {
          files.push(JSON.parse(ele.action_info));
        });
        setPaymentResultFiles({
          notes: "",
          attachments: files,
        });
      }

      // check if paid
      let find_payment_status = details.actionRecords.find(
        (x) => x.action === "CONFIRM_OFFLINE_PAYMENT"
      );
      if (find_payment_status) {
        setOrderPaid(true);
      }
    } else {
      setCourierInfo("");
      setPaymentResultFiles("");
    }
  }, [success]);

  const successActionDone = () => {
    dispatch(serviceQuoteDetailsRequest(id));
  };

  const ViewItem = ({ name, value, rightMargin = false }) => {
    return (
      <div className={`mt-6 ${rightMargin ? "mr-4" : ""}`}>
        <p className="text-sm text-gray">{name}</p>
        <p className="text-sm mt-2">{value}</p>
      </div>
    );
  };

  const OrderViewItem = ({ name, value, rightMargin = false }) => {
    return (
      <div
        className={`mt-6 flex flex-row justify-between ${
          rightMargin ? "mr-4" : ""
        }`}
      >
        <p className="">{name}</p>
        <p className="">{value}</p>
      </div>
    );
  };

  const ViewLabel = ({ name }) => {
    return <p className="text-sm text-gray mt-4">{name}</p>;
  };

  return (
    <div className={commonStyle.pageContainer}>
      {showCertificateModal && (
        <ViewFilesDialog
          isDialogOpen={showCertificateModal}
          closeDialog={() => setShowCertificateModal(false)}
          file={certificateInfo}
          modalTitle="Certificate"
        />
      )}

      {showPaymentResultModal && (
        <ViewFilesDialog
          isDialogOpen={showPaymentResultModal}
          closeDialog={() => setShowPaymentResultModal(false)}
          file={paymentResultFiles}
          modalTitle="Payment Result"
        />
      )}

      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/service/quote-list">
            Service
          </NavLink>
          <NavLink underline="hover" color="inherit">
            Details
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Quote Details" />

        {loading ? (
          <Loader mess="Requesting quote details..." />
        ) : success ? (
          <div className="w-full mt-6">
            <div className="w-full flex  flex-row">
              <div className="w-fit flex flex-row">
                <span>{details.orderNumber}</span>
              </div>
              <OrderStatusTag currState={details.currStateDesc} />
            </div>
            {Number(details.serviceFee) > 0 ? (
              <div className="mt-6">
                <H3 title={"Offer Details"} />
                <div className="bg-lightGray p-4 mt-2 rounded">
                  <OrderViewItem
                    name={"Service Fee"}
                    value={"$" + convtPrice(details.serviceFee)}
                  />

                  {details.currState === "WAITING_SERVICE_AGREEMENT_CONFIRM" &&
                    details.hasAddServicesOffer && (
                      <div className="mt-4">
                        <p>Additional Services (Offered Price)</p>
                        <div className="pl-4">
                          {Number(details.addServices["polishing"]) > 0 ? (
                            <OrderViewItem
                              name={"Polishing"}
                              value={
                                "$" +
                                convtPrice(details.addServices["polishing"])
                              }
                              rightMargin={true}
                            />
                          ) : (
                            <OrderViewItem
                              name={"Polishing"}
                              value={"No offer"}
                              rightMargin={true}
                            />
                          )}
                          {Number(details.addServices["replaceStrap"]) > 0 ? (
                            <OrderViewItem
                              name={"Replace Strap"}
                              value={
                                "$" +
                                convtPrice(details.addServices["replaceStrap"])
                              }
                              rightMargin={true}
                            />
                          ) : (
                            <OrderViewItem
                              name={"Replace Strap"}
                              value={"No offer"}
                              rightMargin={true}
                            />
                          )}
                          {Number(details.addServices["reshaping"]) > 0 ? (
                            <OrderViewItem
                              name={"Reshaping"}
                              value={
                                "$" +
                                convtPrice(details.addServices["reshaping"])
                              }
                              rightMargin={true}
                            />
                          ) : (
                            <OrderViewItem
                              name={"Reshaping"}
                              value={"No offer"}
                              rightMargin={true}
                            />
                          )}
                        </div>
                      </div>
                    )}

                  {details.selectedAddServices.length > 0 ? (
                    <div className="mt-6">
                      <p className="text">Additional Service</p>
                      <div className="pl-4">
                        {details.selectedAddServices.map((item, index) => (
                          <OrderViewItem
                            key={index}
                            name={
                              item === "polishing"
                                ? "Polishing"
                                : item === "replaceStrap"
                                ? "Replace Strap"
                                : item === "reshaping"
                                ? "Reshaping"
                                : "Unknown"
                            }
                            value={convtPrice(details.addServices[item])}
                          />
                        ))}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div
                    className={`flex flex-row justify-between mt-4 border-t border-solid border-borderGray border-black pt-4`}
                  >
                    <p className="font-medium">{"Total"}</p>
                    <p className="font-medium">
                      {convtPrice(details.orderTotal ? details.orderTotal : 0)}
                    </p>
                  </div>
                  {orderPaid && (
                    <p className="text-right text-green-500 mt-4">
                      Payment Received
                    </p>
                  )}
                  {paymentResultFiles && (
                    <button
                      onClick={() => setShowPaymentResultModal(true)}
                      className="w-full text-left lg:text-right hover:text-brandYellow underline text-sm mt-2"
                    >
                      View Payment Result
                    </button>
                  )}
                  {details.orderPdfUri && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={S3_DOMAIN + details.orderPdfUri}
                      className="block w-full text-right mt-4 underline hover:text-brandYellow"
                    >
                      Download Order Certificate
                    </a>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {hasEditPermission && <div className="w-full">

            {/* order actions */}
            {details.actions.includes("SEND_OFFER") ? (
              <div className="w-full mt-4">
                <MakeAnOfferButton
                  orderType={"service"}
                  actionName="SEND_OFFER"
                  actionButtonName="Make An Offer"
                  modalTitle="Make An Offer"
                  modalText="Send an offer to client"
                  successActionText="Offer send successfully"
                  modalButtonText="Confirm Offer"
                  orderId={details.id}
                  userType={"platform"}
                  successActionDone={successActionDone}
                  needOfferPrice={false}
                  needCommision={false}
                  needService={true}
                  oriOfferPrice={
                    Number(details.offerPrice) > 0 ? details.offerPrice : ""
                  }
                  oriCommision={
                    Number(details.commision) > 0 ? details.commision : ""
                  }
                  oriServiceFee={
                    Number(details.serviceFee) > 0 ? details.serviceFee : ""
                  }
                />
              </div>
            ) : (
              <></>
            )}

            {details.actions.includes("UPDATE_OFFER") ? (
              <div className="w-full mt-4">
                <MakeAnOfferButton
                  orderType={"service"}
                  actionName="UPDATE_OFFER"
                  actionButtonName="Update Offer"
                  modalTitle="Update Offer"
                  modalText="Update offer"
                  successActionText="Offer updated successfully"
                  modalButtonText="Confirm Offer"
                  orderId={details.id}
                  userType={"platform"}
                  successActionDone={successActionDone}
                  needOfferPrice={false}
                  needCommision={false}
                  needService={true}
                  oriOfferPrice={
                    Number(details.offerPrice) > 0 ? details.offerPrice : ""
                  }
                  oriCommision={
                    Number(details.commision) > 0 ? details.commision : ""
                  }
                  oriServiceFee={
                    Number(details.serviceFee) > 0 ? details.serviceFee : ""
                  }
                />
              </div>
            ) : (
              <></>
            )}

            {details.actions.includes("SEND_SHIPPING_LABEL_TO_CLIENT") ? (
              <div className="w-full mt-4">
                <ActionWithFileButton
                  orderType={"service"}
                  actionName="SEND_SHIPPING_LABEL_TO_CLIENT"
                  actionButtonName="Send Shipping Label"
                  modalTitle="Send Shipping Label"
                  modalText="Please upload the shipping label"
                  successActionText="Shipping label has been sent successfully"
                  modalButtonText="Confirm"
                  orderId={details.id}
                  userType={"platform"}
                  successActionDone={successActionDone}
                />
              </div>
            ) : (
              <></>
            )}

            {details.actions.includes("PRODUCT_RECEIVED") ? (
              <div className="w-full mt-4">
                <OrderActionButton
                  orderType={"service"}
                  actionName="PRODUCT_RECEIVED"
                  actionButtonName="Watch Received"
                  modalTitle="Watch Received"
                  modalText="Are you sure you have received the watch?"
                  successActionText="Watch has been received successfully"
                  modalButtonText="Yes, Proceed"
                  orderId={details.id}
                  userType={"platform"}
                  successActionDone={successActionDone}
                />
              </div>
            ) : (
              <></>
            )}

            {details.actions.includes("UPDATE_FINAL_SERVICE_FEE") ? (
              <div className="w-full mt-4">
                <AdditionalServiceButton
                  orderType={"service"}
                  actionName="UPDATE_FINAL_SERVICE_FEE"
                  actionButtonName="Update Final Service Fee"
                  modalTitle="Update Final Service Fee"
                  modalText="Watch inspection passed, please confirm the final service fee"
                  successActionText="Service fee updated successfully."
                  modalButtonText="Submit"
                  orderId={details.id}
                  userType={"platform"}
                  successActionDone={successActionDone}
                  oriServiceFee={
                    Number(details.serviceFee) > 0 ? details.serviceFee : ""
                  }
                  addServices={details.addServices}
                />
              </div>
            ) : (
              <></>
            )}

            {details.actions.includes("UPDATE_AGREEMENT") ? (
              <div className="w-full mt-4">
                <AdditionalServiceButton
                  orderType={"service"}
                  actionName="UPDATE_AGREEMENT"
                  actionButtonName="Update Final Service Fee"
                  modalTitle="Update Final Service Fee"
                  modalText="Update Final Service Fee"
                  successActionText="Service fee updated successfully"
                  modalButtonText="Update"
                  orderId={details.id}
                  userType={"platform"}
                  successActionDone={successActionDone}
                  oriServiceFee={
                    Number(details.serviceFee) > 0 ? details.serviceFee : ""
                  }
                  addServices={details.addServices}
                />
              </div>
            ) : (
              <></>
            )}

            {details.actions.includes("CONFIRM_OFFLINE_PAYMENT") ? (
              <div className="w-full mt-4">
                <OrderActionButton
                  orderType={"service"}
                  actionName="CONFIRM_OFFLINE_PAYMENT"
                  actionButtonName="Payment Received"
                  modalTitle="Payment Received"
                  modalText="Are you sure you have received the payment?"
                  successActionText="Proceed successfully"
                  modalButtonText="Yes, Proceed"
                  orderId={details.id}
                  userType={"platform"}
                  successActionDone={successActionDone}
                />
              </div>
            ) : (
              <></>
            )}

            {details.actions.includes("COMPLETE_SERVICE") ? (
              <div className="w-full mt-4">
                <ActionWithFileButton
                  orderType={"service"}
                  actionName="COMPLETE_SERVICE"
                  actionButtonName="Complete Service"
                  modalTitle="Complete Service"
                  modalText="Please upload the ceritificate of service"
                  successActionText="Proceeded successfully"
                  modalButtonText="Yes, Proceed"
                  orderId={details.id}
                  userType={"platform"}
                  successActionDone={successActionDone}
                />
              </div>
            ) : (
              <></>
            )}

            {details.actions.includes("SHIP_PRODUCT_TO_CLIENT") ? (
              <div className="mt-4">
                <ShipOrderButton
                  orderType={"service"}
                  actionName="SHIP_PRODUCT_TO_CLIENT"
                  actionButtonName="Ship Watch"
                  modalTitle="Ship Watch"
                  modalText="Please enter tracking details"
                  successActionText="Shipping details updated successfully."
                  modalButtonText="Submit"
                  orderId={details.id}
                  userType={"platform"}
                  successActionDone={successActionDone}
                />
              </div>
            ) : (
              <></>
            )}
            </div>
            }


            {hasCertificate && (
              <div className="my-4">
                <Button
                  name={"View Service Paper"}
                  action={() => setShowCertificateModal(true)}
                />
              </div>
            )}

            <div className="mt-2 border-b border-solid border-borderGray py-4">
              <H3 title={"Watch Details"} />
              <div className="w-full mt-2">
                <p className="text-sm text-gray mt-4">Watch Images</p>
                <div className="w-full mt-2">
                  <ProductImageListPreview images={details.images} />
                </div>

                <div className="w-fully mt-2">
                  <div className="w-full grid grid-cols-2">
                    <ViewItem name={"Brand"} value={details.brandName} />
                    <ViewItem name={"Model"} value={details.modelName} />
                    <ViewItem
                      name={"Reference Number"}
                      value={details.referenceNumber}
                    />
                    <ViewItem name={"Product of Year"} value={details.year} />
                  </div>
                  <ViewItem name={"Serial NO."} value={details.serialNumber} />
                  <ViewLabel name={"Which services are you interested in?*"} />
                  {details.services.length > 0 ? (
                    <div>
                      {details.services.map((item, index) => (
                        <p
                          key={index}
                          className="w-fit text-sm px-2 py-1 mt-2 rounded-full bg-lightGray"
                        >
                          {item === "Other"
                            ? item + ": " + details.otherServiceNotes
                            : item}
                        </p>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                  <ViewItem
                    name={
                      "Any other details you'd like to share about your watch?"
                    }
                    value={details.notes}
                  />
                </div>
              </div>
            </div>

            {/* client info */}
            <div className="w-full h-full border-b border-solid border-borderGray mt-6 py-4">
              <H3 title={"Contact User"} />
              <div className="flex justify-between flex-col lg:flex-row">
                <div className="flex items-center mt-4">
                  <UserAvatar name={details.userName} />{" "}
                  <NavLink to={`/users/details/${details.userId}`} className={"hover:underline ml-4 "}>
                    <p>{details.userName}</p>
                  </NavLink>
                </div>

                <div className="w-fit text-sm text-darkGray mt-4">
                  <ContactButton
                    recipientId={details.userId}
                    productId={details.id}
                    refType={"SERVICE"}
                    buttonName={"Contact User"}
                  />
                </div>
              </div>
            </div>

            {/* buyer info */}
            <div className="w-full h-full border-b border-solid border-borderGray mt-6  py-4">
              <H3 title={"Contact Info"} />
              <div className="text-sm text-darkGray mt-4">
                <AddressLine
                  firstName={details.contactInfo.firstName}
                  lastName={details.contactInfo.lastName}
                  phone={details.contactInfo.phone}
                  streetLine1={details.contactInfo.streetLine1}
                  city={details.contactInfo.city}
                  country={details.contactInfo.countryName}
                  region={details.contactInfo.region}
                  postalCode={details.contactInfo.postalCode}
                  showName={true}
                  showPhone={true}
                />
              </div>
            </div>

            <div className="w-full h-full border-b border-solid border-borderGray mt-4 lg:justify-between py-4">
              <H3 title={"Quote Logs"} />
              <div className="text-sm text-darkGray mt-4">
                <div>
                  <span className="text-sm">
                    {convToLocalDate(details.atime)}
                  </span>
                  <span className="text-sm ml-4">Quote created</span>
                </div>

                {details.actionRecords.length ? (
                  details.actionRecords.map((item, index) => (
                    <div key={index} className="mt-2">
                      <span className="text-sm">
                        {convToLocalDate(item.atime)}
                      </span>
                      <span className="text-sm ml-4"> {item.actionName}</span>
                      {item.userType && (
                        <span className="text-sm">
                          {" by " + item.userType}
                        </span>
                      )}
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>

            {/* order actions */}
            <div className="w-full flex flex-col justify-start py-4">
              {details.actions.includes("CANCEL_ORDER") && hasEditPermission ? (
                <div className="mt-4">
                  <OrderActionButton
                    orderType={"service"}
                    actionName="CANCEL_ORDER"
                    actionButtonName="Cancel Quote"
                    modalTitle="Cancel Quote"
                    modalText="Are you sure you want to cancel the quote?"
                    successActionText="Quote has been cancelled!"
                    modalButtonText="Yes, Please Cancel The Quote"
                    orderId={details.id}
                    successActionDone={successActionDone}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
