import React, { useState, useEffect} from "react";
import Select, { components } from "react-select";
import {
  MdOutlineClose,
  MdKeyboardArrowDown,
  MdOutlineSearch,
  MdOutlineCalendarToday,
} from "react-icons/md";
import {
  AiOutlineClose,
  AiOutlineDelete,
  AiOutlineEyeInvisible,
  AiOutlineEye,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import commonStyle from "../style/commonStyle.module.css";
import { addDays, subDays } from "date-fns";

/*
UI guideline

font size:
PC: body: 16px-18px(1rem), heading: 1.96倍大于body size, 32px/ 2rem
mobile: 14-16px,, heading: 1.3倍大于body size, 21px/ 1.3rem

margin: 8px的倍数
padding: 8px的倍数

x 方向的padding是y方向的3倍

button text 大小和body一致
*/

function FieldLabel({ label }) {
  return <p className="w-full mt-6 text-base">{label}</p>;
}

function ReadOnlyField({ label, readOnlyName }) {
  return (
    <div className="w-full mt-6">
      <label className="w-full text-base text-black">{label}</label>
      <div className="w-full mt-2 h-12 bg-lightGray flex items-center px-2">
        {readOnlyName}
      </div>
    </div>
  );
}

function TextField({
  type,
  label,
  name,
  value,
  placeholder,
  onChange,
  autoFocus = false,
  readOnly,
  error,
  unit = "",
}) {
  const handleChange = (e) => {
    e.preventDefault();
    onChange(e.target.value);
  };

  return (
    <div className="relative w-full mt-6">
     <label className="w-full text-base text-black">{label}</label>
      <div className="relative flex items-center w-full mt-2">
        <input
          type={type}
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          autoFocus={autoFocus}
          readOnly={readOnly}
          className={`w-full h-12 pl-4 py-2 rounded border-borderGray border-2 ${readOnly
              ? "bg-lightGray border-lightGray text-gray-500 cursor-not-allowed"
              : "border-borderGray  hover:border-black focus:outline-black focus:border-black active:outline-black" }`}
        ></input>
        {unit ? (
          <span className="absolute right-0 w-10 flex items-center justify-center">
            {unit}
          </span>
        ) : (
          <></>
        )}
      </div>
      {error ? <p className="w-full text-red-500 mt-2 ">{error}</p> : <></>}
    </div>
  );
}

function PasswordField({
  name,
  value,
  label,
  autoFocus,
  placeholder,
  onChange,
  error,
  showHints = true,
  readOnly,
}) {
  const [isFocused, setIsFocused] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [hint1, setHint1] = useState(false);
  // const [hint2, setHint2] = useState(false);
  // const [hint3, setHint3] = useState(false);
  // const [hint4, setHint4] = useState(false);
  // const [hint5, setHint5] = useState(false);

  // handle password field changes

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    // const lowerCaseCount = (value.match(/[a-z]/g) || []).length;
    // const upperCaseCount = (value.match(/[A-Z]/g) || []).length;
    // const numberCount = (value.match(/\d/g) || []).length;
    // const specialCount = (value.match(/[~^@#$!%*?&]/g) || []).length;
    setHint1(value.length > 7 ? true : false);

    // setHint2(
    //   numberCount > 0 ? componentsStyle.hintPassed : componentsStyle.hintError
    // );
    // setHint3(
    //   upperCaseCount > 0
    //     ? componentsStyle.hintPassed
    //     : componentsStyle.hintError
    // );
    // setHint4(
    //   lowerCaseCount > 0
    //     ? componentsStyle.hintPassed
    //     : componentsStyle.hintError
    // );

    // setHint5(
    //   specialCount > 0 ? componentsStyle.hintPassed : componentsStyle.hintError
    // );

    onChange(value);
  };

  const handleShowPwdChange = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div className="relative w-full mt-6">
      <label className="w-full text-base text-black">{label}</label>
      <div className="relative flex items-center w-full mt-2">
        <input
          type="password"
          name={name}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          autoFocus={autoFocus}
          readOnly={readOnly}
          className="w-full h-12 pl-4 py-2 rounded border-borderGray border-2 focus:outline-black focus:border-black active:outline-black"
        ></input>
        <button
          className="absolute right-0 w-10 flex items-center justify-center"
          onClick={handleShowPwdChange}
        >
          {showPassword ? (
            <AiOutlineEye className="w-6 h-6 text-gray-500" />
          ) : (
            <AiOutlineEyeInvisible className="w-6 h-6 text-gray-500" />
          )}
        </button>
      </div>

      {error ? <p className="w-full text-red-500 mt-2 ">{error}</p> : <></>}
      {showHints && value.length > 0 && isFocused ? (
        <div className="absolute top-20 w-full shadow-md py-2 bg-white">
          <div
            className={`w-full flex items-center ${
              hint1 ? "text-green-500" : "text-black"
            }`}
          >
            <AiOutlineCheckCircle />
            <p style={{ marginLeft: "2px" }}>At least 8 characters</p>
          </div>
          {/* <div className={hint2}>
          <AiOutlineCheckCircle />
          <p style={{ marginLeft: "2px" }}>Contains 1 number</p>
        </div>
        <div className={hint3}>
          <AiOutlineCheckCircle />
          <p style={{ marginLeft: "2px" }}>Contains 1 uppercase letter</p>
        </div>
        <div className={hint4}>
          <AiOutlineCheckCircle />
          <p style={{ marginLeft: "2px" }}>Contains 1 lowercase letter</p>
        </div>
        <div className={hint5}>
          <AiOutlineCheckCircle />
          <p style={{ marginLeft: "2px" }}>Contains 1 special character</p>
        </div> */}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

function TextAreaField({
  type,
  label,
  name,
  value,
  placeholder,
  onChange,
  rows,
  error,
  unit = "",
}) {
  const handleChange = (e) => {
    e.preventDefault();
    onChange(e.target.value);
  };

  return (
    <div className="relative w-full mt-6">
      <label className="w-full text-base text-black">{label}</label>
      <div className="relative flex items-center w-full mt-2">
        <textarea
          type={type}
          name={name}
          value={value ? value : ""}
          onChange={handleChange}
          placeholder={placeholder}
          className="w-full min-h-12 pl-4 py-2 rounded border-borderGray border-2 focus:outline-black focus:border-black active:outline-black"
          rows={rows}
        ></textarea>
        {unit ? (
          <span className="absolute right-0 w-10 flex items-center justify-center">
            {unit}
          </span>
        ) : (
          <></>
        )}
      </div>
      {error ? <p className="w-full text-red-500 mt-2 ">{error}</p> : <></>}
    </div>
  );
}

const RatioField = ({ name, label, isOn, onChange }) => {
  return (
    <div className="w-full mt-6">
      <label
        className={`${commonStyle.checkboxContainer} ${commonStyle.labelSize}`}
      >
        {label}
        <input
          type="checkbox"
          name={name}
          checked={isOn}
          onChange={(e) => onChange(e.target.checked)}
        />
        <span className={commonStyle.checkmark}></span>
      </label>
    </div>
  );
};

function SelectField({
  value,
  label,
  selectOptions,
  onChange,
  error,
  extractKey,
  placeHolder,
  clearable = true,
  readOnly = false,
  readOnlyName = "",
}) {
  const handleSelect = (value) => {
    onChange(extractKey && value ? value[extractKey] : value);
  };

  // set select dropdown icon
  const DropdownIndicator = (DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...DropdownIndicatorProps}>
        <MdKeyboardArrowDown />
      </components.DropdownIndicator>
    );
  };
  // set select clear icon
  const ClearIndicator = (ClearIndicatorProps) => {
    return (
      <components.DropdownIndicator {...ClearIndicatorProps}>
        <MdOutlineClose />
      </components.DropdownIndicator>
    );
  };

  // set custom color
  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "black" : "#E5E7E7",
      borderWidth: "2px",
      outline: state.isFocused ? "black" : "none",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        borderColor: state.isFocused ? "black" : "black",
      },
      innerHeight: "48px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "44px",
      padding: "0 6px",
    }),
    option: (base, state) => ({
      ...base,
      color: "black",
      backgroundColor: state.isSelected
        ? "var(--light-gray-bc)"
        : "var(--white-color)",
      padding: ".5rem 3rem .5rem .5rem",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "var(--light-gray-bc)",
      },
    }),

    IndicatorsContainer: (base, state) => ({
      ...base,
      backgroundColor: "var(--main-color)",
    }),
  };

  return (
    <div className="w-full mt-6">
      <label className="w-full text-base text-black">{label}</label>
      {readOnly ? (
        <div className="w-full mt-2 h-12 bg-lightGray flex items-center px-2">
          {readOnlyName}
        </div>
      ) : (
        <div className="w-full mt-2">
          <Select
            value={
              extractKey
                ? selectOptions.filter((e) => e[extractKey] === value)
                : value
            }
            components={{ DropdownIndicator, ClearIndicator }}
            placeholder={placeHolder}
            onChange={(e) => handleSelect(e)}
            isClearable={clearable}
            options={selectOptions}
            styles={customStyles}
          />
        </div>
      )}

      <p className="w-full text-red-500 mt-2 ">{error}</p>
    </div>
  );
}

function MultiSelectField({
  value,
  label,
  selectOptions,
  onChange,
  error,
  placeHolder,
  clearable = true,
}) {
  const handleSelect = (value) => {
    onChange(value);
  };

  // set select dropdown icon
  const DropdownIndicator = (DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...DropdownIndicatorProps}>
        <MdKeyboardArrowDown />
      </components.DropdownIndicator>
    );
  };
  // set select clear icon
  const ClearIndicator = (ClearIndicatorProps) => {
    return (
      <components.DropdownIndicator {...ClearIndicatorProps}>
        <MdOutlineClose />
      </components.DropdownIndicator>
    );
  };

  const MultiValueRemove = (MultiValueRemoveProps) => {
    return (
      <components.MultiValueRemove {...MultiValueRemoveProps}>
        <MdOutlineClose />
      </components.MultiValueRemove>
    );
  };
  // set custom color
  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "black" : "#E5E7E7",
      borderWidth: "2px",
      outline: state.isFocused ? "black" : "none",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        borderColor: state.isFocused ? "black" : "black",
      },
      innerHeight: "48px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "44px",
      padding: "0 6px",
    }),
    option: (base, state) => ({
      ...base,
      color: "black",
      backgroundColor: state.isSelected
        ? "var(--main-color)"
        : "var(--white-color)",
      padding: ".5rem 3rem .5rem .5rem",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "var(--table-hover)",
      },
    }),

    IndicatorsContainer: (base, state) => ({
      ...base,
      backgroundColor: "var(--main-color)",
    }),
  };

  return (
    <div className="w-full mt-6">
      <label className="w-full text-base text-black">{label}</label>
      <div className="w-full mt-2">
        <Select
          value={value}
          components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
          placeholder={placeHolder}
          onChange={(e) => handleSelect(e)}
          isClearable={clearable}
          isMulti
          options={selectOptions}
          styles={customStyles}
        />
      </div>
      <p className="w-full text-red-500 mt-2 ">{error}</p>
    </div>
  );
}

function SearchField({ initialQuery, placeholder, onChange }) {
  const [query, setQuery] = useState(initialQuery);
  const [debouncedQuery, setDebouncedQuery] = useState(initialQuery);

  // Debounce effect: Wait 300ms after user stops typing
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 300); // Adjust debounce time if needed

    return () => clearTimeout(handler); // Cleanup timeout on re-render
  }, [query]);

  // Call onChange only when debouncedQuery updates
  useEffect(() => {
    if (debouncedQuery !== initialQuery) {
      onChange(debouncedQuery);
    }
  }, [debouncedQuery, initialQuery, onChange]);

  return (
    <div className="relative w-full">
      <input
        type="text"
        className="w-full h-12 px-4 py-2 border border-borderGray rounded text-black text-base focus:outline-black focus:border-black active:outline-black"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder={placeholder}
        style={{ height: "48px" }}
      />
      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        <MdOutlineSearch className="h-5 w-5 text-gray" />
      </div>
    </div>
  );
}

function PureTextField({
  type,
  name,
  value,
  placeholder,
  onChange,
  autoFocus = false,
  hasBorder = false,
  showSearchIcon = false,
  dollarIcon = false,
}) {
  const handleChange = (e) => {
    e.preventDefault();
    onChange(e.target.value);
  };

  return (
    <div className="relative flex items-center w-full text-black">
      {dollarIcon ? (
        <span className="absolute left-0 w-10 flex items-center justify-center">
          $
        </span>
      ) : (
        <></>
      )}
      <input
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        autoFocus={autoFocus}
        className={`w-full text-[14px] h-12 ${
          dollarIcon ? "pl-8" : "pl-4"
        } py-2 rounded border-borderGray hover:border-black focus:outline-black focus:border-black active:outline-black ${
          hasBorder ? "border-2" : ""
        } `}
      ></input>
      {showSearchIcon ? (
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
          <MdOutlineSearch className="h-5 w-5 text-gray" />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}


// Helper function to generate time options
const generateTimeOptions = (start, end, step) => {
  const options = [];
  for (let i = start; i < end; i += step) {
    const formatted = i.toString().padStart(2, "0");
    options.push(formatted);
  }
  return options;
};

function DateTimePicker({label, value, onChange}) {

  const [selectedDate, setSelectedDate] = useState(value);
  const [hours, setHours] = useState(
    value ? value.getHours().toString().padStart(2, "0") : "10"
  );
  const [minutes, setMinutes] = useState(
    value ? value.getMinutes().toString().padStart(2, "0") : "00"
  );

  const hoursOptions = generateTimeOptions(0, 24, 1); // 0 to 23 hours
  const minutesOptions = generateTimeOptions(0, 60, 5); // 0 to 55 minutes in steps of 5

  // Handle changes
  const handleDateChange = (event) => {
    const date = new Date(event.target.value);
    date.setHours(hours, minutes);
    setSelectedDate(date);
    onChange?.(date);
  };

  const handleTimeChange = (type, value) => {
    if (type === "hours") setHours(value);
    if (type === "minutes") setMinutes(value);

    const newDate = new Date(selectedDate);
    newDate.setHours(type === "hours" ? value : newDate.getHours());
    newDate.setMinutes(type === "minutes" ? value : newDate.getMinutes());
    setSelectedDate(newDate);
    onChange?.(newDate);
  };

  const formatDateForInput = (date) => {
    const offset = date.getTimezoneOffset() * 60000; // Offset in milliseconds
    const localDate = new Date(date.getTime() - offset); // Adjust for local time
    return localDate.toISOString().split("T")[0]; // Get YYYY-MM-DD
  };

  return (
    <div className="w-full mt-6">
      <label className="w-full text-base text-black">{label}</label>
      <div className="flex flex-row mt-2">
      {/* Date Picker */}
      <div className="mr-4">
        <input
          type="date"
          value={formatDateForInput(selectedDate)}
          onChange={handleDateChange}
          onClick={(e) => e.currentTarget.showPicker() }
          className="border p-2 rounded w-full picker"
        />
      </div>

      {/* Time Picker */}
      <div>
        <div className="flex gap-2">
          <select
            value={hours}
            onChange={(e) => handleTimeChange("hours", e.target.value)}
            className="border p-2 rounded w-1/2"
          >
            {hoursOptions.map((hour) => (
              <option key={hour} value={hour}>
                {hour}
              </option>
            ))}
          </select>
          <select
            value={minutes}
            onChange={(e) => handleTimeChange("minutes", e.target.value)}
            className="border p-2 rounded w-1/2"
          >
            {minutesOptions.map((minute) => (
              <option key={minute} value={minute}>
                {minute}
              </option>
            ))}
          </select>
        </div>
      </div>
      </div>
    </div>
  );
};

function DatePicker({ label, value, onChange }) {
  const [selectedDate, setSelectedDate] = useState(value);

  const handleDateChange = (event) => {
    const dateValue = event.target.value;

    if (!dateValue) {
      // If cleared, set date to null and trigger onChange with null
      setSelectedDate(null);
      onChange?.(null);
      return;
    }

    const date = new Date(dateValue);
    setSelectedDate(date);
    onChange?.(date);
  };

  const formatDateForInput = (date) => {
    if (!date || isNaN(date.getTime())) return ""; // Handle null or invalid date

    const offset = date.getTimezoneOffset() * 60000; // Offset in milliseconds
    const localDate = new Date(date.getTime() - offset); // Adjust for local time
    return localDate.toISOString().split("T")[0]; // Get YYYY-MM-DD format
  };

  return (
    <div className="w-full mt-6">
      <label className="w-full text-base text-black">{label}</label>
      <div className="mt-2 h-12">
        <input
          type="date"
          value={formatDateForInput(selectedDate)}
          placeholder="select a date"
          onChange={handleDateChange}
          onClick={(e) => e.currentTarget.showPicker()}
          className="w-full h-full rounded picker pl-4 rounded border-borderGray border-2 hover:border-black focus:outline-black focus:border-black active:outline-black"
        />
      </div>
    </div>
  );
}

export {
  FieldLabel,
  TextField,
  PasswordField,
  RatioField,
  SelectField,
  MultiSelectField,
  ReadOnlyField,
  TextAreaField,
  SearchField,
  PureTextField,
  DateTimePicker,
  DatePicker
};
