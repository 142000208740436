import {
  EDIT_USER_FAIL,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  MESSAGE_DETAILS_FAIL,
  MESSAGE_DETAILS_REQUEST,
  MESSAGE_DETAILS_SUCCESS,
  MESSAGE_LIST_FAIL,
  MESSAGE_LIST_REQUEST,
  MESSAGE_LIST_SUCCESS,
  NEW_USER_FAIL,
  NEW_USER_REQUEST,
  NEW_USER_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_OPTIONS_LIST_REQUEST,
  USER_OPTIONS_LIST_SUCCESS,
  USER_OPTIONS_LIST_FAIL,
  UNREAD_MESSAGE_REQUEST,
  UNREAD_MESSAGE_SUCCESS,
  UNREAD_MESSAGE_FAIL,
  ACCOUNT_USER_DETAILS_REQUEST,
  ACCOUNT_USER_DETAILS_SUCCESS,
  ACCOUNT_USER_DETAILS_FAIL
} from "../constants/userConstants";


export const userLoginReducer = (state = { userInfo: {} }, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { loading: true, success: false };
    case LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload, success: true };
    case LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case LOGOUT_REQUEST:
      return {};
    default:
      return state;
  }
};

export const accountUserDetailsReducer = (state = { userDetails: {} }, action) => {
  switch (action.type) {
    case ACCOUNT_USER_DETAILS_REQUEST:
      return { loading: true, success: false };
    case ACCOUNT_USER_DETAILS_SUCCESS:
      return { loading: false, userDetails: action.payload, success: true };
    case ACCOUNT_USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true, success: false };
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload, success: true };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const newUserReducer = (state = { userId: "" }, action) => {
  switch (action.type) {
    case NEW_USER_REQUEST:
      return { loading: true, success: false };
    case NEW_USER_SUCCESS:
      return { loading: false, userId: action.payload, success: true };
    case NEW_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const editUserReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_USER_REQUEST:
      return { loading: true, success: false };
    case EDIT_USER_SUCCESS:
      return { loading: false, success: true };
    case EDIT_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userDetailsReducer = (state = { details: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { loading: true, success: false };
    case USER_DETAILS_SUCCESS:
      return { loading: false, details: action.payload, success: true };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const messageListReducer = (state = { messages: [] }, action) => {
  switch (action.type) {
    case MESSAGE_LIST_REQUEST:
      return { loading: true, success: false };
    case MESSAGE_LIST_SUCCESS:
      return { loading: false, messages: action.payload, success: true };
    case MESSAGE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const messageDetailsReducer = (state = { details: [] }, action) => {
  switch (action.type) {
    case MESSAGE_DETAILS_REQUEST:
      return { loading: true, success: false };
    case MESSAGE_DETAILS_SUCCESS:
      return { loading: false, details: action.payload, success: true };
    case MESSAGE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, success: false };
    case PRODUCT_LIST_SUCCESS:
      return { loading: false, products: action.payload, success: true };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productDetailsReducer = (state = { details: {} }, action) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { loading: true, success: false };
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, details: action.payload, success: true };
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userListOptionsReducer = (state = { userOptions: [] }, action) => {
  switch (action.type) {
    case USER_OPTIONS_LIST_REQUEST:
      return { loading: true, success: false };
    case USER_OPTIONS_LIST_SUCCESS:
      return { loading: false, userOptions: action.payload, success: true };
    case USER_OPTIONS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const unreadMessagesReducer = (state = { unreads: ""}, action) => {
  switch (action.type) {
    case UNREAD_MESSAGE_REQUEST:
      return { loading: true, success: false };
    case UNREAD_MESSAGE_SUCCESS:
      return { loading: false, unreads: action.payload, success: true };
    case UNREAD_MESSAGE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};