"use client";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { consignmentQuoteDetailsApiRequest } from "../../api/consignmentRequests";
import { convtPrice } from "../../api/functions";
import { getAllBrands, getModelsByBrand } from "../../api/watchRequests";
import AddressCollector from "../../components/AddressCollector";
import { Button } from "../../components/buttons";
import {
  FieldLabel,
  PureTextField,
  RatioField,
  SelectField,
  TextAreaField,
  TextField,
} from "../../components/inputs";
import { ErrorMessage, H3, Loader } from "../../components/smallComponents";
import { K_COUNTRIES } from "../../constantData/K_COUNTRIES";
import ImagesUploader from "../product/ImagesUploader";
import SearchWatch from "../product/SearchWatch";
import { getWatchDetailsRequest } from "../../api/productsRequests";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function PreOrderForm({ type, orderId, onSave }) {
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const {
    loading,
    watchOptions,
    success: optionSuccess,
    error,
  } = useSelector((state) => state.watchConstantOptions);


  const {
    loading: userDetailsLoading,
    details,
    success: userDetailsSuccess,
    error: userDetailsError,
  } = useSelector((state) => state.userDetails);

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [watchStatus, setWatchStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [brandReqStatus, setBrandReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [modelReqStatus, setModelReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [watchPrice, setWatchPrice] = useState("");
  const [shippingFee, setShippingFee] = useState(0);
  const [pickup, setPickup] = useState(false);
  const [brandOptions, setBrandOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);

  const [preOrderWatchInfo, setPreOrderWatchInfo] = useState({
    brandId: "",
    modelId: "",
    referenceNumber: "",
    watchTypeId: "",
    movementId: "",
    genderId: "",
    year: "",
    dialColorId: "",
    caseMaterialId: "",
    braceletColorId: "",
    braceletMaterialId: "",
    imageUris: [],
  });

  const [shippingAddress, setShippingAddress] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    streetLine1: "",
    city: "",
    region: "",
    country: "",
    postalCode: "",
  });
  const [billingAddress, setBillingAddress] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    streetLine1: "",
    city: "",
    region: "",
    country: "",
    postalCode: "",
  });
  const [sameAddress, setSameAddress] = useState(true);
  const [shippingAddressErrors, setShippingAddressErrors] = useState({});
  const [billingAddressErrors, setBillingAddressErrors] = useState({});
  const [adminNotes, setAdminNotes] = useState("");

  const [formErrors, setFormErrors] = useState({});

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (userDetailsSuccess) {
      let contactDetails = {
        firstName: details.firstName,
        lastName: details.lastName,
        phone: details.phone,
        streetLine1: details.streetLine1,
        region: details.region,
        country: K_COUNTRIES.filter((x) => x.value === details.country)[0],
        city: details.city,
        postalCode: details.postalCode,
      };
      setShippingAddress(contactDetails);
    } else {
      setShippingAddress({
        firstName: "",
        lastName: "",
        phone: "",
        streetLine1: "",
        city: "",
        region: "",
        country: "",
        postalCode: "",
      });
    }
  }, [userDetailsSuccess]);

  useEffect(() => {
    if (type === "new") {
      setReqStatus({ ...reqStatus, loading: false, success: true, error: "" });
    }
  }, [type]);

  useEffect(() => {
    const getBrandOptions = async () => {
      try {
        setBrandReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await getAllBrands(config);
        if (responseData.data.success) {
          setBrandOptions(responseData.data.data);
          setBrandReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setBrandReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    getBrandOptions();
  }, []);

  useEffect(() => {
    const getModelOptions = async () => {
      try {
        setModelReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await getModelsByBrand(
          preOrderWatchInfo.brandId,
          config
        );
        if (responseData.data.success) {
          let options = [];
          let models = responseData.data.data;
          models.forEach((ele) => {
            options.push({
              id: ele.id,
              label: ele.name,
              value: ele.name,
            });
          });
          setModelOptions(options);
          setModelReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setModelReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };

    if (preOrderWatchInfo.brandId) {
      getModelOptions();
    }
  }, [preOrderWatchInfo.brandId]);

  const handleWatchInfoChange = (e) => {
    const { name, value } = e.target;
    const type = e.type;

    if (type === "FLOAT") {
      const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
      if (!validated) {
        return;
      }
    }
    if (type === "INT") {
      const validated = value.match(/^(?:\d+)?$/g);
      if (!validated) {
        return;
      }
    }

    setPreOrderWatchInfo({ ...preOrderWatchInfo, [name]: value });
  };

  const getWatchImages = (files) => {
    setPreOrderWatchInfo({
      ...preOrderWatchInfo,
      ["imageUris"]: files,
    });
  };
  const handleWatchSelect = (id) => {
    getWatchInfo(id);
  };

  const getWatchInfo = async (watchId) => {
    try {
      setWatchStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await getWatchDetailsRequest(
        watchId,
        config,
        dispatch
      );
      if (responseData.data.success) {
        let watch = responseData.data.data;

      
        setPreOrderWatchInfo({ ...preOrderWatchInfo, 
          brandId: watch.brandId, 
          modelId: watch.modelId,
          referenceNumber: watch.referenceNumber,
          watchTypeId: watch.watchTypeId,
          movementId: watch.movementId,
          genderId: watch.genderId,
          dialColorId: watch.dialColorId,
          caseMaterialId: watch.caseMaterialId,
          braceletColorId: watch.braceletColorId,
          braceletMaterialId: watch.braceletMaterialId,
        });
        //setFormState(watch);
        setWatchStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setWatchStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };
 



  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (!validated) {
      return;
    }

    if (name === "watchPrice") {
      setWatchPrice(value);
    } else if (name === "shippingFee") {
      setShippingFee(value);
    }
  };

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  const handleSubmit = () => {
    const hasErrors = validatePreOrderInfo(preOrderWatchInfo);
    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      let shippingAddressCopy = { ...shippingAddress };
      let billingAddressCopy = sameAddress
        ? shippingAddressCopy
        : { ...billingAddress };

      if (typeof shippingAddressCopy.country === "object") {
        shippingAddressCopy.countryName = shippingAddressCopy.country.value;
      }
      if (typeof billingAddressCopy.country === "object") {
        billingAddressCopy.countryName = billingAddressCopy.country.value;
      }

      let images = [];

      if (preOrderWatchInfo.imageUris.length) {
        preOrderWatchInfo.imageUris.forEach((x) => {
          if (x.uri) {
            images.push(x.uri);
          }
        });
      }

      let watchInfo = {
        brandId: preOrderWatchInfo.brandId,
        modelId: preOrderWatchInfo.modelId,
        referenceNumber: preOrderWatchInfo.referenceNumber,
        watchTypeId: preOrderWatchInfo.watchTypeId,
        movementId: preOrderWatchInfo.movementId,
        genderId: preOrderWatchInfo.genderId,
        year: preOrderWatchInfo.year,
        dialColorId: preOrderWatchInfo.dialColorId,
        caseMaterialId: preOrderWatchInfo.caseMaterialId,
        braceletColorId: preOrderWatchInfo.braceletColorId,
        braceletMaterialId: preOrderWatchInfo.braceletMaterialId,
        imageUris: images,
      };

      let orderValues = {
        watchInfo: watchInfo,
        watchPrice: watchPrice,
        shippingFee: shippingFee,
        deliverMethod: pickup ? "instore" : "ship",
        shippingAddress: JSON.stringify(shippingAddressCopy),
        billingAddress: JSON.stringify(billingAddressCopy),
        adminNotes: adminNotes
      };

      onSave(orderValues);
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validatePreOrderInfo = (values) => {
    const errors = {};

    if (!values.brandId) {
      errors.brand = "Please select a brand.";
    }

    if (!values.modelId) {
      errors.model = "Please select a model.";
    }
    if (!values.genderId) {
      errors.gender = "Please select watch gender.";
    }
    if (!values.year) {
      errors.year = "Please enter the year.";
    }

    if (!values.referenceNumber) {
      errors.referenceNumber = "Please enter your watch reference number.";
    }
    if (!values.caseMaterialId) {
      errors.caseMaterial = "Please select case material.";
    }
    if (!values.dialColorId) {
      errors.dialColor = "Please select dial color.";
    }
    if (!values.braceletColorId) {
      errors.braceletColor = "Please select bracelet color.";
    }
    if (!values.braceletMaterialId) {
      errors.barceletMaterial = "Please select bracelet material.";
    }
    if (!values.watchTypeId) {
      errors.watchType = "Please select watch type.";
    }
    if (!values.movementId) {
      errors.movement = "Please select watch movement.";
    }

    return errors;
  };

  return (
    <div className="w-full pb-20 pt-4">
      {reqStatus.loading ? (
        <Loader mess="Request details..." />
      ) : reqStatus.success ? (
        <div className="w-full flex items-start flex-row">
          <div className="w-full lg:w-3/5">
            <div className="w-full mt-6">
              <div className="w-fit bg-green-500 text-white rounded px-3 py-2 mb-4">
                Pre-order
              </div>
              <H3 title={"1. Watch Details"} />
              <FieldLabel label={"Watch Images"} />
              <ImagesUploader
                initialImages={preOrderWatchInfo.imageUris}
                numLimit={20}
                onWatchUpload={getWatchImages}
                tipsText={
                  "Upload photos showing the watch that you are going to order"
                }
              />
              <div>
              <FieldLabel label={"Search Watch"} />
              <div className="mt-4">
              <SearchWatch onSelect={handleWatchSelect} />
              </div>
              </div>

              <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-6">
                <SelectField
                  label="Brand*"
                  placeHolder="Select brand"
                  selectOptions={brandOptions}
                  value={preOrderWatchInfo.brandId}
                  extractKey="id"
                  onChange={(brandId) =>
                    handleWatchInfoChange({
                      type: "ID",
                      target: { value: brandId, name: "brandId" },
                    })
                  }
                  error={formErrors.brand}
                />
                <SelectField
                  label="Model*"
                  placeHolder="Select model"
                  selectOptions={modelOptions}
                  value={preOrderWatchInfo.modelId}
                  extractKey="id"
                  onChange={(modelId) =>
                    handleWatchInfoChange({
                      type: "ID",
                      target: { value: modelId, name: "modelId" },
                    })
                  }
                  error={formErrors.model}
                />

                <TextField
                  type="text"
                  label="Reference Number*"
                  name="referenceNumber"
                  autofocus={true}
                  value={preOrderWatchInfo.referenceNumber}
                  onChange={(referenceNumber) =>
                    handleWatchInfoChange({
                      type: "TEXT",
                      target: {
                        value: referenceNumber,
                        name: "referenceNumber",
                      },
                    })
                  }
                  error={formErrors.referenceNumber}
                />
                <SelectField
                  label="Watch Type*"
                  placeHolder="Select watch type"
                  selectOptions={optionSuccess ? watchOptions.watchTypes : []}
                  value={preOrderWatchInfo.watchTypeId}
                  extractKey="id"
                  onChange={(watchTypeId) =>
                    handleWatchInfoChange({
                      type: "ID",
                      target: { value: watchTypeId, name: "watchTypeId" },
                    })
                  }
                  error={formErrors.watchType}
                />
                <SelectField
                  label="Movement*"
                  placeHolder="Select movement"
                  selectOptions={optionSuccess ? watchOptions.movemnets : []}
                  value={preOrderWatchInfo.movementId}
                  extractKey="id"
                  onChange={(movementId) =>
                    handleWatchInfoChange({
                      type: "ID",
                      target: { value: movementId, name: "movementId" },
                    })
                  }
                  error={formErrors.movement}
                />
                <TextField
                  type="text"
                  label="Year of Product*"
                  name="year"
                  autofocus={true}
                  value={preOrderWatchInfo.year}
                  onChange={(year) =>
                    handleWatchInfoChange({
                      type: "TEXT",
                      target: {
                        value: year,
                        name: "year",
                      },
                    })
                  }
                  error={formErrors.year}
                />
                <SelectField
                  label="Gender*"
                  placeHolder="Select gender"
                  selectOptions={optionSuccess ? watchOptions.genders : []}
                  value={preOrderWatchInfo.genderId}
                  extractKey="id"
                  onChange={(genderId) =>
                    handleWatchInfoChange({
                      type: "ID",
                      target: { value: genderId, name: "genderId" },
                    })
                  }
                  error={formErrors.gender}
                />
              </div>
              <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-6">
                <SelectField
                  label="Dial Color*"
                  placeHolder="Select dial color"
                  selectOptions={optionSuccess ? watchOptions.dialColors : []}
                  value={preOrderWatchInfo.dialColorId}
                  extractKey="id"
                  onChange={(dialColorId) =>
                    handleWatchInfoChange({
                      type: "ID",
                      target: { value: dialColorId, name: "dialColorId" },
                    })
                  }
                  error={formErrors.dialColor}
                />
                <SelectField
                  label="Case Material*"
                  placeHolder="Select case material"
                  selectOptions={
                    optionSuccess ? watchOptions.caseMaterials : []
                  }
                  value={preOrderWatchInfo.caseMaterialId}
                  extractKey="id"
                  onChange={(caseMaterialId) =>
                    handleWatchInfoChange({
                      type: "ID",
                      target: { value: caseMaterialId, name: "caseMaterialId" },
                    })
                  }
                  error={formErrors.caseMaterial}
                />
                <SelectField
                  label="Bracelet Color*"
                  placeHolder="Select bracelet color"
                  selectOptions={
                    optionSuccess ? watchOptions.breceletColors : []
                  }
                  value={preOrderWatchInfo.braceletColorId}
                  extractKey="id"
                  onChange={(braceletColorId) =>
                    handleWatchInfoChange({
                      type: "ID",
                      target: {
                        value: braceletColorId,
                        name: "braceletColorId",
                      },
                    })
                  }
                  error={formErrors.braceletColor}
                />
                <SelectField
                  label="Bracelet Material*"
                  placeHolder="Select bracelet material"
                  selectOptions={
                    optionSuccess ? watchOptions.breceletMaterials : []
                  }
                  value={preOrderWatchInfo.braceletMaterialId}
                  extractKey="id"
                  onChange={(braceletMaterialId) =>
                    handleWatchInfoChange({
                      type: "ID",
                      target: {
                        value: braceletMaterialId,
                        name: "braceletMaterialId",
                      },
                    })
                  }
                  error={formErrors.barceletMaterial}
                />
              </div>

              <div className="mt-8 w-full">
                <H3 title={"2. Shipping Address"} />
                <AddressCollector
                  formState={shippingAddress}
                  onChange={(address) => setShippingAddress(address)}
                  errors={shippingAddressErrors}
                />
              </div>

              <div className="mt-8 w-full">
                <H3 title={"3. Billing Address"} />
                <RatioField
                  name={"same address"}
                  label={"Billing address is the same with shipping address"}
                  isOn={sameAddress}
                  onChange={(selected) => setSameAddress(selected)}
                />

                {!sameAddress && (
                  <AddressCollector
                    formState={billingAddress}
                    onChange={(address) => setBillingAddress(address)}
                    errors={billingAddressErrors}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="w-2/5 ml-8 ">
            <H3 title={"Order Summary"} />
            <div className="w-full mt-6 bg-lightGray rounded p-4">
              <div className="w-full bg-lightGray rounded p-4 mt-4">
                <div className="w-full grid grid-cols-2">
                  <RatioField
                    name={"shipping"}
                    label={"Ship to customer"}
                    isOn={!pickup}
                    onChange={(selected) => setPickup(!selected)}
                  />
                  <RatioField
                    name={"pickup"}
                    label={"Pick up at store"}
                    isOn={pickup}
                    onChange={(selected) => setPickup(selected)}
                  />
                </div>
                <div className="flex justify-between items-center mt-6">
                  <p className="w-[120px]">Watch Price</p>
                  <PureTextField
                    type="text"
                    name="keyword"
                    placeholder="Price"
                    autofocus={true}
                    value={watchPrice}
                    hasBorder={true}
                    showSearchIcon={false}
                    dollarIcon={true}
                    onChange={(watchPrice) =>
                      handlePriceChange({
                        target: { value: watchPrice, name: "watchPrice" },
                      })
                    }
                  />
                </div>
                <div className="flex mt-4 justify-between items-center">
                  <p className="w-[120px]">Shipping Fee</p>
                  <PureTextField
                    type="text"
                    name="keyword"
                    placeholder="Watch Price"
                    autofocus={true}
                    value={shippingFee}
                    hasBorder={true}
                    showSearchIcon={false}
                    dollarIcon={true}
                    onChange={(shippingFee) =>
                      handlePriceChange({
                        target: { value: shippingFee, name: "shippingFee" },
                      })
                    }
                  />
                </div>

                <div className="flex border-t border-solid border-borderGray pt-4 mt-4 font-bold text-xl justify-between items-center">
                  <span>Total</span>
                  <span>
                    ${convtPrice(Number(watchPrice) + Number(shippingFee))}
                  </span>
                </div>
              </div>
            </div>

            <TextAreaField
              type="text"
              label="Notes (Printed on order PDF)"
              name="adminNotes"
              value={adminNotes}
              rows={6}
              placeholder="order notes..."
              onChange={(adminNotes) =>
                setAdminNotes(adminNotes)
              }
            />

            <div className="mt-6 w-full">
              <Button
                name={`${type === "new" ? "Place Order" : "Save Update"}`}
                action={handleSubmit}
              />
            </div>
          </div>
        </div>
      ) : reqStatus.error ? (
        <ErrorMessage mess={reqStatus.error} />
      ) : (
        <></>
      )}
    </div>
  );
}
