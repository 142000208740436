const K_ACTION_TYPES = [
  { id: 1, value: "search_product", label: "Search Product" },
  { id: 2, value: "search_no_results", label: "Search - No Results" },
  { id: 3, value: "search_suggestion_click", label: "Clicked Search Suggestion" },
  { id: 4, value: "product_view", label: "Viewed Product" },
  { id: 5, value: "product_view_from_email", label: "Viewed Product from Email" },
  { id: 6, value: "click_place_order", label: "Clicked Place Order" },
  { id: 7, value: "click_submit_order", label: "Clicked Submit Order" },
  { id: 8, value: "submit_order_success", label: "Order Submitted Successfully" },
  { id: 9, value: "filter_brand", label: "Filtered by Brand" },
  { id: 10, value: "filter_model", label: "Filtered by Model" },
  { id: 11, value: "filter_reference", label: "Filtered by Reference" },
  { id: 12, value: "filter_price", label: "Filtered by Price" },
  { id: 13, value: "filter_case_size", label: "Filtered by Case Size" },
  { id: 14, value: "filter_watch_type", label: "Filtered by Watch Type" },
  { id: 15, value: "filter_gender", label: "Filtered by Gender" },
  { id: 16, value: "page_view", label: "Page Viewed" },
];

  export {
    K_ACTION_TYPES
  };